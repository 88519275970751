import "./i18n";
import { PROJECT_INVITES_LIST as BASE_PROJECT_INVITES_LIST, PROJECT_INVITES_CREATE as BASE_PROJECT_INVITES_CREATE, PROJECT_INVITES_EDIT as BASE_PROJECT_INVITES_EDIT, PROJECT_INVITES_VIEW as BASE_PROJECT_INVITES_VIEW, } from "./config/routes";
export const PROJECT_INVITES_LIST = {
    ...BASE_PROJECT_INVITES_LIST,
};
export const PROJECT_INVITES_CREATE = {
    ...BASE_PROJECT_INVITES_CREATE,
};
export const PROJECT_INVITES_EDIT = {
    ...BASE_PROJECT_INVITES_EDIT,
};
export const PROJECT_INVITES_VIEW = {
    ...BASE_PROJECT_INVITES_VIEW,
};
