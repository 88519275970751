import { SubscriptionPricesList } from "../components/List/SubscriptionPricesList";
import { SubscriptionPricesCreate } from "../components/Create/SubscriptionPricesCreate";
import { SubscriptionPricesEdit } from "../components/Edit/SubscriptionPricesEdit";
import { SubscriptionPricesView } from "../components/View/SubscriptionPricesView";
import { SettingFilled } from "@ant-design/icons";
export const SUBSCRIPTION_PRICES_LIST = {
    path: "/admin/subscription-prices",
    component: SubscriptionPricesList,
    menu: {
        key: "SUBSCRIPTION_PRICES_LIST",
        label: "management.subscription_prices.menu.title",
        icon: SettingFilled,
    },
};
export const SUBSCRIPTION_PRICES_CREATE = {
    path: "/admin/subscription-prices/create",
    component: SubscriptionPricesCreate,
};
export const SUBSCRIPTION_PRICES_EDIT = {
    path: "/admin/subscription-prices/:id/edit",
    component: SubscriptionPricesEdit,
};
export const SUBSCRIPTION_PRICES_VIEW = {
    path: "/admin/subscription-prices/:id/view",
    component: SubscriptionPricesView,
};
