import "./i18n";
import { SUBSCRIPTION_PRICES_LIST as BASE_SUBSCRIPTION_PRICES_LIST, SUBSCRIPTION_PRICES_CREATE as BASE_SUBSCRIPTION_PRICES_CREATE, SUBSCRIPTION_PRICES_EDIT as BASE_SUBSCRIPTION_PRICES_EDIT, SUBSCRIPTION_PRICES_VIEW as BASE_SUBSCRIPTION_PRICES_VIEW, } from "./config/routes";
export const SUBSCRIPTION_PRICES_LIST = {
    ...BASE_SUBSCRIPTION_PRICES_LIST,
};
export const SUBSCRIPTION_PRICES_CREATE = {
    ...BASE_SUBSCRIPTION_PRICES_CREATE,
};
export const SUBSCRIPTION_PRICES_EDIT = {
    ...BASE_SUBSCRIPTION_PRICES_EDIT,
};
export const SUBSCRIPTION_PRICES_VIEW = {
    ...BASE_SUBSCRIPTION_PRICES_VIEW,
};
