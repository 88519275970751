import "./i18n";
import { PROJECT_ASSET_INFOS_LIST as BASE_PROJECT_ASSET_INFOS_LIST, PROJECT_ASSET_INFOS_CREATE as BASE_PROJECT_ASSET_INFOS_CREATE, PROJECT_ASSET_INFOS_EDIT as BASE_PROJECT_ASSET_INFOS_EDIT, PROJECT_ASSET_INFOS_VIEW as BASE_PROJECT_ASSET_INFOS_VIEW, } from "./config/routes";
export const PROJECT_ASSET_INFOS_LIST = {
    ...BASE_PROJECT_ASSET_INFOS_LIST,
};
export const PROJECT_ASSET_INFOS_CREATE = {
    ...BASE_PROJECT_ASSET_INFOS_CREATE,
};
export const PROJECT_ASSET_INFOS_EDIT = {
    ...BASE_PROJECT_ASSET_INFOS_EDIT,
};
export const PROJECT_ASSET_INFOS_VIEW = {
    ...BASE_PROJECT_ASSET_INFOS_VIEW,
};
