var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ProjectsCollection, ProductsCollection, WorldsAssetPositionsCollection, ProjectAssetInfosCollection, } from "@bundles/UIAppBundle/collections";
let ProjectAssetInfoCreateForm = class ProjectAssetInfoCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isEnabled",
                label: t("management.project_asset_infos.fields.isEnabled"),
                name: ["isEnabled"],
                required: true,
                initialValue: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "mediaType",
                label: t("management.project_asset_infos.fields.mediaType"),
                name: ["mediaType"],
                render: (props) => {
                    const options = [
                        { value: "IMAGE_2D", label: "Image 2 D" },
                        { value: "IMAGE_2D_CAROUSEL", label: "Image 2 D Carousel" },
                        { value: "IMAGE_360", label: "Image 360" },
                        { value: "IMAGE_360_CAROUSEL", label: "Image 360 Carousel" },
                        { value: "ITEM_3D", label: "Item 3 D" },
                        { value: "VIDEO", label: "Video" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.project_asset_infos.fields.mediaType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.project_asset_infos.fields.mediaType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.project_asset_infos.fields.mediaType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "typeFor3D",
                label: t("management.project_asset_infos.fields.typeFor3D"),
                name: ["typeFor3D"],
                render: (props) => {
                    const options = [
                        { value: "OBJ_MTL", label: "Obj Mtl" },
                        { value: "GLB", label: "Glb" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.project_asset_infos.fields.typeFor3D"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.project_asset_infos.fields.typeFor3D_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.project_asset_infos.fields.typeFor3D_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "typeForVideo",
                label: t("management.project_asset_infos.fields.typeForVideo"),
                name: ["typeForVideo"],
                render: (props) => {
                    const options = [
                        { value: "LINK", label: "Link" },
                        { value: "UPLOAD", label: "Upload" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.project_asset_infos.fields.typeForVideo"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.project_asset_infos.fields.typeForVideo_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.project_asset_infos.fields.typeForVideo_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "action",
                label: t("management.project_asset_infos.fields.action"),
                name: ["action"],
                nest: [
                    {
                        id: "type",
                        label: t("management.project_asset_infos.fields.action.type"),
                        name: ["action", "type"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "EXIT", label: "Exit" },
                                { value: "NEXT_ROOM", label: "Next Room" },
                                { value: "ADD_TO_BASKET", label: "Add To Basket" },
                                { value: "CHAT", label: "Chat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.project_asset_infos.fields.action.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.project_asset_infos.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.project_asset_infos.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "projectId",
                        label: t("management.project_asset_infos.fields.action.projectId"),
                        name: ["action", "projectId"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "deltaCoordinates",
                label: t("management.project_asset_infos.fields.deltaCoordinates"),
                name: ["deltaCoordinates"],
                required: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.project_asset_infos.fields.deltaCoordinates.position"),
                        name: ["deltaCoordinates", "position"],
                        required: true,
                        tooltip: t("management.project_asset_infos.fields.deltaCoordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.project_asset_infos.fields.deltaCoordinates.rotation"),
                        name: ["deltaCoordinates", "rotation"],
                        required: true,
                        tooltip: t("management.project_asset_infos.fields.deltaCoordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.project_asset_infos.fields.deltaCoordinates.scale"),
                        name: ["deltaCoordinates", "scale"],
                        required: true,
                        tooltip: t("management.project_asset_infos.fields.deltaCoordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "projectId",
                label: t("management.project_asset_infos.fields.project"),
                name: ["projectId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", required: true }) })),
            },
            {
                id: "productId",
                label: t("management.project_asset_infos.fields.product"),
                name: ["productId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "translatedName", required: false }) })),
            },
            {
                id: "assetPositionId",
                label: t("management.project_asset_infos.fields.assetPosition"),
                name: ["assetPositionId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: WorldsAssetPositionsCollection, field: "_id", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.project_asset_infos.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.PROJECT_ASSET_INFOS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PROJECT_ASSET_INFOS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PROJECT_ASSET_INFOS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProjectAssetInfosCollection),
    __metadata("design:type", typeof (_a = typeof ProjectAssetInfosCollection !== "undefined" && ProjectAssetInfosCollection) === "function" ? _a : Object)
], ProjectAssetInfoCreateForm.prototype, "collection", void 0);
ProjectAssetInfoCreateForm = __decorate([
    Service({ transient: true })
], ProjectAssetInfoCreateForm);
export { ProjectAssetInfoCreateForm };
