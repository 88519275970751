import "./i18n";
import { FEEDBACKS_LIST as BASE_FEEDBACKS_LIST, FEEDBACKS_CREATE as BASE_FEEDBACKS_CREATE, FEEDBACKS_EDIT as BASE_FEEDBACKS_EDIT, FEEDBACKS_VIEW as BASE_FEEDBACKS_VIEW, } from "./config/routes";
export const FEEDBACKS_LIST = {
    ...BASE_FEEDBACKS_LIST,
};
export const FEEDBACKS_CREATE = {
    ...BASE_FEEDBACKS_CREATE,
};
export const FEEDBACKS_EDIT = {
    ...BASE_FEEDBACKS_EDIT,
};
export const FEEDBACKS_VIEW = {
    ...BASE_FEEDBACKS_VIEW,
};
