import "./i18n";
import { PROJECTS_CATEGORIES_LIST as BASE_PROJECTS_CATEGORIES_LIST, PROJECTS_CATEGORIES_CREATE as BASE_PROJECTS_CATEGORIES_CREATE, PROJECTS_CATEGORIES_EDIT as BASE_PROJECTS_CATEGORIES_EDIT, PROJECTS_CATEGORIES_VIEW as BASE_PROJECTS_CATEGORIES_VIEW, } from "./config/routes";
export const PROJECTS_CATEGORIES_LIST = {
    ...BASE_PROJECTS_CATEGORIES_LIST,
};
export const PROJECTS_CATEGORIES_CREATE = {
    ...BASE_PROJECTS_CATEGORIES_CREATE,
};
export const PROJECTS_CATEGORIES_EDIT = {
    ...BASE_PROJECTS_CATEGORIES_EDIT,
};
export const PROJECTS_CATEGORIES_VIEW = {
    ...BASE_PROJECTS_CATEGORIES_VIEW,
};
