var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let SubscriptionFeaturePriceListFiltersForm = class SubscriptionFeaturePriceListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "periodType",
                label: t("management.subscription_feature_prices.fields.periodType"),
                name: ["periodType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.subscription_feature_prices.fields.periodType"), children: [_jsx(Ant.Select.Option, { value: "MONTHLY", children: "Monthly" }, "MONTHLY"), _jsx(Ant.Select.Option, { value: "YEARLY", children: "Yearly" }, "YEARLY")] }) })),
            },
            {
                id: "feature",
                label: t("management.subscription_feature_prices.fields.feature"),
                name: ["feature"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.subscription_feature_prices.fields.feature"), children: [_jsx(Ant.Select.Option, { value: "ADD_360_IMAGE", children: "Add 360 Image" }, "ADD_360_IMAGE"), _jsx(Ant.Select.Option, { value: "ADD_3D_OBJECT", children: "Add 3 D Object" }, "ADD_3D_OBJECT"), _jsx(Ant.Select.Option, { value: "ADD_10_3D_OBJECTS", children: "Add 10 3 D Objects" }, "ADD_10_3D_OBJECTS"), _jsx(Ant.Select.Option, { value: "LOAD_1_PHOTO", children: "Load 1 Photo" }, "LOAD_1_PHOTO"), _jsx(Ant.Select.Option, { value: "LOAD_10_PHOTOS", children: "Load 10 Photos" }, "LOAD_10_PHOTOS"), _jsx(Ant.Select.Option, { value: "WISHLIST_FOR_CLIENTS", children: "Wishlist For Clients" }, "WISHLIST_FOR_CLIENTS"), _jsx(Ant.Select.Option, { value: "CLIENTS_CAN_GET_CONTACTS", children: "Clients Can Get Contacts" }, "CLIENTS_CAN_GET_CONTACTS"), _jsx(Ant.Select.Option, { value: "TEXTCHAT", children: "Textchat" }, "TEXTCHAT"), _jsx(Ant.Select.Option, { value: "VIDEOCHAT", children: "Videochat" }, "VIDEOCHAT"), _jsx(Ant.Select.Option, { value: "VOICECHAT", children: "Voicechat" }, "VOICECHAT")] }) })),
            },
            {
                id: "projectType",
                label: t("management.subscription_feature_prices.fields.projectType"),
                name: ["projectType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.subscription_feature_prices.fields.projectType"), children: [_jsx(Ant.Select.Option, { value: "GALLERY", children: "Exquisite Gallery" }, "GALLERY"), _jsx(Ant.Select.Option, { value: "SHOP", children: "Shop" }, "SHOP"), _jsx(Ant.Select.Option, { value: "EXHIBITION", children: "Exhibition" }, "EXHIBITION"), _jsx(Ant.Select.Option, { value: "HALL", children: "Hall" }, "HALL")] }) })),
            },
            {
                id: "price",
                label: t("management.subscription_feature_prices.fields.price"),
                name: ["price"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "productId",
                label: t("management.subscription_feature_prices.fields.productId"),
                name: ["productId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.subscription_feature_prices.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.subscription_feature_prices.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.subscription_feature_prices.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.subscription_feature_prices.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "createdById",
                label: t("management.subscription_feature_prices.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.subscription_feature_prices.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.subscription_feature_prices.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.subscription_feature_prices.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
SubscriptionFeaturePriceListFiltersForm = __decorate([
    Service({ transient: true })
], SubscriptionFeaturePriceListFiltersForm);
export { SubscriptionFeaturePriceListFiltersForm };
