var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let ProductViewer = class ProductViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.products.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "name.values",
                label: t("management.products.fields.name.values"),
                dataIndex: ["name", "values"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "description.values",
                label: t("management.products.fields.description.values"),
                dataIndex: ["description", "values"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "mediaTypes",
                label: t("management.products.fields.mediaTypes"),
                dataIndex: ["mediaTypes"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "price",
                label: t("management.products.fields.price"),
                dataIndex: ["price"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "vat",
                label: t("management.products.fields.vat"),
                dataIndex: ["vat"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "manufacturer",
                label: t("management.products.fields.manufacturer"),
                dataIndex: ["manufacturer"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "href",
                label: t("management.products.fields.href"),
                dataIndex: ["href"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "tags",
                label: t("management.products.fields.tags"),
                dataIndex: ["tags"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "isPhysical",
                label: t("management.products.fields.isPhysical"),
                dataIndex: ["isPhysical"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "inventory.status",
                label: t("management.products.fields.inventory.status"),
                dataIndex: ["inventory", "status"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "inventory.stockKeepingUnit",
                label: t("management.products.fields.inventory.stockKeepingUnit"),
                dataIndex: ["inventory", "stockKeepingUnit"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "inventory.barcode",
                label: t("management.products.fields.inventory.barcode"),
                dataIndex: ["inventory", "barcode"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "options.sizes",
                label: t("management.products.fields.options.sizes"),
                dataIndex: ["options", "sizes"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "options.colours",
                label: t("management.products.fields.options.colours"),
                dataIndex: ["options", "colours"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "options.materials",
                label: t("management.products.fields.options.materials"),
                dataIndex: ["options", "materials"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "shipping.weight",
                label: t("management.products.fields.shipping.weight"),
                dataIndex: ["shipping", "weight"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "shipping.originCountryOrRegion",
                label: t("management.products.fields.shipping.originCountryOrRegion"),
                dataIndex: ["shipping", "originCountryOrRegion"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "shipping.harmonisedSystemCode",
                label: t("management.products.fields.shipping.harmonisedSystemCode"),
                dataIndex: ["shipping", "harmonisedSystemCode"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "seo.title",
                label: t("management.products.fields.seo.title"),
                dataIndex: ["seo", "title"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "seo.description",
                label: t("management.products.fields.seo.description"),
                dataIndex: ["seo", "description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdById",
                label: t("management.products.fields.createdById"),
                dataIndex: ["createdById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedById",
                label: t("management.products.fields.updatedById"),
                dataIndex: ["updatedById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                label: t("management.products.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                label: t("management.products.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "media",
                label: t("management.products.fields.media"),
                dataIndex: ["media"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MEDIAS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "category",
                label: t("management.products.fields.category"),
                dataIndex: ["category"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCT_CATEGORIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchant",
                label: t("management.products.fields.merchant"),
                dataIndex: ["merchant"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                label: t("management.products.fields.createdBy"),
                dataIndex: ["createdBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                label: t("management.products.fields.updatedBy"),
                dataIndex: ["updatedBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            mediaTypes: {
                type: 1,
                typeFor3D: 1,
            },
            price: 1,
            vat: 1,
            manufacturer: 1,
            href: 1,
            tags: 1,
            isPhysical: 1,
            inventory: {
                status: 1,
                stockKeepingUnit: 1,
                barcode: 1,
            },
            options: {
                sizes: 1,
                colours: 1,
                materials: 1,
            },
            shipping: {
                weight: 1,
                originCountryOrRegion: 1,
                harmonisedSystemCode: 1,
            },
            seo: {
                title: 1,
                description: 1,
            },
            createdById: 1,
            updatedById: 1,
            createdAt: 1,
            updatedAt: 1,
            media: {
                _id: 1,
                name: 1,
            },
            mediaId: 1,
            category: {
                _id: 1,
                name: 1,
            },
            categoryId: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
        };
    }
};
ProductViewer = __decorate([
    Service({ transient: true })
], ProductViewer);
export { ProductViewer };
