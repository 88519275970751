import "./i18n";
import { DISPUTES_LIST as BASE_DISPUTES_LIST, DISPUTES_CREATE as BASE_DISPUTES_CREATE, DISPUTES_EDIT as BASE_DISPUTES_EDIT, DISPUTES_VIEW as BASE_DISPUTES_VIEW, } from "./config/routes";
export const DISPUTES_LIST = {
    ...BASE_DISPUTES_LIST,
};
export const DISPUTES_CREATE = {
    ...BASE_DISPUTES_CREATE,
};
export const DISPUTES_EDIT = {
    ...BASE_DISPUTES_EDIT,
};
export const DISPUTES_VIEW = {
    ...BASE_DISPUTES_VIEW,
};
