import { MediasList } from "../components/List/MediasList";
import { MediasCreate } from "../components/Create/MediasCreate";
import { MediasEdit } from "../components/Edit/MediasEdit";
import { MediasView } from "../components/View/MediasView";
import { SettingFilled } from "@ant-design/icons";
export const MEDIAS_LIST = {
    path: "/admin/medias",
    component: MediasList,
    menu: {
        key: "MEDIAS_LIST",
        label: "management.medias.menu.title",
        icon: SettingFilled,
    },
};
export const MEDIAS_CREATE = {
    path: "/admin/medias/create",
    component: MediasCreate,
};
export const MEDIAS_EDIT = {
    path: "/admin/medias/:id/edit",
    component: MediasEdit,
};
export const MEDIAS_VIEW = {
    path: "/admin/medias/:id/view",
    component: MediasView,
};
