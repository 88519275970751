var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { MerchantsBillingPackagesCollection, } from "@bundles/UIAppBundle/collections";
let MerchantsBillingPackageCreateForm = class MerchantsBillingPackageCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "packageType",
                label: t("management.merchants_billing_packages.fields.packageType"),
                name: ["packageType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "BRONZE", label: "Bronze" },
                        { value: "SILVER", label: "Silver" },
                        { value: "GOLD", label: "Gold" },
                        { value: "TITAN", label: "Titan" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billing_packages.fields.packageType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billing_packages.fields.packageType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants_billing_packages.fields.packageType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "benefits",
                label: t("management.merchants_billing_packages.fields.benefits"),
                name: ["benefits"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "type",
                        label: t("management.merchants_billing_packages.fields.benefits.type"),
                        name: ["benefits", "type"],
                        required: true,
                        initialValue: [],
                        render: (props) => {
                            const options = [
                                { value: "ADD_360_IMAGE", label: "Add 360 Image" },
                                { value: "ADD_3D_OBJECT", label: "Add 3 D Object" },
                                { value: "ADD_10_3D_OBJECTS", label: "Add 10 3 D Objects" },
                                { value: "ADD_VIDEO", label: "Add Video" },
                                { value: "API_CALL_ACCESS", label: "Api Call Access" },
                                { value: "LOAD_1_PHOTO", label: "Load 1 Photo" },
                                { value: "LOAD_10_PHOTOS", label: "Load 10 Photos" },
                                {
                                    value: "WISHLIST_FOR_CLIENTS",
                                    label: "Wishlist For Clients",
                                },
                                {
                                    value: "CLIENTS_CAN_GET_CONTACTS",
                                    label: "Clients Can Get Contacts",
                                },
                                { value: "TEXTCHAT", label: "Textchat" },
                                { value: "VIDEOCHAT", label: "Videochat" },
                                { value: "VOICECHAT", label: "Voicechat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billing_packages.fields.benefits.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billing_packages.fields.benefits.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billing_packages.fields.benefits.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "isExtra",
                        label: t("management.merchants_billing_packages.fields.benefits.isExtra"),
                        name: ["benefits", "isExtra"],
                        required: true,
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
                    },
                    {
                        id: "isUISeparator",
                        label: t("management.merchants_billing_packages.fields.benefits.isUISeparator"),
                        name: ["benefits", "isUISeparator"],
                        required: true,
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
                    },
                ],
                initialValue: [],
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.merchants_billing_packages.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.MERCHANTS_BILLING_PACKAGES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.MERCHANTS_BILLING_PACKAGES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.MERCHANTS_BILLING_PACKAGES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => MerchantsBillingPackagesCollection),
    __metadata("design:type", typeof (_a = typeof MerchantsBillingPackagesCollection !== "undefined" && MerchantsBillingPackagesCollection) === "function" ? _a : Object)
], MerchantsBillingPackageCreateForm.prototype, "collection", void 0);
MerchantsBillingPackageCreateForm = __decorate([
    Service({ transient: true })
], MerchantsBillingPackageCreateForm);
export { MerchantsBillingPackageCreateForm };
