var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ProjectsCollection, ProductsCollection, WorldsAssetPositionsCollection, } from "@bundles/UIAppBundle/collections";
let ProjectAssetInfoListFiltersForm = class ProjectAssetInfoListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isEnabled",
                label: t("management.project_asset_infos.fields.isEnabled"),
                name: ["isEnabled"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "mediaType",
                label: t("management.project_asset_infos.fields.mediaType"),
                name: ["mediaType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.project_asset_infos.fields.mediaType"), children: [_jsx(Ant.Select.Option, { value: "IMAGE_2D", children: "Image 2 D" }, "IMAGE_2D"), _jsx(Ant.Select.Option, { value: "IMAGE_2D_CAROUSEL", children: "Image 2 D Carousel" }, "IMAGE_2D_CAROUSEL"), _jsx(Ant.Select.Option, { value: "IMAGE_360", children: "Image 360" }, "IMAGE_360"), _jsx(Ant.Select.Option, { value: "IMAGE_360_CAROUSEL", children: "Image 360 Carousel" }, "IMAGE_360_CAROUSEL"), _jsx(Ant.Select.Option, { value: "ITEM_3D", children: "Item 3 D" }, "ITEM_3D"), _jsx(Ant.Select.Option, { value: "VIDEO", children: "Video" }, "VIDEO")] }) })),
            },
            {
                id: "typeFor3D",
                label: t("management.project_asset_infos.fields.typeFor3D"),
                name: ["typeFor3D"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.project_asset_infos.fields.typeFor3D"), children: [_jsx(Ant.Select.Option, { value: "OBJ_MTL", children: "Obj Mtl" }, "OBJ_MTL"), _jsx(Ant.Select.Option, { value: "GLB", children: "Glb" }, "GLB")] }) })),
            },
            {
                id: "typeForVideo",
                label: t("management.project_asset_infos.fields.typeForVideo"),
                name: ["typeForVideo"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.project_asset_infos.fields.typeForVideo"), children: [_jsx(Ant.Select.Option, { value: "LINK", children: "Link" }, "LINK"), _jsx(Ant.Select.Option, { value: "UPLOAD", children: "Upload" }, "UPLOAD")] }) })),
            },
            {
                id: "action",
                label: t("management.project_asset_infos.fields.action"),
                name: ["action"],
                columns: true,
                nest: [
                    {
                        id: "type",
                        label: t("management.project_asset_infos.fields.action.type"),
                        name: ["action", "type"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "EXIT", label: "Exit" },
                                { value: "NEXT_ROOM", label: "Next Room" },
                                { value: "ADD_TO_BASKET", label: "Add To Basket" },
                                { value: "CHAT", label: "Chat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.project_asset_infos.fields.action.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.project_asset_infos.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.project_asset_infos.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "projectId",
                        label: t("management.project_asset_infos.fields.action.projectId"),
                        name: ["action", "projectId"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "deltaCoordinates",
                label: t("management.project_asset_infos.fields.deltaCoordinates"),
                name: ["deltaCoordinates"],
                columns: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.project_asset_infos.fields.deltaCoordinates.position"),
                        name: ["deltaCoordinates", "position"],
                        required: true,
                        tooltip: t("management.project_asset_infos.fields.deltaCoordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.project_asset_infos.fields.deltaCoordinates.rotation"),
                        name: ["deltaCoordinates", "rotation"],
                        required: true,
                        tooltip: t("management.project_asset_infos.fields.deltaCoordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.project_asset_infos.fields.deltaCoordinates.scale"),
                        name: ["deltaCoordinates", "scale"],
                        required: true,
                        tooltip: t("management.project_asset_infos.fields.deltaCoordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "projectId",
                label: t("management.project_asset_infos.fields.project"),
                name: ["projectId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "productId",
                label: t("management.project_asset_infos.fields.product"),
                name: ["productId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "translatedName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "assetPositionId",
                label: t("management.project_asset_infos.fields.assetPosition"),
                name: ["assetPositionId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: WorldsAssetPositionsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
ProjectAssetInfoListFiltersForm = __decorate([
    Service({ transient: true })
], ProjectAssetInfoListFiltersForm);
export { ProjectAssetInfoListFiltersForm };
