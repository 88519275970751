var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let MerchantsBillingList = class MerchantsBillingList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "renewalDate",
                title: t("management.merchants_billings.fields.renewalDate"),
                key: "management.merchants_billings.fields.renewalDate",
                dataIndex: ["renewalDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "packageType",
                title: t("management.merchants_billings.fields.packageType"),
                key: "management.merchants_billings.fields.packageType",
                dataIndex: ["packageType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "periodType",
                title: t("management.merchants_billings.fields.periodType"),
                key: "management.merchants_billings.fields.periodType",
                dataIndex: ["periodType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeSubscriptionId",
                title: t("management.merchants_billings.fields.stripeSubscriptionId"),
                key: "management.merchants_billings.fields.stripeSubscriptionId",
                dataIndex: ["stripeSubscriptionId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeSubscriptionSchedule.id",
                title: t("management.merchants_billings.fields.stripeSubscriptionSchedule.id"),
                key: "management.merchants_billings.fields.stripeSubscriptionSchedule.id",
                dataIndex: ["stripeSubscriptionSchedule", "id"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeSubscriptionSchedule.nextPhaseDate",
                title: t("management.merchants_billings.fields.stripeSubscriptionSchedule.nextPhaseDate"),
                key: "management.merchants_billings.fields.stripeSubscriptionSchedule.nextPhaseDate",
                dataIndex: ["stripeSubscriptionSchedule", "nextPhaseDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeSubscriptionSchedule.packageType",
                title: t("management.merchants_billings.fields.stripeSubscriptionSchedule.packageType"),
                key: "management.merchants_billings.fields.stripeSubscriptionSchedule.packageType",
                dataIndex: ["stripeSubscriptionSchedule", "packageType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeSubscriptionSchedule.periodType",
                title: t("management.merchants_billings.fields.stripeSubscriptionSchedule.periodType"),
                key: "management.merchants_billings.fields.stripeSubscriptionSchedule.periodType",
                dataIndex: ["stripeSubscriptionSchedule", "periodType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeSubscriptionSchedule.features",
                title: t("management.merchants_billings.fields.stripeSubscriptionSchedule.features"),
                key: "management.merchants_billings.fields.stripeSubscriptionSchedule.features",
                dataIndex: ["stripeSubscriptionSchedule", "features"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "enum",
                                    value,
                                    labelify: true,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "expiryDate",
                title: t("management.merchants_billings.fields.expiryDate"),
                key: "management.merchants_billings.fields.expiryDate",
                dataIndex: ["expiryDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isActive",
                title: t("management.merchants_billings.fields.isActive"),
                key: "management.merchants_billings.fields.isActive",
                dataIndex: ["isActive"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "status",
                title: t("management.merchants_billings.fields.status"),
                key: "management.merchants_billings.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "failedPaymentReasonCode",
                title: t("management.merchants_billings.fields.failedPaymentReasonCode"),
                key: "management.merchants_billings.fields.failedPaymentReasonCode",
                dataIndex: ["failedPaymentReasonCode"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "statusReason",
                title: t("management.merchants_billings.fields.statusReason"),
                key: "management.merchants_billings.fields.statusReason",
                dataIndex: ["statusReason"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "features",
                title: t("management.merchants_billings.fields.features"),
                key: "management.merchants_billings.fields.features",
                dataIndex: ["features"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "enum",
                                    value,
                                    labelify: true,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "project",
                title: t("management.merchants_billings.fields.project"),
                key: "management.merchants_billings.fields.project",
                dataIndex: ["project"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROJECTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchant",
                title: t("management.merchants_billings.fields.merchant"),
                key: "management.merchants_billings.fields.merchant",
                dataIndex: ["merchant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            project: "project.name",
            merchant: "merchant.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            renewalDate: 1,
            packageType: 1,
            periodType: 1,
            stripeSubscriptionId: 1,
            stripeSubscriptionSchedule: {
                id: 1,
                nextPhaseDate: 1,
                packageType: 1,
                periodType: 1,
                features: 1,
            },
            expiryDate: 1,
            isActive: 1,
            status: 1,
            failedPaymentReasonCode: 1,
            statusReason: 1,
            features: 1,
            project: {
                _id: 1,
                name: 1,
            },
            projectId: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
        };
    }
};
MerchantsBillingList = __decorate([
    Service({ transient: true })
], MerchantsBillingList);
export { MerchantsBillingList };
