var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { WorldsCollection, MediasCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let WorldsAssetPositionListFiltersForm = class WorldsAssetPositionListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "coordinates",
                label: t("management.worlds_asset_positions.fields.coordinates"),
                name: ["coordinates"],
                columns: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.worlds_asset_positions.fields.coordinates.position"),
                        name: ["coordinates", "position"],
                        required: true,
                        tooltip: t("management.worlds_asset_positions.fields.coordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.worlds_asset_positions.fields.coordinates.rotation"),
                        name: ["coordinates", "rotation"],
                        required: true,
                        tooltip: t("management.worlds_asset_positions.fields.coordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.worlds_asset_positions.fields.coordinates.scale"),
                        name: ["coordinates", "scale"],
                        required: true,
                        tooltip: t("management.worlds_asset_positions.fields.coordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "mediaType",
                label: t("management.worlds_asset_positions.fields.mediaType"),
                name: ["mediaType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.worlds_asset_positions.fields.mediaType"), children: [_jsx(Ant.Select.Option, { value: "IMAGE_2D", children: "Image 2 D" }, "IMAGE_2D"), _jsx(Ant.Select.Option, { value: "IMAGE_2D_CAROUSEL", children: "Image 2 D Carousel" }, "IMAGE_2D_CAROUSEL"), _jsx(Ant.Select.Option, { value: "IMAGE_360", children: "Image 360" }, "IMAGE_360"), _jsx(Ant.Select.Option, { value: "IMAGE_360_CAROUSEL", children: "Image 360 Carousel" }, "IMAGE_360_CAROUSEL"), _jsx(Ant.Select.Option, { value: "ITEM_3D", children: "Item 3 D" }, "ITEM_3D"), _jsx(Ant.Select.Option, { value: "VIDEO", children: "Video" }, "VIDEO")] }) })),
            },
            {
                id: "typeFor3D",
                label: t("management.worlds_asset_positions.fields.typeFor3D"),
                name: ["typeFor3D"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.worlds_asset_positions.fields.typeFor3D"), children: [_jsx(Ant.Select.Option, { value: "OBJ_MTL", children: "Obj Mtl" }, "OBJ_MTL"), _jsx(Ant.Select.Option, { value: "GLB", children: "Glb" }, "GLB")] }) })),
            },
            {
                id: "action",
                label: t("management.worlds_asset_positions.fields.action"),
                name: ["action"],
                columns: true,
                nest: [
                    {
                        id: "type",
                        label: t("management.worlds_asset_positions.fields.action.type"),
                        name: ["action", "type"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "EXIT", label: "Exit" },
                                { value: "NEXT_ROOM", label: "Next Room" },
                                { value: "ADD_TO_BASKET", label: "Add To Basket" },
                                { value: "CHAT", label: "Chat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.worlds_asset_positions.fields.action.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.worlds_asset_positions.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.worlds_asset_positions.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "projectId",
                        label: t("management.worlds_asset_positions.fields.action.projectId"),
                        name: ["action", "projectId"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "width",
                label: t("management.worlds_asset_positions.fields.width"),
                name: ["width"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "height",
                label: t("management.worlds_asset_positions.fields.height"),
                name: ["height"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.worlds_asset_positions.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.worlds_asset_positions.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.worlds_asset_positions.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.worlds_asset_positions.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "worldId",
                label: t("management.worlds_asset_positions.fields.world"),
                name: ["worldId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: WorldsCollection, field: "", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "mediaId",
                label: t("management.worlds_asset_positions.fields.media"),
                name: ["mediaId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MediasCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.worlds_asset_positions.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.worlds_asset_positions.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.worlds_asset_positions.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.worlds_asset_positions.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
WorldsAssetPositionListFiltersForm = __decorate([
    Service({ transient: true })
], WorldsAssetPositionListFiltersForm);
export { WorldsAssetPositionListFiltersForm };
