import "./i18n";
import { MERCHANTS_LIST as BASE_MERCHANTS_LIST, MERCHANTS_CREATE as BASE_MERCHANTS_CREATE, MERCHANTS_EDIT as BASE_MERCHANTS_EDIT, MERCHANTS_VIEW as BASE_MERCHANTS_VIEW, } from "./config/routes";
export const MERCHANTS_LIST = {
    ...BASE_MERCHANTS_LIST,
};
export const MERCHANTS_CREATE = {
    ...BASE_MERCHANTS_CREATE,
};
export const MERCHANTS_EDIT = {
    ...BASE_MERCHANTS_EDIT,
};
export const MERCHANTS_VIEW = {
    ...BASE_MERCHANTS_VIEW,
};
