import "./i18n";
import { ANALYTICS_LIST as BASE_ANALYTICS_LIST, ANALYTICS_CREATE as BASE_ANALYTICS_CREATE, ANALYTICS_EDIT as BASE_ANALYTICS_EDIT, ANALYTICS_VIEW as BASE_ANALYTICS_VIEW, } from "./config/routes";
export const ANALYTICS_LIST = {
    ...BASE_ANALYTICS_LIST,
};
export const ANALYTICS_CREATE = {
    ...BASE_ANALYTICS_CREATE,
};
export const ANALYTICS_EDIT = {
    ...BASE_ANALYTICS_EDIT,
};
export const ANALYTICS_VIEW = {
    ...BASE_ANALYTICS_VIEW,
};
