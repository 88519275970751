var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let WorldsAssetPositionList = class WorldsAssetPositionList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "coordinates.position",
                title: t("management.worlds_asset_positions.fields.coordinates.position"),
                key: "management.worlds_asset_positions.fields.coordinates.position",
                dataIndex: ["coordinates", "position"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "coordinates.rotation",
                title: t("management.worlds_asset_positions.fields.coordinates.rotation"),
                key: "management.worlds_asset_positions.fields.coordinates.rotation",
                dataIndex: ["coordinates", "rotation"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "coordinates.scale",
                title: t("management.worlds_asset_positions.fields.coordinates.scale"),
                key: "management.worlds_asset_positions.fields.coordinates.scale",
                dataIndex: ["coordinates", "scale"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "mediaType",
                title: t("management.worlds_asset_positions.fields.mediaType"),
                key: "management.worlds_asset_positions.fields.mediaType",
                dataIndex: ["mediaType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "typeFor3D",
                title: t("management.worlds_asset_positions.fields.typeFor3D"),
                key: "management.worlds_asset_positions.fields.typeFor3D",
                dataIndex: ["typeFor3D"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "action.type",
                title: t("management.worlds_asset_positions.fields.action.type"),
                key: "management.worlds_asset_positions.fields.action.type",
                dataIndex: ["action", "type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "action.projectId",
                title: t("management.worlds_asset_positions.fields.action.projectId"),
                key: "management.worlds_asset_positions.fields.action.projectId",
                dataIndex: ["action", "projectId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "width",
                title: t("management.worlds_asset_positions.fields.width"),
                key: "management.worlds_asset_positions.fields.width",
                dataIndex: ["width"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "height",
                title: t("management.worlds_asset_positions.fields.height"),
                key: "management.worlds_asset_positions.fields.height",
                dataIndex: ["height"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.worlds_asset_positions.fields.createdAt"),
                key: "management.worlds_asset_positions.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.worlds_asset_positions.fields.updatedAt"),
                key: "management.worlds_asset_positions.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "world",
                title: t("management.worlds_asset_positions.fields.world"),
                key: "management.worlds_asset_positions.fields.world",
                dataIndex: ["world"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLDS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name.text",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "media",
                title: t("management.worlds_asset_positions.fields.media"),
                key: "management.worlds_asset_positions.fields.media",
                dataIndex: ["media"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MEDIAS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.worlds_asset_positions.fields.createdBy"),
                key: "management.worlds_asset_positions.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.worlds_asset_positions.fields.updatedBy"),
                key: "management.worlds_asset_positions.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            world: "world.",
            media: "media.name",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            coordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            mediaType: 1,
            typeFor3D: 1,
            action: {
                type: 1,
                projectId: 1,
            },
            width: 1,
            height: 1,
            createdAt: 1,
            updatedAt: 1,
            world: {
                _id: 1,
                name: {
                    values: {
                        lang: 1,
                        text: 1,
                    },
                    text: 1,
                },
            },
            worldId: 1,
            media: {
                _id: 1,
                name: 1,
            },
            mediaId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
WorldsAssetPositionList = __decorate([
    Service({ transient: true })
], WorldsAssetPositionList);
export { WorldsAssetPositionList };
