var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, WorldCategoriesCollection, MerchantsCollection, } from "@bundles/UIAppBundle/collections";
import { I18NFieldCustomRenderer, RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
let WorldListFiltersForm = class WorldListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.worlds.fields.name"),
                name: ["name"],
                columns: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "description",
                label: t("management.worlds.fields.description"),
                name: ["description"],
                columns: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "startCoordinates",
                label: t("management.worlds.fields.startCoordinates"),
                name: ["startCoordinates"],
                columns: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.worlds.fields.startCoordinates.position"),
                        name: ["startCoordinates", "position"],
                        required: true,
                        tooltip: t("management.worlds.fields.startCoordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.worlds.fields.startCoordinates.rotation"),
                        name: ["startCoordinates", "rotation"],
                        required: true,
                        tooltip: t("management.worlds.fields.startCoordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.worlds.fields.startCoordinates.scale"),
                        name: ["startCoordinates", "scale"],
                        required: true,
                        tooltip: t("management.worlds.fields.startCoordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "yearlyProductId",
                label: t("management.worlds.fields.yearlyProductId"),
                name: ["yearlyProductId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "monthlyProductId",
                label: t("management.worlds.fields.monthlyProductId"),
                name: ["monthlyProductId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "merchantDefaultVideoCoordinates",
                label: t("management.worlds.fields.merchantDefaultVideoCoordinates"),
                name: ["merchantDefaultVideoCoordinates"],
                columns: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.worlds.fields.merchantDefaultVideoCoordinates.position"),
                        name: ["merchantDefaultVideoCoordinates", "position"],
                        required: true,
                        tooltip: t("management.worlds.fields.merchantDefaultVideoCoordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.worlds.fields.merchantDefaultVideoCoordinates.rotation"),
                        name: ["merchantDefaultVideoCoordinates", "rotation"],
                        required: true,
                        tooltip: t("management.worlds.fields.merchantDefaultVideoCoordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.worlds.fields.merchantDefaultVideoCoordinates.scale"),
                        name: ["merchantDefaultVideoCoordinates", "scale"],
                        required: true,
                        tooltip: t("management.worlds.fields.merchantDefaultVideoCoordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "capacity",
                label: t("management.worlds.fields.capacity"),
                name: ["capacity"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "usageRecommendations",
                label: t("management.worlds.fields.usageRecommendations"),
                name: ["usageRecommendations"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "isFeatured",
                label: t("management.worlds.fields.isFeatured"),
                name: ["isFeatured"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isHidden",
                label: t("management.worlds.fields.isHidden"),
                name: ["isHidden"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "createdAt",
                label: t("management.worlds.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.worlds.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.worlds.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.worlds.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "createdById",
                label: t("management.worlds.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.worlds.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.worlds.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.worlds.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "categoryId",
                label: t("management.worlds.fields.category"),
                name: ["categoryId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldCategoriesCollection, field: "name.text", otherQuery: nameQuery, placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "restrictedToMerchantId",
                label: t("management.worlds.fields.restrictedToMerchant"),
                name: ["restrictedToMerchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
WorldListFiltersForm = __decorate([
    Service({ transient: true })
], WorldListFiltersForm);
export { WorldListFiltersForm };
