var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ProjectsCollection, EndUsersCollection, OrderItemsCollection, BasketsCollection, OrdersCollection, } from "@bundles/UIAppBundle/collections";
let OrderCreateForm = class OrderCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "invoiceNumber",
                label: t("management.orders.fields.invoiceNumber"),
                name: ["invoiceNumber"],
                component: Ant.Input,
            },
            {
                id: "totalWithoutVAT",
                label: t("management.orders.fields.totalWithoutVAT"),
                name: ["totalWithoutVAT"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "total",
                label: t("management.orders.fields.total"),
                name: ["total"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "status",
                label: t("management.orders.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "DRAFT", label: "Draft" },
                        { value: "SUBMITTED", label: "Submitted" },
                        { value: "PAID", label: "Paid" },
                        { value: "UNPAID", label: "Unpaid" },
                        { value: "DISPUTE", label: "Dispute" },
                        { value: "CANCELED", label: "Canceled" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.orders.fields.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.orders.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.orders.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "submittedAt",
                label: t("management.orders.fields.submittedAt"),
                name: ["submittedAt"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "paymentType",
                label: t("management.orders.fields.paymentType"),
                name: ["paymentType"],
                render: (props) => {
                    const options = [{ value: "STRIPE", label: "Stripe" }];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.orders.fields.paymentType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.orders.fields.paymentType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.orders.fields.paymentType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "paidAt",
                label: t("management.orders.fields.paidAt"),
                name: ["paidAt"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: true }) })),
            },
            {
                id: "projectId",
                label: t("management.orders.fields.project"),
                name: ["projectId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", required: true }) })),
            },
            {
                id: "endUserId",
                label: t("management.orders.fields.endUser"),
                name: ["endUserId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUsersCollection, field: "fullName", required: true }) })),
            },
            {
                id: "itemsIds",
                label: t("management.orders.fields.items"),
                name: ["itemsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrderItemsCollection, field: "_id", required: true, mode: "multiple" }) })),
            },
            {
                id: "basketId",
                label: t("management.orders.fields.basket"),
                name: ["basketId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: BasketsCollection, field: "_id", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.orders.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.ORDERS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.ORDERS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.ORDERS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => OrdersCollection),
    __metadata("design:type", typeof (_a = typeof OrdersCollection !== "undefined" && OrdersCollection) === "function" ? _a : Object)
], OrderCreateForm.prototype, "collection", void 0);
OrderCreateForm = __decorate([
    Service({ transient: true })
], OrderCreateForm);
export { OrderCreateForm };
