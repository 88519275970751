var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { SubscriptionFeaturePricesCollection, } from "@bundles/UIAppBundle/collections";
let SubscriptionFeaturePriceCreateForm = class SubscriptionFeaturePriceCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "periodType",
                label: t("management.subscription_feature_prices.fields.periodType"),
                name: ["periodType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "MONTHLY", label: "Monthly" },
                        { value: "YEARLY", label: "Yearly" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.subscription_feature_prices.fields.periodType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.subscription_feature_prices.fields.periodType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.subscription_feature_prices.fields.periodType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "feature",
                label: t("management.subscription_feature_prices.fields.feature"),
                name: ["feature"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "ADD_360_IMAGE", label: "Add 360 Image" },
                        { value: "ADD_3D_OBJECT", label: "Add 3 D Object" },
                        { value: "ADD_10_3D_OBJECTS", label: "Add 10 3 D Objects" },
                        { value: "LOAD_1_PHOTO", label: "Load 1 Photo" },
                        { value: "LOAD_10_PHOTOS", label: "Load 10 Photos" },
                        { value: "WISHLIST_FOR_CLIENTS", label: "Wishlist For Clients" },
                        {
                            value: "CLIENTS_CAN_GET_CONTACTS",
                            label: "Clients Can Get Contacts",
                        },
                        { value: "TEXTCHAT", label: "Textchat" },
                        { value: "VIDEOCHAT", label: "Videochat" },
                        { value: "VOICECHAT", label: "Voicechat" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.subscription_feature_prices.fields.feature"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.subscription_feature_prices.fields.feature_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.subscription_feature_prices.fields.feature_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "projectType",
                label: t("management.subscription_feature_prices.fields.projectType"),
                name: ["projectType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "GALLERY", label: "Exquisite Gallery" },
                        { value: "SHOP", label: "Shop" },
                        { value: "EXHIBITION", label: "Exhibition" },
                        { value: "HALL", label: "Hall" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.subscription_feature_prices.fields.projectType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.subscription_feature_prices.fields.projectType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.subscription_feature_prices.fields.projectType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "productId",
                label: t("management.subscription_feature_prices.fields.productId"),
                name: ["productId"],
                required: true,
                component: Ant.Input,
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.subscription_feature_prices.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.SUBSCRIPTION_FEATURE_PRICES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.SUBSCRIPTION_FEATURE_PRICES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.SUBSCRIPTION_FEATURE_PRICES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SubscriptionFeaturePricesCollection),
    __metadata("design:type", typeof (_a = typeof SubscriptionFeaturePricesCollection !== "undefined" && SubscriptionFeaturePricesCollection) === "function" ? _a : Object)
], SubscriptionFeaturePriceCreateForm.prototype, "collection", void 0);
SubscriptionFeaturePriceCreateForm = __decorate([
    Service({ transient: true })
], SubscriptionFeaturePriceCreateForm);
export { SubscriptionFeaturePriceCreateForm };
