import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Alert, Card, } from "antd";
export function ForgotPassword() {
    const guardian = useGuardian();
    const router = useRouter();
    const t = useTranslate();
    const tl = useTranslate("authentication.forgotPassword");
    const [submitError, setSubmitError] = useState(null);
    const [isCompleted, setIsComplete] = useState(false);
    const onSubmit = (data) => {
        guardian
            .forgotPassword(data.email)
            .then(() => {
            setIsComplete(true);
        })
            .catch((err) => {
            setSubmitError(err.toString());
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, { justify: "center", align: "middle", style: style, className: "forgot-password-page", children: _jsx(Col, { sm: 24, md: 12, lg: 6, children: _jsxs(Card, { title: tl("header"), children: [isCompleted && _jsx(Alert, { type: "success", message: tl("success") }), !isCompleted && (_jsxs(Form, { onFinish: (data) => onSubmit(data), className: "authentication-form", children: [_jsx(Form.Item, { name: "email", rules: [{ required: true }], children: _jsx(Input, { placeholder: tl("fields.email") }) }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "authentication-form-button", children: tl("submit") }) }), submitError && (_jsx(Alert, { message: t("generics.error"), type: "error" }))] }))] }) }) }));
}
