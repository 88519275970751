import { DisputesRepliesList } from "../components/List/DisputesRepliesList";
import { DisputesRepliesCreate } from "../components/Create/DisputesRepliesCreate";
import { DisputesRepliesEdit } from "../components/Edit/DisputesRepliesEdit";
import { DisputesRepliesView } from "../components/View/DisputesRepliesView";
import { SettingFilled } from "@ant-design/icons";
export const DISPUTES_REPLIES_LIST = {
    path: "/admin/disputes-replies",
    component: DisputesRepliesList,
    menu: {
        key: "DISPUTES_REPLIES_LIST",
        label: "management.disputes_replies.menu.title",
        icon: SettingFilled,
    },
};
export const DISPUTES_REPLIES_CREATE = {
    path: "/admin/disputes-replies/create",
    component: DisputesRepliesCreate,
};
export const DISPUTES_REPLIES_EDIT = {
    path: "/admin/disputes-replies/:id/edit",
    component: DisputesRepliesEdit,
};
export const DISPUTES_REPLIES_VIEW = {
    path: "/admin/disputes-replies/:id/view",
    component: DisputesRepliesView,
};
