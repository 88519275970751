import { PublicChatMessagesList } from "../components/List/PublicChatMessagesList";
import { PublicChatMessagesCreate } from "../components/Create/PublicChatMessagesCreate";
import { PublicChatMessagesEdit } from "../components/Edit/PublicChatMessagesEdit";
import { PublicChatMessagesView } from "../components/View/PublicChatMessagesView";
import { SettingFilled } from "@ant-design/icons";
export const PUBLIC_CHAT_MESSAGES_LIST = {
    path: "/admin/public-chat-messages",
    component: PublicChatMessagesList,
    menu: {
        key: "PUBLIC_CHAT_MESSAGES_LIST",
        label: "management.public_chat_messages.menu.title",
        icon: SettingFilled,
    },
};
export const PUBLIC_CHAT_MESSAGES_CREATE = {
    path: "/admin/public-chat-messages/create",
    component: PublicChatMessagesCreate,
};
export const PUBLIC_CHAT_MESSAGES_EDIT = {
    path: "/admin/public-chat-messages/:id/edit",
    component: PublicChatMessagesEdit,
};
export const PUBLIC_CHAT_MESSAGES_VIEW = {
    path: "/admin/public-chat-messages/:id/view",
    component: PublicChatMessagesView,
};
