var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { WorldsCollection, ProjectsCategoriesCollection, MerchantsCollection, ProjectsCollection, } from "@bundles/UIAppBundle/collections";
import { I18NFieldCustomRenderer, RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
let ProjectCreateForm = class ProjectCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.projects.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "onSuccessfulInquiryRedirectURL",
                label: t("management.projects.fields.onSuccessfulInquiryRedirectURL"),
                name: ["onSuccessfulInquiryRedirectURL"],
                component: Ant.Input,
            },
            {
                id: "linkName",
                label: t("management.projects.fields.linkName"),
                name: ["linkName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.projects.fields.description"),
                name: ["description"],
                required: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "toDate",
                label: t("management.projects.fields.toDate"),
                name: ["toDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "liveDate",
                label: t("management.projects.fields.liveDate"),
                name: ["liveDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "state",
                label: t("management.projects.fields.state"),
                name: ["state"],
                required: true,
                initialValue: "UNPUBLISHED",
                render: (props) => {
                    const options = [
                        { value: "PUBLISHED", label: "Published" },
                        { value: "UNPUBLISHED", label: "Unpublished" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.projects.fields.state"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.projects.fields.state_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.projects.fields.state_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "type",
                label: t("management.projects.fields.type"),
                name: ["type"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "GALLERY", label: "Exquisite Gallery" },
                        { value: "SHOP", label: "Shop" },
                        { value: "EXHIBITION", label: "Exhibition" },
                        { value: "HALL", label: "Hall" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.projects.fields.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.projects.fields.type_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.projects.fields.type_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "watermarksEnabled",
                label: t("management.projects.fields.watermarksEnabled"),
                name: ["watermarksEnabled"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAvailableToVideoAndAudioChat",
                label: t("management.projects.fields.isAvailableToVideoAndAudioChat"),
                name: ["isAvailableToVideoAndAudioChat"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isApprovedByAdmin",
                label: t("management.projects.fields.isApprovedByAdmin"),
                name: ["isApprovedByAdmin"],
                required: true,
                tooltip: t("management.projects.fields.isApprovedByAdmin_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isSetup",
                label: t("management.projects.fields.isSetup"),
                name: ["isSetup"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "worldId",
                label: t("management.projects.fields.world"),
                name: ["worldId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", otherQuery: nameQuery, required: true }) })),
            },
            {
                id: "categoryId",
                label: t("management.projects.fields.category"),
                name: ["categoryId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCategoriesCollection, field: "_id", required: false }) })),
            },
            {
                id: "merchantId",
                label: t("management.projects.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
            {
                id: "watermarkId",
                label: t("management.projects.fields.watermark"),
                name: ["watermarkId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "logoId",
                label: t("management.projects.fields.logo"),
                name: ["logoId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.projects.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.PROJECTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PROJECTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PROJECTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProjectsCollection),
    __metadata("design:type", typeof (_a = typeof ProjectsCollection !== "undefined" && ProjectsCollection) === "function" ? _a : Object)
], ProjectCreateForm.prototype, "collection", void 0);
ProjectCreateForm = __decorate([
    Service({ transient: true })
], ProjectCreateForm);
export { ProjectCreateForm };
