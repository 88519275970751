var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { WorldsCollection, UsersCollection, PublicChatSessionsCollection, } from "@bundles/UIAppBundle/collections";
import { RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
let MerchantListFiltersForm = class MerchantListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.merchants.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "lastName",
                label: t("management.merchants.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "email",
                label: t("management.merchants.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "phoneNumber",
                label: t("management.merchants.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "consentForEmails",
                label: t("management.merchants.fields.consentForEmails"),
                name: ["consentForEmails"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "incomingChannel",
                label: t("management.merchants.fields.incomingChannel"),
                name: ["incomingChannel"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.merchants.fields.incomingChannel"), children: _jsx(Ant.Select.Option, { value: "NEW", children: "New" }, "NEW") }) })),
            },
            {
                id: "isAvailableToChat",
                label: t("management.merchants.fields.isAvailableToChat"),
                name: ["isAvailableToChat"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "chatSessionId",
                label: t("management.merchants.fields.chatSession"),
                name: ["chatSessionId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: PublicChatSessionsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "billingAddress",
                label: t("management.merchants.fields.billingAddress"),
                name: ["billingAddress"],
                columns: true,
                nest: [
                    {
                        id: "line1",
                        label: t("management.merchants.fields.billingAddress.line1"),
                        name: ["billingAddress", "line1"],
                        component: Ant.Input,
                    },
                    {
                        id: "line2",
                        label: t("management.merchants.fields.billingAddress.line2"),
                        name: ["billingAddress", "line2"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.merchants.fields.billingAddress.zip"),
                        name: ["billingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.merchants.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        component: Ant.Input,
                    },
                    {
                        id: "county",
                        label: t("management.merchants.fields.billingAddress.county"),
                        name: ["billingAddress", "county"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.merchants.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "stripeCustomerId",
                label: t("management.merchants.fields.stripeCustomerId"),
                name: ["stripeCustomerId"],
                tooltip: t("management.merchants.fields.stripeCustomerId_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "status",
                label: t("management.merchants.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.merchants.fields.status"), children: [_jsx(Ant.Select.Option, { value: "ACTIVE", children: "Active" }, "ACTIVE"), _jsx(Ant.Select.Option, { value: "INACTIVE", children: "Inactive" }, "INACTIVE")] }) })),
            },
            {
                id: "VAT",
                label: t("management.merchants.fields.VAT"),
                name: ["VAT"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "favouriteWorldsIds",
                label: t("management.merchants.fields.favouriteWorlds"),
                name: ["favouriteWorldsIds"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", otherQuery: nameQuery, placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "ownerId",
                label: t("management.merchants.fields.owner"),
                name: ["ownerId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
MerchantListFiltersForm = __decorate([
    Service({ transient: true })
], MerchantListFiltersForm);
export { MerchantListFiltersForm };
