import { VRAvatarsList } from "../components/List/VRAvatarsList";
import { VRAvatarsCreate } from "../components/Create/VRAvatarsCreate";
import { VRAvatarsEdit } from "../components/Edit/VRAvatarsEdit";
import { VRAvatarsView } from "../components/View/VRAvatarsView";
import { SettingFilled } from "@ant-design/icons";
export const VR_AVATARS_LIST = {
    path: "/admin/vr-avatars",
    component: VRAvatarsList,
    menu: {
        key: "VR_AVATARS_LIST",
        label: "management.vr_avatars.menu.title",
        icon: SettingFilled,
    },
};
export const VR_AVATARS_CREATE = {
    path: "/admin/vr-avatars/create",
    component: VRAvatarsCreate,
};
export const VR_AVATARS_EDIT = {
    path: "/admin/vr-avatars/:id/edit",
    component: VRAvatarsEdit,
};
export const VR_AVATARS_VIEW = {
    path: "/admin/vr-avatars/:id/view",
    component: VRAvatarsView,
};
