var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ProjectsCollection, ProductsCollection, MerchantsCollection, AnalyticsCollection, } from "@bundles/UIAppBundle/collections";
let AnalyticEditForm = class AnalyticEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "type",
                label: t("management.analytics.fields.type"),
                name: ["type"],
                required: true,
                render: (props) => {
                    const options = [
                        {
                            value: "REQUEST_DETAILS_ABOUT_PRODUCT",
                            label: "Request Details About Product",
                        },
                        { value: "JOIN_PROJECT", label: "Join Project" },
                        { value: "SEND_EMAIL", label: "Send Email" },
                        { value: "CHAT_WITH_MERCHANT", label: "Chat With Merchant" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.analytics.fields.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.analytics.fields.type_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.analytics.fields.type_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "assetMediaRenderType",
                label: t("management.analytics.fields.assetMediaRenderType"),
                name: ["assetMediaRenderType"],
                render: (props) => {
                    const options = [
                        { value: "IMAGE_2D", label: "Image 2 D" },
                        { value: "IMAGE_2D_CAROUSEL", label: "Image 2 D Carousel" },
                        { value: "IMAGE_360", label: "Image 360" },
                        { value: "IMAGE_360_CAROUSEL", label: "Image 360 Carousel" },
                        { value: "ITEM_3D", label: "Item 3 D" },
                        { value: "VIDEO", label: "Video" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.analytics.fields.assetMediaRenderType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.analytics.fields.assetMediaRenderType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.analytics.fields.assetMediaRenderType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "assetMediaTypeFor3D",
                label: t("management.analytics.fields.assetMediaTypeFor3D"),
                name: ["assetMediaTypeFor3D"],
                render: (props) => {
                    const options = [
                        { value: "OBJ_MTL", label: "Obj Mtl" },
                        { value: "GLB", label: "Glb" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.analytics.fields.assetMediaTypeFor3D"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.analytics.fields.assetMediaTypeFor3D_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.analytics.fields.assetMediaTypeFor3D_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "projectId",
                label: t("management.analytics.fields.project"),
                name: ["projectId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", required: false }) })),
            },
            {
                id: "productId",
                label: t("management.analytics.fields.product"),
                name: ["productId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "translatedName", required: false }) })),
            },
            {
                id: "merchantId",
                label: t("management.analytics.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            type: 1,
            assetMediaRenderType: 1,
            assetMediaTypeFor3D: 1,
            project: {
                _id: 1,
                name: 1,
            },
            projectId: 1,
            product: {
                _id: 1,
                translatedName: 1,
            },
            productId: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.analytics.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => AnalyticsCollection),
    __metadata("design:type", typeof (_a = typeof AnalyticsCollection !== "undefined" && AnalyticsCollection) === "function" ? _a : Object)
], AnalyticEditForm.prototype, "collection", void 0);
AnalyticEditForm = __decorate([
    Service({ transient: true })
], AnalyticEditForm);
export { AnalyticEditForm };
