var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { WorldsCollection, MerchantsCollection, } from "@bundles/UIAppBundle/collections";
import { RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
let FeedbackListFiltersForm = class FeedbackListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.feedbacks.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "email",
                label: t("management.feedbacks.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "message",
                label: t("management.feedbacks.fields.message"),
                name: ["message"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.feedbacks.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.feedbacks.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.feedbacks.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.feedbacks.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "worldId",
                label: t("management.feedbacks.fields.world"),
                name: ["worldId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", otherQuery: nameQuery, required: true }) })),
            },
            {
                id: "merchantId",
                label: t("management.feedbacks.fields.merchant"),
                name: ["merchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
FeedbackListFiltersForm = __decorate([
    Service({ transient: true })
], FeedbackListFiltersForm);
export { FeedbackListFiltersForm };
