var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { MerchantsCollection, DisputesCollection, } from "@bundles/UIAppBundle/collections";
let DisputeEditForm = class DisputeEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "identifier",
                label: t("management.disputes.fields.identifier"),
                name: ["identifier"],
                required: true,
                tooltip: t("management.disputes.fields.identifier_description"),
                component: Ant.Input,
            },
            {
                id: "status",
                label: t("management.disputes.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "OPEN", label: "Open" },
                        { value: "IN_PROGRESS", label: "In Progress" },
                        { value: "SOLVED", label: "Solved" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.disputes.fields.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.disputes.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.disputes.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "merchantId",
                label: t("management.disputes.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            identifier: 1,
            status: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.disputes.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => DisputesCollection),
    __metadata("design:type", typeof (_a = typeof DisputesCollection !== "undefined" && DisputesCollection) === "function" ? _a : Object)
], DisputeEditForm.prototype, "collection", void 0);
DisputeEditForm = __decorate([
    Service({ transient: true })
], DisputeEditForm);
export { DisputeEditForm };
