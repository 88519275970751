import { FeedbacksList } from "../components/List/FeedbacksList";
import { FeedbacksCreate } from "../components/Create/FeedbacksCreate";
import { FeedbacksEdit } from "../components/Edit/FeedbacksEdit";
import { FeedbacksView } from "../components/View/FeedbacksView";
import { SettingFilled } from "@ant-design/icons";
export const FEEDBACKS_LIST = {
    path: "/admin/feedbacks",
    component: FeedbacksList,
    menu: {
        key: "FEEDBACKS_LIST",
        label: "management.feedbacks.menu.title",
        icon: SettingFilled,
    },
};
export const FEEDBACKS_CREATE = {
    path: "/admin/feedbacks/create",
    component: FeedbacksCreate,
};
export const FEEDBACKS_EDIT = {
    path: "/admin/feedbacks/:id/edit",
    component: FeedbacksEdit,
};
export const FEEDBACKS_VIEW = {
    path: "/admin/feedbacks/:id/view",
    component: FeedbacksView,
};
