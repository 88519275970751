var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
let MerchantsBillingPackageList = class MerchantsBillingPackageList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "packageType",
                title: t("management.merchants_billing_packages.fields.packageType"),
                key: "management.merchants_billing_packages.fields.packageType",
                dataIndex: ["packageType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "benefits",
                title: t("management.merchants_billing_packages.fields.benefits"),
                key: "management.merchants_billing_packages.fields.benefits",
                dataIndex: ["benefits"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
        ]);
    }
    static getSortMap() {
        return {};
    }
    static getRequestBody() {
        return {
            _id: 1,
            packageType: 1,
            benefits: {
                type: 1,
                isExtra: 1,
                isUISeparator: 1,
            },
        };
    }
};
MerchantsBillingPackageList = __decorate([
    Service({ transient: true })
], MerchantsBillingPackageList);
export { MerchantsBillingPackageList };
