var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @overridable */
import { SmileOutlined } from "@ant-design/icons";
import { Inject, Service } from "@bluelibs/core";
import { XForm } from "@bluelibs/x-ui-admin";
import { MerchantsCollection, WorldsCollection } from "@bundles/UIAppBundle/collections";
import { I18NFieldCustomRenderer, RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
import * as Ant from "antd";
let WorldEditForm = class WorldEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.worlds.fields.name"),
                name: ["name"],
                required: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "description",
                label: t("management.worlds.fields.description"),
                name: ["description"],
                required: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "startCoordinates",
                label: t("management.worlds.fields.startCoordinates"),
                name: ["startCoordinates"],
                required: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.worlds.fields.startCoordinates.position"),
                        name: ["startCoordinates", "position"],
                        required: true,
                        tooltip: t("management.worlds.fields.startCoordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.worlds.fields.startCoordinates.rotation"),
                        name: ["startCoordinates", "rotation"],
                        required: true,
                        tooltip: t("management.worlds.fields.startCoordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.worlds.fields.startCoordinates.scale"),
                        name: ["startCoordinates", "scale"],
                        required: true,
                        tooltip: t("management.worlds.fields.startCoordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "yearlyProductId",
                label: t("management.worlds.fields.yearlyProductId"),
                name: ["yearlyProductId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "monthlyProductId",
                label: t("management.worlds.fields.monthlyProductId"),
                name: ["monthlyProductId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "merchantDefaultVideoCoordinates",
                label: t("management.worlds.fields.merchantDefaultVideoCoordinates"),
                name: ["merchantDefaultVideoCoordinates"],
                required: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.worlds.fields.merchantDefaultVideoCoordinates.position"),
                        name: ["merchantDefaultVideoCoordinates", "position"],
                        required: true,
                        tooltip: t("management.worlds.fields.merchantDefaultVideoCoordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.worlds.fields.merchantDefaultVideoCoordinates.rotation"),
                        name: ["merchantDefaultVideoCoordinates", "rotation"],
                        required: true,
                        tooltip: t("management.worlds.fields.merchantDefaultVideoCoordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.worlds.fields.merchantDefaultVideoCoordinates.scale"),
                        name: ["merchantDefaultVideoCoordinates", "scale"],
                        required: true,
                        tooltip: t("management.worlds.fields.merchantDefaultVideoCoordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "capacity",
                label: t("management.worlds.fields.capacity"),
                name: ["capacity"],
                component: Ant.InputNumber,
            },
            {
                id: "usageRecommendations",
                label: t("management.worlds.fields.usageRecommendations"),
                name: ["usageRecommendations"],
                component: Ant.Input,
            },
            {
                id: "isFeatured",
                label: t("management.worlds.fields.isFeatured"),
                name: ["isFeatured"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isHidden",
                label: t("management.worlds.fields.isHidden"),
                name: ["isHidden"],
                required: true,
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "worldFileId",
                label: t("management.worlds.fields.worldFile"),
                name: ["worldFileId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "meshFileId",
                label: t("management.worlds.fields.meshFile"),
                name: ["meshFileId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "pictureGalleryId",
                label: t("management.worlds.fields.pictureGallery"),
                name: ["pictureGalleryId"],
                component: UIComponents.AdminFileGroupUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "categoryId",
                label: t("management.worlds.fields.category"),
                name: ["categoryId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", otherQuery: nameQuery, required: true }) })),
            },
            {
                id: "restrictedToMerchantId",
                label: t("management.worlds.fields.restrictedToMerchant"),
                name: ["restrictedToMerchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: false }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            isHidden: 1,
            name: {
                values: {
                    lang: 1,
                    text: 1,
                },
            },
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
            },
            startCoordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            yearlyProductId: 1,
            monthlyProductId: 1,
            merchantDefaultVideoCoordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            capacity: 1,
            usageRecommendations: 1,
            isFeatured: 1,
            worldFile: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            worldFileId: 1,
            meshFile: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            meshFileId: 1,
            pictureGallery: {
                _id: 1,
                name: 1,
                files: {
                    downloadUrl: 1,
                    name: 1,
                },
            },
            pictureGalleryId: 1,
            category: {
                _id: 1,
                name: {
                    text: 1,
                    values: {
                        lang: 1,
                        text: 1,
                    },
                },
            },
            categoryId: 1,
            restrictedToMerchant: {
                _id: 1,
                fullName: 1,
            },
            restrictedToMerchantId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.worlds.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => WorldsCollection),
    __metadata("design:type", typeof (_a = typeof WorldsCollection !== "undefined" && WorldsCollection) === "function" ? _a : Object)
], WorldEditForm.prototype, "collection", void 0);
WorldEditForm = __decorate([
    Service({ transient: true })
], WorldEditForm);
export { WorldEditForm };
