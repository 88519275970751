var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ProjectsCollection, MerchantsCollection, UsersCollection, ProjectInvitesCollection, } from "@bundles/UIAppBundle/collections";
let ProjectInvitationCreateForm = class ProjectInvitationCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "email",
                label: t("management.project_invites.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "token",
                label: t("management.project_invites.fields.token"),
                name: ["token"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "projectId",
                label: t("management.project_invites.fields.project"),
                name: ["projectId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", required: true }) })),
            },
            {
                id: "merchantId",
                label: t("management.project_invites.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
            {
                id: "userId",
                label: t("management.project_invites.fields.user"),
                name: ["userId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.project_invites.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.PROJECT_INVITES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PROJECT_INVITES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PROJECT_INVITES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProjectInvitesCollection),
    __metadata("design:type", typeof (_a = typeof ProjectInvitesCollection !== "undefined" && ProjectInvitesCollection) === "function" ? _a : Object)
], ProjectInvitationCreateForm.prototype, "collection", void 0);
ProjectInvitationCreateForm = __decorate([
    Service({ transient: true })
], ProjectInvitationCreateForm);
export { ProjectInvitationCreateForm };
