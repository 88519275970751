import "./i18n";
import { PUBLIC_CHAT_MESSAGES_LIST as BASE_PUBLIC_CHAT_MESSAGES_LIST, PUBLIC_CHAT_MESSAGES_CREATE as BASE_PUBLIC_CHAT_MESSAGES_CREATE, PUBLIC_CHAT_MESSAGES_EDIT as BASE_PUBLIC_CHAT_MESSAGES_EDIT, PUBLIC_CHAT_MESSAGES_VIEW as BASE_PUBLIC_CHAT_MESSAGES_VIEW, } from "./config/routes";
export const PUBLIC_CHAT_MESSAGES_LIST = {
    ...BASE_PUBLIC_CHAT_MESSAGES_LIST,
};
export const PUBLIC_CHAT_MESSAGES_CREATE = {
    ...BASE_PUBLIC_CHAT_MESSAGES_CREATE,
};
export const PUBLIC_CHAT_MESSAGES_EDIT = {
    ...BASE_PUBLIC_CHAT_MESSAGES_EDIT,
};
export const PUBLIC_CHAT_MESSAGES_VIEW = {
    ...BASE_PUBLIC_CHAT_MESSAGES_VIEW,
};
