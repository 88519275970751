import "./i18n";
import { PUBLIC_CHAT_SESSIONS_LIST as BASE_PUBLIC_CHAT_SESSIONS_LIST, PUBLIC_CHAT_SESSIONS_CREATE as BASE_PUBLIC_CHAT_SESSIONS_CREATE, PUBLIC_CHAT_SESSIONS_EDIT as BASE_PUBLIC_CHAT_SESSIONS_EDIT, PUBLIC_CHAT_SESSIONS_VIEW as BASE_PUBLIC_CHAT_SESSIONS_VIEW, } from "./config/routes";
export const PUBLIC_CHAT_SESSIONS_LIST = {
    ...BASE_PUBLIC_CHAT_SESSIONS_LIST,
};
export const PUBLIC_CHAT_SESSIONS_CREATE = {
    ...BASE_PUBLIC_CHAT_SESSIONS_CREATE,
};
export const PUBLIC_CHAT_SESSIONS_EDIT = {
    ...BASE_PUBLIC_CHAT_SESSIONS_EDIT,
};
export const PUBLIC_CHAT_SESSIONS_VIEW = {
    ...BASE_PUBLIC_CHAT_SESSIONS_VIEW,
};
