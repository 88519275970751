import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ProjectsCollection } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import { DeleteOutlined, DownOutlined, EditOutlined, EyeOutlined, } from "@ant-design/icons";
import { AntTableSmart } from "@bluelibs/x-ui-admin";
import { features } from "../../config/features";
import { ProjectList } from "../../config/ProjectList";
export class ProjectsAntTableSmart extends AntTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = ProjectsCollection;
    }
    getBody() {
        return ProjectList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(ProjectList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            title: this.i18n.t("generics.list_actions"),
            key: "actions",
            fixed: "right",
            width: 180,
            render: (_, model) => {
                return this.generateActions(model, {
                    label: this.i18n.t("generics.list_actions"),
                    icon: _jsx(DownOutlined, {}),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return ProjectList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(EyeOutlined, {}),
                action: (model) => {
                    this.router.go(Routes.PROJECTS_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.edit) {
            actions.push({
                label: this.i18n.t("generics.edit"),
                icon: _jsx(EditOutlined, {}),
                action: (model) => {
                    this.router.go(Routes.PROJECTS_EDIT, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.delete) {
            actions.push({
                label: this.i18n.t("generics.delete"),
                icon: _jsx(DeleteOutlined, {}),
                confirm: this.i18n.t("generics.ask_confirmation"),
                action: (model) => {
                    this.collection.deleteOne(model._id).then(() => {
                        this.load();
                    });
                },
            });
        }
        return actions;
    }
}
