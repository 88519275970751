var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let EndUserList = class EndUserList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                title: t("management.end_users.fields.firstName"),
                key: "management.end_users.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "lastName",
                title: t("management.end_users.fields.lastName"),
                key: "management.end_users.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "fullName",
                title: t("management.end_users.fields.fullName"),
                key: "management.end_users.fields.fullName",
                dataIndex: ["fullName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                title: t("management.end_users.fields.email"),
                key: "management.end_users.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "phoneNumber",
                title: t("management.end_users.fields.phoneNumber"),
                key: "management.end_users.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "wishlistItems",
                title: t("management.end_users.fields.wishlistItems"),
                key: "management.end_users.fields.wishlistItems",
                dataIndex: ["wishlistItems"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WISHLIST_ITEMS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "basket",
                title: t("management.end_users.fields.basket"),
                key: "management.end_users.fields.basket",
                dataIndex: ["basket"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BASKETS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "owner",
                title: t("management.end_users.fields.owner"),
                key: "management.end_users.fields.owner",
                dataIndex: ["owner"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            wishlistItems: "wishlistItems._id",
            basket: "basket._id",
            owner: "owner.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            firstName: 1,
            lastName: 1,
            fullName: 1,
            email: 1,
            phoneNumber: 1,
            wishlistItems: {
                _id: 1,
            },
            basket: {
                _id: 1,
            },
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
        };
    }
};
EndUserList = __decorate([
    Service({ transient: true })
], EndUserList);
export { EndUserList };
