var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let ProjectAssetInfoViewer = class ProjectAssetInfoViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.project_asset_infos.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isEnabled",
                label: t("management.project_asset_infos.fields.isEnabled"),
                dataIndex: ["isEnabled"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "mediaType",
                label: t("management.project_asset_infos.fields.mediaType"),
                dataIndex: ["mediaType"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "typeFor3D",
                label: t("management.project_asset_infos.fields.typeFor3D"),
                dataIndex: ["typeFor3D"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "typeForVideo",
                label: t("management.project_asset_infos.fields.typeForVideo"),
                dataIndex: ["typeForVideo"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "action.type",
                label: t("management.project_asset_infos.fields.action.type"),
                dataIndex: ["action", "type"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "action.projectId",
                label: t("management.project_asset_infos.fields.action.projectId"),
                dataIndex: ["action", "projectId"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "deltaCoordinates.position",
                label: t("management.project_asset_infos.fields.deltaCoordinates.position"),
                dataIndex: ["deltaCoordinates", "position"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "deltaCoordinates.rotation",
                label: t("management.project_asset_infos.fields.deltaCoordinates.rotation"),
                dataIndex: ["deltaCoordinates", "rotation"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "deltaCoordinates.scale",
                label: t("management.project_asset_infos.fields.deltaCoordinates.scale"),
                dataIndex: ["deltaCoordinates", "scale"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "project",
                label: t("management.project_asset_infos.fields.project"),
                dataIndex: ["project"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROJECTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "product",
                label: t("management.project_asset_infos.fields.product"),
                dataIndex: ["product"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "translatedName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "assetPosition",
                label: t("management.project_asset_infos.fields.assetPosition"),
                dataIndex: ["assetPosition"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLDS_ASSET_POSITIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            isEnabled: 1,
            mediaType: 1,
            typeFor3D: 1,
            typeForVideo: 1,
            action: {
                type: 1,
                projectId: 1,
            },
            deltaCoordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            project: {
                _id: 1,
                name: 1,
            },
            projectId: 1,
            product: {
                _id: 1,
                translatedName: 1,
            },
            productId: 1,
            assetPosition: {
                _id: 1,
            },
            assetPositionId: 1,
        };
    }
};
ProjectAssetInfoViewer = __decorate([
    Service({ transient: true })
], ProjectAssetInfoViewer);
export { ProjectAssetInfoViewer };
