import { Collection, } from "@bluelibs/x-ui";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { DisputesCollection, WorldsCollection, EndUserInquiriesCollection, ProjectsCollection, PublicChatSessionsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class MerchantsCollection extends Collection {
    getName() {
        return "Merchants";
    }
    getInputs() {
        return {
            insert: "MerchantInsertInput!",
            update: "MerchantUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => WorldsCollection,
                name: "favouriteWorlds",
                many: true,
                field: "favouriteWorldsIds",
            },
            {
                collection: () => PublicChatSessionsCollection,
                name: "chatSession",
                field: "chatSessionId",
            },
            {
                collection: () => AppFilesCollection,
                name: "avatar",
                field: "avatarId",
            },
            {
                collection: () => UsersCollection,
                name: "owner",
                field: "ownerId",
            },
            {
                collection: () => DisputesCollection,
                name: "disputes",
                many: true,
            },
            {
                collection: () => EndUserInquiriesCollection,
                name: "inquiries",
                many: true,
            },
            {
                collection: () => ProjectsCollection,
                name: "projects",
                many: true,
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
