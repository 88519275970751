import "./i18n";
import { DEMO_PROJECTS_LIST as BASE_DEMO_PROJECTS_LIST, DEMO_PROJECTS_CREATE as BASE_DEMO_PROJECTS_CREATE, DEMO_PROJECTS_EDIT as BASE_DEMO_PROJECTS_EDIT, DEMO_PROJECTS_VIEW as BASE_DEMO_PROJECTS_VIEW, } from "./config/routes";
export const DEMO_PROJECTS_LIST = {
    ...BASE_DEMO_PROJECTS_LIST,
};
export const DEMO_PROJECTS_CREATE = {
    ...BASE_DEMO_PROJECTS_CREATE,
};
export const DEMO_PROJECTS_EDIT = {
    ...BASE_DEMO_PROJECTS_EDIT,
};
export const DEMO_PROJECTS_VIEW = {
    ...BASE_DEMO_PROJECTS_VIEW,
};
