var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let UserCreateForm = class UserCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "preferredLanguage",
                label: t("management.users.fields.preferredLanguage"),
                name: ["preferredLanguage"],
                render: (props) => {
                    const options = [
                        { value: "en", label: "En" },
                        { value: "de", label: "De" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.users.fields.preferredLanguage"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.users.fields.preferredLanguage_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.users.fields.preferredLanguage_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "activeNotifications",
                label: t("management.users.fields.activeNotifications"),
                name: ["activeNotifications"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ENQUIRY", label: "Enquiry" },
                        { value: "NEW_MESSAGE", label: "New Message" },
                        { value: "SOMEONE_SPEAKS", label: "Someone Speaks" },
                        { value: "SYSTEM_UPDATE", label: "System Update" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.users.fields.activeNotifications"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.users.fields.activeNotifications_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.users.fields.activeNotifications_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "deletionInfo",
                label: t("management.users.fields.deletionInfo"),
                name: ["deletionInfo"],
                nest: [
                    {
                        id: "reason",
                        label: t("management.users.fields.deletionInfo.reason"),
                        name: ["deletionInfo", "reason"],
                        render: (props) => {
                            const options = [
                                { value: "NO_INTEREST", label: "No Interest" },
                                { value: "FAULTY_SYSTEM", label: "Faulty System" },
                                { value: "SPEED", label: "Speed" },
                                { value: "UGLY_WORLDS", label: "Ugly Worlds" },
                                { value: "OTHER", label: "Other" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.users.fields.deletionInfo.reason"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.users.fields.deletionInfo.reason_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.users.fields.deletionInfo.reason_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "feedback",
                        label: t("management.users.fields.deletionInfo.feedback"),
                        name: ["deletionInfo", "feedback"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "roles",
                label: t("management.users.fields.roles"),
                name: ["roles"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ADMIN", label: "Admin" },
                        { value: "ENDUSER", label: "Enduser" },
                        { value: "WORLD_ADMIN", label: "World Admin" },
                        { value: "MERCHANT", label: "Merchant" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.users.fields.roles"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.users.fields.roles_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.users.fields.roles_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "isEnabled",
                label: t("management.users.fields.isEnabled"),
                name: ["isEnabled"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "profile",
                label: t("management.users.fields.profile"),
                name: ["profile"],
                required: true,
                nest: [
                    {
                        id: "firstName",
                        label: t("management.users.fields.profile.firstName"),
                        name: ["profile", "firstName"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.users.fields.profile.lastName"),
                        name: ["profile", "lastName"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.users.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.USERS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.USERS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.USERS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => UsersCollection),
    __metadata("design:type", typeof (_a = typeof UsersCollection !== "undefined" && UsersCollection) === "function" ? _a : Object)
], UserCreateForm.prototype, "collection", void 0);
UserCreateForm = __decorate([
    Service({ transient: true })
], UserCreateForm);
export { UserCreateForm };
