import { Collection, } from "@bluelibs/x-ui";
import { ProjectsCollection, ProductsCollection, WorldsAssetPositionsCollection, } from "@bundles/UIAppBundle/collections";
export class ProjectAssetInfosCollection extends Collection {
    getName() {
        return "ProjectAssetInfos";
    }
    getInputs() {
        return {
            insert: "ProjectAssetInfoInsertInput!",
            update: "ProjectAssetInfoUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProjectsCollection,
                name: "project",
                field: "projectId",
            },
            {
                collection: () => ProductsCollection,
                name: "product",
                field: "productId",
            },
            {
                collection: () => WorldsAssetPositionsCollection,
                name: "assetPosition",
                field: "assetPositionId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
