import { Collection, } from "@bluelibs/x-ui";
import { MerchantsCollection, ProductsCollection, EndUsersCollection, } from "@bundles/UIAppBundle/collections";
export class EndUserInquiriesCollection extends Collection {
    getName() {
        return "EndUserInquiries";
    }
    getInputs() {
        return {
            insert: "EndUserInquiryInsertInput!",
            update: "EndUserInquiryUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => MerchantsCollection,
                name: "merchant",
                field: "merchantId",
            },
            {
                collection: () => ProductsCollection,
                name: "products",
                many: true,
                field: "productsIds",
            },
            {
                collection: () => EndUsersCollection,
                name: "endUser",
                field: "endUserId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
