import { OrderItemsList } from "../components/List/OrderItemsList";
import { OrderItemsCreate } from "../components/Create/OrderItemsCreate";
import { OrderItemsEdit } from "../components/Edit/OrderItemsEdit";
import { OrderItemsView } from "../components/View/OrderItemsView";
import { SettingFilled } from "@ant-design/icons";
export const ORDER_ITEMS_LIST = {
    path: "/admin/order-items",
    component: OrderItemsList,
    menu: {
        key: "ORDER_ITEMS_LIST",
        label: "management.order_items.menu.title",
        icon: SettingFilled,
    },
};
export const ORDER_ITEMS_CREATE = {
    path: "/admin/order-items/create",
    component: OrderItemsCreate,
};
export const ORDER_ITEMS_EDIT = {
    path: "/admin/order-items/:id/edit",
    component: OrderItemsEdit,
};
export const ORDER_ITEMS_VIEW = {
    path: "/admin/order-items/:id/view",
    component: OrderItemsView,
};
