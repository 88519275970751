var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { DemoUsersCollection, } from "@bundles/UIAppBundle/collections";
let DemoUserEditForm = class DemoUserEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "username",
                label: t("management.demo_users.fields.username"),
                name: ["username"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "password",
                label: t("management.demo_users.fields.password"),
                name: ["password"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.demo_users.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "firstName",
                label: t("management.demo_users.fields.firstName"),
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.demo_users.fields.lastName"),
                name: ["lastName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "roles",
                label: t("management.demo_users.fields.roles"),
                name: ["roles"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ADMIN", label: "Admin" },
                        { value: "ENDUSER", label: "Enduser" },
                        { value: "WORLD_ADMIN", label: "World Admin" },
                        { value: "MERCHANT", label: "Merchant" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.demo_users.fields.roles"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.demo_users.fields.roles_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.demo_users.fields.roles_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "activeNotifications",
                label: t("management.demo_users.fields.activeNotifications"),
                name: ["activeNotifications"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ENQUIRY", label: "Enquiry" },
                        { value: "NEW_MESSAGE", label: "New Message" },
                        { value: "SOMEONE_SPEAKS", label: "Someone Speaks" },
                        { value: "SYSTEM_UPDATE", label: "System Update" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.demo_users.fields.activeNotifications"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.demo_users.fields.activeNotifications_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.demo_users.fields.activeNotifications_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "preferredLanguage",
                label: t("management.demo_users.fields.preferredLanguage"),
                name: ["preferredLanguage"],
                render: (props) => {
                    const options = [
                        { value: "en", label: "En" },
                        { value: "de", label: "De" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.demo_users.fields.preferredLanguage"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.demo_users.fields.preferredLanguage_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.demo_users.fields.preferredLanguage_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            username: 1,
            password: 1,
            email: 1,
            firstName: 1,
            lastName: 1,
            roles: 1,
            activeNotifications: 1,
            preferredLanguage: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.demo_users.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => DemoUsersCollection),
    __metadata("design:type", typeof (_a = typeof DemoUsersCollection !== "undefined" && DemoUsersCollection) === "function" ? _a : Object)
], DemoUserEditForm.prototype, "collection", void 0);
DemoUserEditForm = __decorate([
    Service({ transient: true })
], DemoUserEditForm);
export { DemoUserEditForm };
