var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let MediaViewer = class MediaViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.medias.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "name",
                label: t("management.medias.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "videoUrl",
                label: t("management.medias.fields.videoUrl"),
                dataIndex: ["videoUrl"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "image360Files",
                label: t("management.medias.fields.image360Files"),
                dataIndex: ["image360Files"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "files",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "image2dFiles",
                label: t("management.medias.fields.image2dFiles"),
                dataIndex: ["image2dFiles"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "files",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "image3dOBJ",
                label: t("management.medias.fields.image3dOBJ"),
                dataIndex: ["image3dOBJ"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "image3dMTL",
                label: t("management.medias.fields.image3dMTL"),
                dataIndex: ["image3dMTL"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "image3DGLB",
                label: t("management.medias.fields.image3DGLB"),
                dataIndex: ["image3DGLB"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "videoUpload",
                label: t("management.medias.fields.videoUpload"),
                dataIndex: ["videoUpload"],
                render: (value) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                label: t("management.medias.fields.createdBy"),
                dataIndex: ["createdBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                label: t("management.medias.fields.updatedBy"),
                dataIndex: ["updatedBy"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            videoUrl: 1,
            image360Files: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image360FilesIds: 1,
            image2dFiles: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image2dFilesIds: 1,
            image3dOBJ: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image3dOBJId: 1,
            image3dMTL: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image3dMTLId: 1,
            image3DGLB: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image3DGLBId: 1,
            videoUpload: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            videoUploadId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
MediaViewer = __decorate([
    Service({ transient: true })
], MediaViewer);
export { MediaViewer };
