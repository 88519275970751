var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let DemoMerchantList = class DemoMerchantList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                title: t("management.demo_merchants.fields.firstName"),
                key: "management.demo_merchants.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "lastName",
                title: t("management.demo_merchants.fields.lastName"),
                key: "management.demo_merchants.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                title: t("management.demo_merchants.fields.email"),
                key: "management.demo_merchants.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "phoneNumber",
                title: t("management.demo_merchants.fields.phoneNumber"),
                key: "management.demo_merchants.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "consentForEmails",
                title: t("management.demo_merchants.fields.consentForEmails"),
                key: "management.demo_merchants.fields.consentForEmails",
                dataIndex: ["consentForEmails"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isAvailableToChat",
                title: t("management.demo_merchants.fields.isAvailableToChat"),
                key: "management.demo_merchants.fields.isAvailableToChat",
                dataIndex: ["isAvailableToChat"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.line1",
                title: t("management.demo_merchants.fields.billingAddress.line1"),
                key: "management.demo_merchants.fields.billingAddress.line1",
                dataIndex: ["billingAddress", "line1"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.line2",
                title: t("management.demo_merchants.fields.billingAddress.line2"),
                key: "management.demo_merchants.fields.billingAddress.line2",
                dataIndex: ["billingAddress", "line2"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.zip",
                title: t("management.demo_merchants.fields.billingAddress.zip"),
                key: "management.demo_merchants.fields.billingAddress.zip",
                dataIndex: ["billingAddress", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.city",
                title: t("management.demo_merchants.fields.billingAddress.city"),
                key: "management.demo_merchants.fields.billingAddress.city",
                dataIndex: ["billingAddress", "city"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.county",
                title: t("management.demo_merchants.fields.billingAddress.county"),
                key: "management.demo_merchants.fields.billingAddress.county",
                dataIndex: ["billingAddress", "county"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.country",
                title: t("management.demo_merchants.fields.billingAddress.country"),
                key: "management.demo_merchants.fields.billingAddress.country",
                dataIndex: ["billingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeCustomerId",
                title: t("management.demo_merchants.fields.stripeCustomerId"),
                key: "management.demo_merchants.fields.stripeCustomerId",
                dataIndex: ["stripeCustomerId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "status",
                title: t("management.demo_merchants.fields.status"),
                key: "management.demo_merchants.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "VAT",
                title: t("management.demo_merchants.fields.VAT"),
                key: "management.demo_merchants.fields.VAT",
                dataIndex: ["VAT"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "OAuth.secretKey",
                title: t("management.demo_merchants.fields.OAuth.secretKey"),
                key: "management.demo_merchants.fields.OAuth.secretKey",
                dataIndex: ["OAuth", "secretKey"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "OAuth.activationDate",
                title: t("management.demo_merchants.fields.OAuth.activationDate"),
                key: "management.demo_merchants.fields.OAuth.activationDate",
                dataIndex: ["OAuth", "activationDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "OAuth.regenerationDate",
                title: t("management.demo_merchants.fields.OAuth.regenerationDate"),
                key: "management.demo_merchants.fields.OAuth.regenerationDate",
                dataIndex: ["OAuth", "regenerationDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlAvatar",
                title: t("management.demo_merchants.fields.downloadUrlAvatar"),
                key: "management.demo_merchants.fields.downloadUrlAvatar",
                dataIndex: ["downloadUrlAvatar"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "commonIdFavouriteWorlds",
                title: t("management.demo_merchants.fields.commonIdFavouriteWorlds"),
                key: "management.demo_merchants.fields.commonIdFavouriteWorlds",
                dataIndex: ["commonIdFavouriteWorlds"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "objectId",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "createdAt",
                title: t("management.demo_merchants.fields.createdAt"),
                key: "management.demo_merchants.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.demo_merchants.fields.updatedAt"),
                key: "management.demo_merchants.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "owner",
                title: t("management.demo_merchants.fields.owner"),
                key: "management.demo_merchants.fields.owner",
                dataIndex: ["owner"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.DEMO_USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            owner: "owner._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            firstName: 1,
            lastName: 1,
            email: 1,
            phoneNumber: 1,
            consentForEmails: 1,
            isAvailableToChat: 1,
            billingAddress: {
                line1: 1,
                line2: 1,
                zip: 1,
                city: 1,
                county: 1,
                country: 1,
            },
            stripeCustomerId: 1,
            status: 1,
            VAT: 1,
            OAuth: {
                secretKey: 1,
                activationDate: 1,
                regenerationDate: 1,
            },
            downloadUrlAvatar: 1,
            commonIdFavouriteWorlds: 1,
            createdAt: 1,
            updatedAt: 1,
            owner: {
                _id: 1,
            },
            ownerId: 1,
        };
    }
};
DemoMerchantList = __decorate([
    Service({ transient: true })
], DemoMerchantList);
export { DemoMerchantList };
