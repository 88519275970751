import { Collection, } from "@bluelibs/x-ui";
import { EndUsersCollection, OrdersCollection, BasketItemsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class BasketsCollection extends Collection {
    getName() {
        return "Baskets";
    }
    getInputs() {
        return {
            insert: "BasketInsertInput!",
            update: "BasketUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => EndUsersCollection,
                name: "endUser",
                field: "endUserId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => OrdersCollection,
                name: "order",
            },
            {
                collection: () => BasketItemsCollection,
                name: "items",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
