import { DisputesList } from "../components/List/DisputesList";
import { DisputesCreate } from "../components/Create/DisputesCreate";
import { DisputesEdit } from "../components/Edit/DisputesEdit";
import { DisputesView } from "../components/View/DisputesView";
import { SettingFilled } from "@ant-design/icons";
export const DISPUTES_LIST = {
    path: "/admin/disputes",
    component: DisputesList,
    menu: {
        key: "DISPUTES_LIST",
        label: "management.disputes.menu.title",
        icon: SettingFilled,
    },
};
export const DISPUTES_CREATE = {
    path: "/admin/disputes/create",
    component: DisputesCreate,
};
export const DISPUTES_EDIT = {
    path: "/admin/disputes/:id/edit",
    component: DisputesEdit,
};
export const DISPUTES_VIEW = {
    path: "/admin/disputes/:id/view",
    component: DisputesView,
};
