var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ProjectList = class ProjectList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                title: t("management.projects.fields.name"),
                key: "management.projects.fields.name",
                dataIndex: ["name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "onSuccessfulInquiryRedirectURL",
                title: t("management.projects.fields.onSuccessfulInquiryRedirectURL"),
                key: "management.projects.fields.onSuccessfulInquiryRedirectURL",
                dataIndex: ["onSuccessfulInquiryRedirectURL"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "linkName",
                title: t("management.projects.fields.linkName"),
                key: "management.projects.fields.linkName",
                dataIndex: ["linkName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "description.values",
                title: t("management.projects.fields.description.values"),
                key: "management.projects.fields.description.values",
                dataIndex: ["description", "values"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "toDate",
                title: t("management.projects.fields.toDate"),
                key: "management.projects.fields.toDate",
                dataIndex: ["toDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "liveDate",
                title: t("management.projects.fields.liveDate"),
                key: "management.projects.fields.liveDate",
                dataIndex: ["liveDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "state",
                title: t("management.projects.fields.state"),
                key: "management.projects.fields.state",
                dataIndex: ["state"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "type",
                title: t("management.projects.fields.type"),
                key: "management.projects.fields.type",
                dataIndex: ["type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "watermarksEnabled",
                title: t("management.projects.fields.watermarksEnabled"),
                key: "management.projects.fields.watermarksEnabled",
                dataIndex: ["watermarksEnabled"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isAvailableToVideoAndAudioChat",
                title: t("management.projects.fields.isAvailableToVideoAndAudioChat"),
                key: "management.projects.fields.isAvailableToVideoAndAudioChat",
                dataIndex: ["isAvailableToVideoAndAudioChat"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isApprovedByAdmin",
                title: t("management.projects.fields.isApprovedByAdmin"),
                key: "management.projects.fields.isApprovedByAdmin",
                dataIndex: ["isApprovedByAdmin"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isSetup",
                title: t("management.projects.fields.isSetup"),
                key: "management.projects.fields.isSetup",
                dataIndex: ["isSetup"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.projects.fields.createdAt"),
                key: "management.projects.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.projects.fields.updatedAt"),
                key: "management.projects.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "world",
                title: t("management.projects.fields.world"),
                key: "management.projects.fields.world",
                dataIndex: ["world"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLDS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name.text",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billing",
                title: t("management.projects.fields.billing"),
                key: "management.projects.fields.billing",
                dataIndex: ["billing"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_BILLINGS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "packageType",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "category",
                title: t("management.projects.fields.category"),
                key: "management.projects.fields.category",
                dataIndex: ["category"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROJECTS_CATEGORIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "assetInfos",
                title: t("management.projects.fields.assetInfos"),
                key: "management.projects.fields.assetInfos",
                dataIndex: ["assetInfos"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.PROJECT_ASSET_INFOS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "_id",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "merchant",
                title: t("management.projects.fields.merchant"),
                key: "management.projects.fields.merchant",
                dataIndex: ["merchant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "watermark",
                title: t("management.projects.fields.watermark"),
                key: "management.projects.fields.watermark",
                dataIndex: ["watermark"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "logo",
                title: t("management.projects.fields.logo"),
                key: "management.projects.fields.logo",
                dataIndex: ["logo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.projects.fields.createdBy"),
                key: "management.projects.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.projects.fields.updatedBy"),
                key: "management.projects.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            world: "world.name.text",
            billing: "billing.packageType",
            category: "category._id",
            assetInfos: "assetInfos._id",
            merchant: "merchant.fullName",
            watermark: "watermark._id",
            logo: "logo._id",
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            linkName: 1,
            onSuccessfulInquiryRedirectURL: 1,
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            toDate: 1,
            liveDate: 1,
            state: 1,
            type: 1,
            watermarksEnabled: 1,
            isAvailableToVideoAndAudioChat: 1,
            isApprovedByAdmin: 1,
            isSetup: 1,
            createdAt: 1,
            updatedAt: 1,
            world: {
                _id: 1,
                name: {
                    text: 1,
                    values: {
                        lang: 1,
                        text: 1,
                    },
                },
            },
            worldId: 1,
            billing: {
                _id: 1,
                packageType: 1,
            },
            category: {
                _id: 1,
            },
            categoryId: 1,
            assetInfos: {
                _id: 1,
            },
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
            watermark: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            watermarkId: 1,
            logo: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            logoId: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
ProjectList = __decorate([
    Service({ transient: true })
], ProjectList);
export { ProjectList };
