var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { MerchantsCollection, ProductsCollection, EndUsersCollection, EndUserInquiriesCollection, } from "@bundles/UIAppBundle/collections";
let EndUserInquiryEditForm = class EndUserInquiryEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "email",
                label: t("management.end_user_inquiries.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: t("management.end_user_inquiries.fields.phoneNumber"),
                name: ["phoneNumber"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "firstName",
                label: t("management.end_user_inquiries.fields.firstName"),
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.end_user_inquiries.fields.lastName"),
                name: ["lastName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "isNew",
                label: t("management.end_user_inquiries.fields.isNew"),
                name: ["isNew"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "productsWithQuantities",
                label: t("management.end_user_inquiries.fields.productsWithQuantities"),
                name: ["productsWithQuantities"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "productId",
                        label: t("management.end_user_inquiries.fields.productsWithQuantities.productId"),
                        name: ["productsWithQuantities", "productId"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "projectId",
                        label: t("management.end_user_inquiries.fields.productsWithQuantities.projectId"),
                        name: ["productsWithQuantities", "projectId"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                    {
                        id: "quantity",
                        label: t("management.end_user_inquiries.fields.productsWithQuantities.quantity"),
                        name: ["productsWithQuantities", "quantity"],
                        required: true,
                        initialValue: [],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "product",
                        label: t("management.end_user_inquiries.fields.productsWithQuantities.product"),
                        name: ["productsWithQuantities", "product"],
                        nest: [],
                        initialValue: [],
                    },
                    {
                        id: "project",
                        label: t("management.end_user_inquiries.fields.productsWithQuantities.project"),
                        name: ["productsWithQuantities", "project"],
                        nest: [],
                        initialValue: [],
                    },
                ],
                initialValue: [],
            },
            {
                id: "merchantId",
                label: t("management.end_user_inquiries.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
            {
                id: "productsIds",
                label: t("management.end_user_inquiries.fields.products"),
                name: ["productsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductsCollection, field: "translatedName", required: true, mode: "multiple" }) })),
            },
            {
                id: "endUserId",
                label: t("management.end_user_inquiries.fields.endUser"),
                name: ["endUserId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: EndUsersCollection, field: "fullName", required: false }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            email: 1,
            phoneNumber: 1,
            firstName: 1,
            lastName: 1,
            isNew: 1,
            productsWithQuantities: {
                productId: 1,
                projectId: 1,
                quantity: 1,
            },
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
            products: {
                _id: 1,
                translatedName: 1,
            },
            productsIds: 1,
            endUser: {
                _id: 1,
                fullName: 1,
            },
            endUserId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.end_user_inquiries.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => EndUserInquiriesCollection),
    __metadata("design:type", typeof (_a = typeof EndUserInquiriesCollection !== "undefined" && EndUserInquiriesCollection) === "function" ? _a : Object)
], EndUserInquiryEditForm.prototype, "collection", void 0);
EndUserInquiryEditForm = __decorate([
    Service({ transient: true })
], EndUserInquiryEditForm);
export { EndUserInquiryEditForm };
