import { Collection, } from "@bluelibs/x-ui";
export class MerchantsBillingPackagesCollection extends Collection {
    getName() {
        return "MerchantsBillingPackages";
    }
    getInputs() {
        return {
            insert: "MerchantsBillingPackageInsertInput!",
            update: "MerchantsBillingPackageUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
