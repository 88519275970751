import { MerchantsList } from "../components/List/MerchantsList";
import { MerchantsCreate } from "../components/Create/MerchantsCreate";
import { MerchantsEdit } from "../components/Edit/MerchantsEdit";
import { MerchantsView } from "../components/View/MerchantsView";
import { SettingFilled } from "@ant-design/icons";
export const MERCHANTS_LIST = {
    path: "/admin/merchants",
    component: MerchantsList,
    menu: {
        key: "MERCHANTS_LIST",
        label: "management.merchants.menu.title",
        icon: SettingFilled,
    },
};
export const MERCHANTS_CREATE = {
    path: "/admin/merchants/create",
    component: MerchantsCreate,
};
export const MERCHANTS_EDIT = {
    path: "/admin/merchants/:id/edit",
    component: MerchantsEdit,
};
export const MERCHANTS_VIEW = {
    path: "/admin/merchants/:id/view",
    component: MerchantsView,
};
