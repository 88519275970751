var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { DemoMediasCollection, DemoMerchantsCollection, DemoProductsCollection, } from "@bundles/UIAppBundle/collections";
let DemoProductEditForm = class DemoProductEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.demo_products.fields.name"),
                name: ["name"],
                required: true,
                nest: [
                    {
                        id: "values",
                        label: t("management.demo_products.fields.name.values"),
                        name: ["name", "values"],
                        required: true,
                        isList: true,
                        nest: [],
                    },
                    {
                        id: "text",
                        label: t("management.demo_products.fields.name.text"),
                        name: ["name", "text"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "description",
                label: t("management.demo_products.fields.description"),
                name: ["description"],
                required: true,
                nest: [
                    {
                        id: "values",
                        label: t("management.demo_products.fields.description.values"),
                        name: ["description", "values"],
                        required: true,
                        isList: true,
                        nest: [],
                    },
                    {
                        id: "text",
                        label: t("management.demo_products.fields.description.text"),
                        name: ["description", "text"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "price",
                label: t("management.demo_products.fields.price"),
                name: ["price"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "vat",
                label: t("management.demo_products.fields.vat"),
                name: ["vat"],
                component: Ant.InputNumber,
            },
            {
                id: "manufacturer",
                label: t("management.demo_products.fields.manufacturer"),
                name: ["manufacturer"],
                component: Ant.Input,
            },
            {
                id: "href",
                label: t("management.demo_products.fields.href"),
                name: ["href"],
                component: Ant.Input,
            },
            {
                id: "tags",
                label: t("management.demo_products.fields.tags"),
                name: ["tags"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "isPhysical",
                label: t("management.demo_products.fields.isPhysical"),
                name: ["isPhysical"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "inventory",
                label: t("management.demo_products.fields.inventory"),
                name: ["inventory"],
                nest: [
                    {
                        id: "status",
                        label: t("management.demo_products.fields.inventory.status"),
                        name: ["inventory", "status"],
                        render: (props) => {
                            const options = [
                                { value: "IN_STOCK", label: "In Stock" },
                                { value: "OUT_OF_STOCK", label: "Out Of Stock" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.demo_products.fields.inventory.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.demo_products.fields.inventory.status_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.demo_products.fields.inventory.status_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "stockKeepingUnit",
                        label: t("management.demo_products.fields.inventory.stockKeepingUnit"),
                        name: ["inventory", "stockKeepingUnit"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "barcode",
                        label: t("management.demo_products.fields.inventory.barcode"),
                        name: ["inventory", "barcode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "options",
                label: t("management.demo_products.fields.options"),
                name: ["options"],
                nest: [
                    {
                        id: "sizes",
                        label: t("management.demo_products.fields.options.sizes"),
                        name: ["options", "sizes"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "colours",
                        label: t("management.demo_products.fields.options.colours"),
                        name: ["options", "colours"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "materials",
                        label: t("management.demo_products.fields.options.materials"),
                        name: ["options", "materials"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                ],
            },
            {
                id: "shipping",
                label: t("management.demo_products.fields.shipping"),
                name: ["shipping"],
                nest: [
                    {
                        id: "weight",
                        label: t("management.demo_products.fields.shipping.weight"),
                        name: ["shipping", "weight"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "originCountryOrRegion",
                        label: t("management.demo_products.fields.shipping.originCountryOrRegion"),
                        name: ["shipping", "originCountryOrRegion"],
                        component: Ant.Input,
                    },
                    {
                        id: "harmonisedSystemCode",
                        label: t("management.demo_products.fields.shipping.harmonisedSystemCode"),
                        name: ["shipping", "harmonisedSystemCode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "seo",
                label: t("management.demo_products.fields.seo"),
                name: ["seo"],
                nest: [
                    {
                        id: "title",
                        label: t("management.demo_products.fields.seo.title"),
                        name: ["seo", "title"],
                        component: Ant.Input,
                    },
                    {
                        id: "description",
                        label: t("management.demo_products.fields.seo.description"),
                        name: ["seo", "description"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "commonIdCategory",
                label: t("management.demo_products.fields.commonIdCategory"),
                name: ["commonIdCategory"],
                component: Ant.Input,
            },
            {
                id: "mediaId",
                label: t("management.demo_products.fields.media"),
                name: ["mediaId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: DemoMediasCollection, field: "_id", required: true }) })),
            },
            {
                id: "merchantId",
                label: t("management.demo_products.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: DemoMerchantsCollection, field: "_id", required: true }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: {
                values: {
                    lang: 1,
                    text: 1,
                },
            },
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
            },
            price: 1,
            vat: 1,
            manufacturer: 1,
            href: 1,
            tags: 1,
            isPhysical: 1,
            inventory: {
                status: 1,
                stockKeepingUnit: 1,
                barcode: 1,
            },
            options: {
                sizes: 1,
                colours: 1,
                materials: 1,
            },
            shipping: {
                weight: 1,
                originCountryOrRegion: 1,
                harmonisedSystemCode: 1,
            },
            seo: {
                title: 1,
                description: 1,
            },
            commonIdCategory: 1,
            media: {
                _id: 1,
            },
            mediaId: 1,
            merchant: {
                _id: 1,
            },
            merchantId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.demo_products.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => DemoProductsCollection),
    __metadata("design:type", typeof (_a = typeof DemoProductsCollection !== "undefined" && DemoProductsCollection) === "function" ? _a : Object)
], DemoProductEditForm.prototype, "collection", void 0);
DemoProductEditForm = __decorate([
    Service({ transient: true })
], DemoProductEditForm);
export { DemoProductEditForm };
