var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let WorldReviewList = class WorldReviewList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "rating",
                title: t("management.world_reviews.fields.rating"),
                key: "management.world_reviews.fields.rating",
                dataIndex: ["rating"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "comment",
                title: t("management.world_reviews.fields.comment"),
                key: "management.world_reviews.fields.comment",
                dataIndex: ["comment"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.world_reviews.fields.createdAt"),
                key: "management.world_reviews.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.world_reviews.fields.updatedAt"),
                key: "management.world_reviews.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchant",
                title: t("management.world_reviews.fields.merchant"),
                key: "management.world_reviews.fields.merchant",
                dataIndex: ["merchant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "world",
                title: t("management.world_reviews.fields.world"),
                key: "management.world_reviews.fields.world",
                dataIndex: ["world"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLDS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name.text",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            merchant: "merchant.fullName",
            world: "world.name.text",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            rating: 1,
            comment: 1,
            createdAt: 1,
            updatedAt: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
            world: {
                _id: 1,
                name: {
                    text: 1,
                    values: {
                        lang: 1,
                        text: 1,
                    },
                },
            },
            worldId: 1,
        };
    }
};
WorldReviewList = __decorate([
    Service({ transient: true })
], WorldReviewList);
export { WorldReviewList };
