var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
let DemoMediaViewer = class DemoMediaViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.demo_medias.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "name",
                label: t("management.demo_medias.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlImage360Files",
                label: t("management.demo_medias.fields.downloadUrlImage360Files"),
                dataIndex: ["downloadUrlImage360Files"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "downloadUrlImage2dFiles",
                label: t("management.demo_medias.fields.downloadUrlImage2dFiles"),
                dataIndex: ["downloadUrlImage2dFiles"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "downloadUrlImage3dOBJ",
                label: t("management.demo_medias.fields.downloadUrlImage3dOBJ"),
                dataIndex: ["downloadUrlImage3dOBJ"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlImage3dMTL",
                label: t("management.demo_medias.fields.downloadUrlImage3dMTL"),
                dataIndex: ["downloadUrlImage3dMTL"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlImage3DGLB",
                label: t("management.demo_medias.fields.downloadUrlImage3DGLB"),
                dataIndex: ["downloadUrlImage3DGLB"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlVideoUpload",
                label: t("management.demo_medias.fields.downloadUrlVideoUpload"),
                dataIndex: ["downloadUrlVideoUpload"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "videoUrl",
                label: t("management.demo_medias.fields.videoUrl"),
                dataIndex: ["videoUrl"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                label: t("management.demo_medias.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                label: t("management.demo_medias.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            downloadUrlImage360Files: 1,
            downloadUrlImage2dFiles: 1,
            downloadUrlImage3dOBJ: 1,
            downloadUrlImage3dMTL: 1,
            downloadUrlImage3DGLB: 1,
            downloadUrlVideoUpload: 1,
            videoUrl: 1,
            createdAt: 1,
            updatedAt: 1,
        };
    }
};
DemoMediaViewer = __decorate([
    Service({ transient: true })
], DemoMediaViewer);
export { DemoMediaViewer };
