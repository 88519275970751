import { Collection, } from "@bluelibs/x-ui";
import { WishlistItemsCollection, BasketsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class EndUsersCollection extends Collection {
    getName() {
        return "EndUsers";
    }
    getInputs() {
        return {
            insert: "EndUserInsertInput!",
            update: "EndUserUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "owner",
                field: "ownerId",
            },
            {
                collection: () => WishlistItemsCollection,
                name: "wishlistItems",
            },
            {
                collection: () => BasketsCollection,
                name: "basket",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
