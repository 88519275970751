import { Collection, } from "@bluelibs/x-ui";
import { ProjectsCollection, MerchantsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class ProjectInvitesCollection extends Collection {
    getName() {
        return "ProjectInvites";
    }
    getInputs() {
        return {
            insert: "ProjectInvitationInsertInput!",
            update: "ProjectInvitationUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProjectsCollection,
                name: "project",
                field: "projectId",
            },
            {
                collection: () => MerchantsCollection,
                name: "merchant",
                field: "merchantId",
            },
            {
                collection: () => UsersCollection,
                name: "user",
                field: "userId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
