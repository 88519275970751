var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { WorldsCollection, UsersCollection, MerchantsCollection, PublicChatSessionsCollection, } from "@bundles/UIAppBundle/collections";
import { RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
let MerchantCreateForm = class MerchantCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.merchants.fields.firstName"),
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.merchants.fields.lastName"),
                name: ["lastName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.merchants.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: t("management.merchants.fields.phoneNumber"),
                name: ["phoneNumber"],
                component: Ant.Input,
            },
            {
                id: "consentForEmails",
                label: t("management.merchants.fields.consentForEmails"),
                name: ["consentForEmails"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "incomingChannel",
                label: t("management.merchants.fields.incomingChannel"),
                name: ["incomingChannel"],
                render: (props) => {
                    const options = [{ value: "NEW", label: "New" }];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.merchants.fields.incomingChannel"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants.fields.incomingChannel_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants.fields.incomingChannel_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "isAvailableToChat",
                label: t("management.merchants.fields.isAvailableToChat"),
                name: ["isAvailableToChat"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "billingAddress",
                label: t("management.merchants.fields.billingAddress"),
                name: ["billingAddress"],
                nest: [
                    {
                        id: "line1",
                        label: t("management.merchants.fields.billingAddress.line1"),
                        name: ["billingAddress", "line1"],
                        component: Ant.Input,
                    },
                    {
                        id: "line2",
                        label: t("management.merchants.fields.billingAddress.line2"),
                        name: ["billingAddress", "line2"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.merchants.fields.billingAddress.zip"),
                        name: ["billingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.merchants.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        component: Ant.Input,
                    },
                    {
                        id: "county",
                        label: t("management.merchants.fields.billingAddress.county"),
                        name: ["billingAddress", "county"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.merchants.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "stripeCustomerId",
                label: t("management.merchants.fields.stripeCustomerId"),
                name: ["stripeCustomerId"],
                tooltip: t("management.merchants.fields.stripeCustomerId_description"),
                component: Ant.Input,
            },
            {
                id: "chatSessionId",
                label: t("management.merchants.fields.chatSession"),
                name: ["chatSessionId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: PublicChatSessionsCollection, field: "_id", required: true }) })),
            },
            {
                id: "status",
                label: t("management.merchants.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "ACTIVE", label: "Active" },
                        { value: "INACTIVE", label: "Inactive" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants.fields.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "VAT",
                label: t("management.merchants.fields.VAT"),
                name: ["VAT"],
                component: Ant.Input,
            },
            {
                id: "favouriteWorldsIds",
                label: t("management.merchants.fields.favouriteWorlds"),
                name: ["favouriteWorldsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", mode: "multiple", otherQuery: nameQuery, required: true }) })),
            },
            {
                id: "avatarId",
                label: t("management.merchants.fields.avatar"),
                name: ["avatarId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "ownerId",
                label: t("management.merchants.fields.owner"),
                name: ["ownerId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.merchants.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.MERCHANTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.MERCHANTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.MERCHANTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => MerchantsCollection),
    __metadata("design:type", typeof (_a = typeof MerchantsCollection !== "undefined" && MerchantsCollection) === "function" ? _a : Object)
], MerchantCreateForm.prototype, "collection", void 0);
MerchantCreateForm = __decorate([
    Service({ transient: true })
], MerchantCreateForm);
export { MerchantCreateForm };
