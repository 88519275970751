var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let UserListFiltersForm = class UserListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "preferredLanguage",
                label: t("management.users.fields.preferredLanguage"),
                name: ["preferredLanguage"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.users.fields.preferredLanguage"), children: [_jsx(Ant.Select.Option, { value: "en", children: "En" }, "en"), _jsx(Ant.Select.Option, { value: "de", children: "De" }, "de")] }) })),
            },
            {
                id: "activeNotifications",
                label: t("management.users.fields.activeNotifications"),
                name: ["activeNotifications"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.users.fields.activeNotifications"), children: [_jsx(Ant.Select.Option, { value: "ENQUIRY", children: "Enquiry" }, "ENQUIRY"), _jsx(Ant.Select.Option, { value: "NEW_MESSAGE", children: "New Message" }, "NEW_MESSAGE"), _jsx(Ant.Select.Option, { value: "SOMEONE_SPEAKS", children: "Someone Speaks" }, "SOMEONE_SPEAKS"), _jsx(Ant.Select.Option, { value: "SYSTEM_UPDATE", children: "System Update" }, "SYSTEM_UPDATE")] }) })),
            },
            {
                id: "deletionInfo",
                label: t("management.users.fields.deletionInfo"),
                name: ["deletionInfo"],
                columns: true,
                nest: [
                    {
                        id: "reason",
                        label: t("management.users.fields.deletionInfo.reason"),
                        name: ["deletionInfo", "reason"],
                        render: (props) => {
                            const options = [
                                { value: "NO_INTEREST", label: "No Interest" },
                                { value: "FAULTY_SYSTEM", label: "Faulty System" },
                                { value: "SPEED", label: "Speed" },
                                { value: "UGLY_WORLDS", label: "Ugly Worlds" },
                                { value: "OTHER", label: "Other" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.users.fields.deletionInfo.reason"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.users.fields.deletionInfo.reason_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.users.fields.deletionInfo.reason_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "feedback",
                        label: t("management.users.fields.deletionInfo.feedback"),
                        name: ["deletionInfo", "feedback"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "roles",
                label: t("management.users.fields.roles"),
                name: ["roles"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.users.fields.roles"), children: [_jsx(Ant.Select.Option, { value: "ADMIN", children: "Admin" }, "ADMIN"), _jsx(Ant.Select.Option, { value: "ENDUSER", children: "Enduser" }, "ENDUSER"), _jsx(Ant.Select.Option, { value: "WORLD_ADMIN", children: "World Admin" }, "WORLD_ADMIN"), _jsx(Ant.Select.Option, { value: "MERCHANT", children: "Merchant" }, "MERCHANT")] }) })),
            },
            {
                id: "updatedAt",
                label: t("management.users.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.users.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.users.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.users.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "isEnabled",
                label: t("management.users.fields.isEnabled"),
                name: ["isEnabled"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "profile",
                label: t("management.users.fields.profile"),
                name: ["profile"],
                columns: true,
                nest: [
                    {
                        id: "firstName",
                        label: t("management.users.fields.profile.firstName"),
                        name: ["profile", "firstName"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.users.fields.profile.lastName"),
                        name: ["profile", "lastName"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdById",
                label: t("management.users.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.users.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.users.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.users.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
UserListFiltersForm = __decorate([
    Service({ transient: true })
], UserListFiltersForm);
export { UserListFiltersForm };
