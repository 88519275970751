import { DemoMediasList } from "../components/List/DemoMediasList";
import { DemoMediasCreate } from "../components/Create/DemoMediasCreate";
import { DemoMediasEdit } from "../components/Edit/DemoMediasEdit";
import { DemoMediasView } from "../components/View/DemoMediasView";
import { SettingFilled } from "@ant-design/icons";
export const DEMO_MEDIAS_LIST = {
    path: "/admin/demo-medias",
    component: DemoMediasList,
    menu: {
        key: "DEMO_MEDIAS_LIST",
        label: "management.demo_medias.menu.title",
        icon: SettingFilled,
    },
};
export const DEMO_MEDIAS_CREATE = {
    path: "/admin/demo-medias/create",
    component: DemoMediasCreate,
};
export const DEMO_MEDIAS_EDIT = {
    path: "/admin/demo-medias/:id/edit",
    component: DemoMediasEdit,
};
export const DEMO_MEDIAS_VIEW = {
    path: "/admin/demo-medias/:id/view",
    component: DemoMediasView,
};
