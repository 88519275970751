var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { DemoMediasCollection, } from "@bundles/UIAppBundle/collections";
let DemoMediaEditForm = class DemoMediaEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.demo_medias.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "downloadUrlImage360Files",
                label: t("management.demo_medias.fields.downloadUrlImage360Files"),
                name: ["downloadUrlImage360Files"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "downloadUrlImage2dFiles",
                label: t("management.demo_medias.fields.downloadUrlImage2dFiles"),
                name: ["downloadUrlImage2dFiles"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "downloadUrlImage3dOBJ",
                label: t("management.demo_medias.fields.downloadUrlImage3dOBJ"),
                name: ["downloadUrlImage3dOBJ"],
                component: Ant.Input,
            },
            {
                id: "downloadUrlImage3dMTL",
                label: t("management.demo_medias.fields.downloadUrlImage3dMTL"),
                name: ["downloadUrlImage3dMTL"],
                component: Ant.Input,
            },
            {
                id: "downloadUrlImage3DGLB",
                label: t("management.demo_medias.fields.downloadUrlImage3DGLB"),
                name: ["downloadUrlImage3DGLB"],
                component: Ant.Input,
            },
            {
                id: "downloadUrlVideoUpload",
                label: t("management.demo_medias.fields.downloadUrlVideoUpload"),
                name: ["downloadUrlVideoUpload"],
                component: Ant.Input,
            },
            {
                id: "videoUrl",
                label: t("management.demo_medias.fields.videoUrl"),
                name: ["videoUrl"],
                component: Ant.Input,
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            downloadUrlImage360Files: 1,
            downloadUrlImage2dFiles: 1,
            downloadUrlImage3dOBJ: 1,
            downloadUrlImage3dMTL: 1,
            downloadUrlImage3DGLB: 1,
            downloadUrlVideoUpload: 1,
            videoUrl: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.demo_medias.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => DemoMediasCollection),
    __metadata("design:type", typeof (_a = typeof DemoMediasCollection !== "undefined" && DemoMediasCollection) === "function" ? _a : Object)
], DemoMediaEditForm.prototype, "collection", void 0);
DemoMediaEditForm = __decorate([
    Service({ transient: true })
], DemoMediaEditForm);
export { DemoMediaEditForm };
