/**
 * We customize the Guardian mostly because we have different models of Users, we fetch different data from server than the default and we
 * register them in different ways.
 *
 * Use the `useAppGuardian()` function instead of `useGuardian()`
 */
import { GuardianSmart, use, UserLoggedInEvent, } from "@bluelibs/x-ui";
import { gql } from "@apollo/client";
export class AppGuardian extends GuardianSmart {
    async login(username, password) {
        this.updateState({
            hasInvalidToken: false,
        });
        await this.storeToken(null);
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation login($input: LoginInput!) {
            login(input: $input) {
              token
            }
          }
        `,
            variables: {
                input: {
                    username,
                    password,
                },
            },
        })
            .then(async (response) => {
            const { token } = response.data.login;
            await this.eventManager.emit(new UserLoggedInEvent({ token }));
            // We await this as storing the token might be blocking
            await this.storeToken(token);
            await this.load();
            return token;
        });
    }
    async retrieveUser() {
        return this.apolloClient
            .query({
            query: gql `
          query me {
            me {
              _id
              email
              profile {
                firstName
                lastName
              }
              fullName
              roles
            }
          }
        `,
            fetchPolicy: "network-only",
        })
            .then((response) => {
            return response.data.me;
        });
    }
}
/**
 * Use this instead `useGuardian()`
 * @returns
 */
export function useAppGuardian() {
    return use(AppGuardian);
}
