import { OrdersList } from "../components/List/OrdersList";
import { OrdersCreate } from "../components/Create/OrdersCreate";
import { OrdersEdit } from "../components/Edit/OrdersEdit";
import { OrdersView } from "../components/View/OrdersView";
import { SettingFilled } from "@ant-design/icons";
export const ORDERS_LIST = {
    path: "/admin/orders",
    component: OrdersList,
    menu: {
        key: "ORDERS_LIST",
        label: "management.orders.menu.title",
        icon: SettingFilled,
    },
};
export const ORDERS_CREATE = {
    path: "/admin/orders/create",
    component: OrdersCreate,
};
export const ORDERS_EDIT = {
    path: "/admin/orders/:id/edit",
    component: OrdersEdit,
};
export const ORDERS_VIEW = {
    path: "/admin/orders/:id/view",
    component: OrdersView,
};
