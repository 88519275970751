import { MerchantsBillingsList } from "../components/List/MerchantsBillingsList";
import { MerchantsBillingsCreate } from "../components/Create/MerchantsBillingsCreate";
import { MerchantsBillingsEdit } from "../components/Edit/MerchantsBillingsEdit";
import { MerchantsBillingsView } from "../components/View/MerchantsBillingsView";
import { SettingFilled } from "@ant-design/icons";
export const MERCHANTS_BILLINGS_LIST = {
    path: "/admin/merchants-billings",
    component: MerchantsBillingsList,
    menu: {
        key: "MERCHANTS_BILLINGS_LIST",
        label: "management.merchants_billings.menu.title",
        icon: SettingFilled,
    },
};
export const MERCHANTS_BILLINGS_CREATE = {
    path: "/admin/merchants-billings/create",
    component: MerchantsBillingsCreate,
};
export const MERCHANTS_BILLINGS_EDIT = {
    path: "/admin/merchants-billings/:id/edit",
    component: MerchantsBillingsEdit,
};
export const MERCHANTS_BILLINGS_VIEW = {
    path: "/admin/merchants-billings/:id/view",
    component: MerchantsBillingsView,
};
