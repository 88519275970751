import { WorldsList } from "../components/List/WorldsList";
import { WorldsCreate } from "../components/Create/WorldsCreate";
import { WorldsEdit } from "../components/Edit/WorldsEdit";
import { WorldsView } from "../components/View/WorldsView";
import { SettingFilled } from "@ant-design/icons";
export const WORLDS_LIST = {
    path: "/admin/worlds",
    component: WorldsList,
    menu: {
        key: "WORLDS_LIST",
        label: "management.worlds.menu.title",
        icon: SettingFilled,
    },
};
export const WORLDS_CREATE = {
    path: "/admin/worlds/create",
    component: WorldsCreate,
};
export const WORLDS_EDIT = {
    path: "/admin/worlds/:id/edit",
    component: WorldsEdit,
};
export const WORLDS_VIEW = {
    path: "/admin/worlds/:id/view",
    component: WorldsView,
};
