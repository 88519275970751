var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let NotificationListFiltersForm = class NotificationListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "type",
                label: t("management.notifications.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.notifications.fields.type"), children: [_jsx(Ant.Select.Option, { value: "ENQUIRY", children: "Enquiry" }, "ENQUIRY"), _jsx(Ant.Select.Option, { value: "NEW_MESSAGE", children: "New Message" }, "NEW_MESSAGE"), _jsx(Ant.Select.Option, { value: "SOMEONE_SPEAKS", children: "Someone Speaks" }, "SOMEONE_SPEAKS"), _jsx(Ant.Select.Option, { value: "SYSTEM_UPDATE", children: "System Update" }, "SYSTEM_UPDATE")] }) })),
            },
            {
                id: "message",
                label: t("management.notifications.fields.message"),
                name: ["message"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "isRead",
                label: t("management.notifications.fields.isRead"),
                name: ["isRead"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "createdAt",
                label: t("management.notifications.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.notifications.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.notifications.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.notifications.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "userId",
                label: t("management.notifications.fields.user"),
                name: ["userId"],
                tooltip: t("management.notifications.fields.user_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
NotificationListFiltersForm = __decorate([
    Service({ transient: true })
], NotificationListFiltersForm);
export { NotificationListFiltersForm };
