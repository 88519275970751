import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import * as React from "react";
import * as debounce from "lodash.debounce";
import { use, useTranslate } from "@bluelibs/x-ui";
import { SubscriptionFeaturePriceListFiltersForm } from "../../config/SubscriptionFeaturePriceListFiltersForm";
export const SubscriptionFeaturePricesListFilters = React.memo(SubscriptionFeaturePricesListFiltersBase);
export function SubscriptionFeaturePricesListFiltersBase(props) {
    const [form] = Ant.Form.useForm();
    const t = useTranslate();
    const debouncedFilterUpdates = React.useMemo(() => {
        const setFilters = (_, filters) => {
            props.onUpdate(filters);
        };
        return debounce(setFilters, 500);
    }, [props.onUpdate]);
    const filterForm = use(SubscriptionFeaturePriceListFiltersForm, {
        transient: true,
    });
    filterForm.build();
    return (_jsxs(Ant.Form, { form: form, labelCol: { span: 24 }, onValuesChange: debouncedFilterUpdates, children: [_jsx(Ant.Row, { gutter: [16, 8], children: filterForm.rest().map((item) => {
                    return (_jsx(Ant.Col, { span: 8, children: filterForm.render(item) }, item.id));
                }) }), _jsx(Ant.Form.Item, { children: _jsx(Ant.Button, { htmlType: "button", onClick: () => {
                        form.resetFields();
                        props.onUpdate({});
                    }, children: t("generics.list_filters_reset") }) })] }));
}
