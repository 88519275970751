import "./i18n";
import { WORLDS_LIST as BASE_WORLDS_LIST, WORLDS_CREATE as BASE_WORLDS_CREATE, WORLDS_EDIT as BASE_WORLDS_EDIT, WORLDS_VIEW as BASE_WORLDS_VIEW, } from "./config/routes";
export const WORLDS_LIST = {
    ...BASE_WORLDS_LIST,
};
export const WORLDS_CREATE = {
    ...BASE_WORLDS_CREATE,
};
export const WORLDS_EDIT = {
    ...BASE_WORLDS_EDIT,
};
export const WORLDS_VIEW = {
    ...BASE_WORLDS_VIEW,
};
