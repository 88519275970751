import "./i18n";
import { DEMO_PRODUCTS_LIST as BASE_DEMO_PRODUCTS_LIST, DEMO_PRODUCTS_CREATE as BASE_DEMO_PRODUCTS_CREATE, DEMO_PRODUCTS_EDIT as BASE_DEMO_PRODUCTS_EDIT, DEMO_PRODUCTS_VIEW as BASE_DEMO_PRODUCTS_VIEW, } from "./config/routes";
export const DEMO_PRODUCTS_LIST = {
    ...BASE_DEMO_PRODUCTS_LIST,
};
export const DEMO_PRODUCTS_CREATE = {
    ...BASE_DEMO_PRODUCTS_CREATE,
};
export const DEMO_PRODUCTS_EDIT = {
    ...BASE_DEMO_PRODUCTS_EDIT,
};
export const DEMO_PRODUCTS_VIEW = {
    ...BASE_DEMO_PRODUCTS_VIEW,
};
