import { SubscriptionFeaturePricesList } from "../components/List/SubscriptionFeaturePricesList";
import { SubscriptionFeaturePricesCreate } from "../components/Create/SubscriptionFeaturePricesCreate";
import { SubscriptionFeaturePricesEdit } from "../components/Edit/SubscriptionFeaturePricesEdit";
import { SubscriptionFeaturePricesView } from "../components/View/SubscriptionFeaturePricesView";
import { SettingFilled } from "@ant-design/icons";
export const SUBSCRIPTION_FEATURE_PRICES_LIST = {
    path: "/admin/subscription-feature-prices",
    component: SubscriptionFeaturePricesList,
    menu: {
        key: "SUBSCRIPTION_FEATURE_PRICES_LIST",
        label: "management.subscription_feature_prices.menu.title",
        icon: SettingFilled,
    },
};
export const SUBSCRIPTION_FEATURE_PRICES_CREATE = {
    path: "/admin/subscription-feature-prices/create",
    component: SubscriptionFeaturePricesCreate,
};
export const SUBSCRIPTION_FEATURE_PRICES_EDIT = {
    path: "/admin/subscription-feature-prices/:id/edit",
    component: SubscriptionFeaturePricesEdit,
};
export const SUBSCRIPTION_FEATURE_PRICES_VIEW = {
    path: "/admin/subscription-feature-prices/:id/view",
    component: SubscriptionFeaturePricesView,
};
