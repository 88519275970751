var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
let DemoUserViewer = class DemoUserViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.demo_users.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "username",
                label: t("management.demo_users.fields.username"),
                dataIndex: ["username"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "password",
                label: t("management.demo_users.fields.password"),
                dataIndex: ["password"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                label: t("management.demo_users.fields.email"),
                dataIndex: ["email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "firstName",
                label: t("management.demo_users.fields.firstName"),
                dataIndex: ["firstName"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "lastName",
                label: t("management.demo_users.fields.lastName"),
                dataIndex: ["lastName"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "roles",
                label: t("management.demo_users.fields.roles"),
                dataIndex: ["roles"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "enum",
                                    value,
                                    labelify: true,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "activeNotifications",
                label: t("management.demo_users.fields.activeNotifications"),
                dataIndex: ["activeNotifications"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "enum",
                                    value,
                                    labelify: true,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "preferredLanguage",
                label: t("management.demo_users.fields.preferredLanguage"),
                dataIndex: ["preferredLanguage"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                label: t("management.demo_users.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                label: t("management.demo_users.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            username: 1,
            password: 1,
            email: 1,
            firstName: 1,
            lastName: 1,
            roles: 1,
            activeNotifications: 1,
            preferredLanguage: 1,
            createdAt: 1,
            updatedAt: 1,
        };
    }
};
DemoUserViewer = __decorate([
    Service({ transient: true })
], DemoUserViewer);
export { DemoUserViewer };
