import { ProjectsCategoriesList } from "../components/List/ProjectsCategoriesList";
import { ProjectsCategoriesCreate } from "../components/Create/ProjectsCategoriesCreate";
import { ProjectsCategoriesEdit } from "../components/Edit/ProjectsCategoriesEdit";
import { ProjectsCategoriesView } from "../components/View/ProjectsCategoriesView";
import { SettingFilled } from "@ant-design/icons";
export const PROJECTS_CATEGORIES_LIST = {
    path: "/admin/projects-categories",
    component: ProjectsCategoriesList,
    menu: {
        key: "PROJECTS_CATEGORIES_LIST",
        label: "management.projects_categories.menu.title",
        icon: SettingFilled,
    },
};
export const PROJECTS_CATEGORIES_CREATE = {
    path: "/admin/projects-categories/create",
    component: ProjectsCategoriesCreate,
};
export const PROJECTS_CATEGORIES_EDIT = {
    path: "/admin/projects-categories/:id/edit",
    component: ProjectsCategoriesEdit,
};
export const PROJECTS_CATEGORIES_VIEW = {
    path: "/admin/projects-categories/:id/view",
    component: ProjectsCategoriesView,
};
