import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useRouter, useUIComponents, useTranslate, } from "@bluelibs/x-ui";
import { useState, useMemo } from "react";
import { NotificationsAntTableSmart } from "./NotificationsTableSmart";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { features } from "../../config/features";
import { NotificationsListFilters } from "./NotificationsListFilters";
export function NotificationsList() {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const [api, Provider] = newSmart(NotificationsAntTableSmart);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const onFiltersUpdate = useMemo(() => {
        return (filters) => {
            api.setFlexibleFilters(filters);
        };
    }, []);
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.notifications.list.header"), extra: [
                    features.create ? (_jsx(Ant.Button, { onClick: () => router.go(Routes.NOTIFICATIONS_CREATE), icon: _jsx(PlusOutlined, {}), children: t("management.notifications.list.create_btn") }, "1")) : null,
                    _jsx(Ant.Button, { onClick: () => setFiltersOpened(!filtersOpened), icon: _jsx(FilterOutlined, {}), children: t("generics.list_filters") }, "2"),
                ] }), api.state.isError && (_jsx(Ant.Alert, { type: "error", message: t("generics.error_message") })), _jsx(Ant.Layout.Content, { children: _jsx(Provider, { children: _jsxs("div", { className: "page-notifications-list", children: [filtersOpened && (_jsx(NotificationsListFilters, { onUpdate: onFiltersUpdate })), _jsx(Ant.Input.Search, { name: "Search", placeholder: t("generics.list_search"), className: "search", onKeyUp: (e) => {
                                    const value = e.target.value;
                                    api.setFilters({
                                        // Customise your search filters!
                                        title: new RegExp(`${value}`, "i"),
                                    });
                                } }), _jsx(Ant.Table, { ...api.getTableProps() })] }) }) })] }));
}
