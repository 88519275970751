import { EndUserInquiriesList } from "../components/List/EndUserInquiriesList";
import { EndUserInquiriesCreate } from "../components/Create/EndUserInquiriesCreate";
import { EndUserInquiriesEdit } from "../components/Edit/EndUserInquiriesEdit";
import { EndUserInquiriesView } from "../components/View/EndUserInquiriesView";
import { SettingFilled } from "@ant-design/icons";
export const END_USER_INQUIRIES_LIST = {
    path: "/admin/end-user-inquiries",
    component: EndUserInquiriesList,
    menu: {
        key: "END_USER_INQUIRIES_LIST",
        label: "management.end_user_inquiries.menu.title",
        icon: SettingFilled,
    },
};
export const END_USER_INQUIRIES_CREATE = {
    path: "/admin/end-user-inquiries/create",
    component: EndUserInquiriesCreate,
};
export const END_USER_INQUIRIES_EDIT = {
    path: "/admin/end-user-inquiries/:id/edit",
    component: EndUserInquiriesEdit,
};
export const END_USER_INQUIRIES_VIEW = {
    path: "/admin/end-user-inquiries/:id/view",
    component: EndUserInquiriesView,
};
