import "./i18n";
import { PRODUCT_CATEGORIES_LIST as BASE_PRODUCT_CATEGORIES_LIST, PRODUCT_CATEGORIES_CREATE as BASE_PRODUCT_CATEGORIES_CREATE, PRODUCT_CATEGORIES_EDIT as BASE_PRODUCT_CATEGORIES_EDIT, PRODUCT_CATEGORIES_VIEW as BASE_PRODUCT_CATEGORIES_VIEW, } from "./config/routes";
export const PRODUCT_CATEGORIES_LIST = {
    ...BASE_PRODUCT_CATEGORIES_LIST,
};
export const PRODUCT_CATEGORIES_CREATE = {
    ...BASE_PRODUCT_CATEGORIES_CREATE,
};
export const PRODUCT_CATEGORIES_EDIT = {
    ...BASE_PRODUCT_CATEGORIES_EDIT,
};
export const PRODUCT_CATEGORIES_VIEW = {
    ...BASE_PRODUCT_CATEGORIES_VIEW,
};
