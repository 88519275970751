import "./i18n";
import { WORLD_CATEGORIES_LIST as BASE_WORLD_CATEGORIES_LIST, WORLD_CATEGORIES_CREATE as BASE_WORLD_CATEGORIES_CREATE, WORLD_CATEGORIES_EDIT as BASE_WORLD_CATEGORIES_EDIT, WORLD_CATEGORIES_VIEW as BASE_WORLD_CATEGORIES_VIEW, } from "./config/routes";
export const WORLD_CATEGORIES_LIST = {
    ...BASE_WORLD_CATEGORIES_LIST,
};
export const WORLD_CATEGORIES_CREATE = {
    ...BASE_WORLD_CATEGORIES_CREATE,
};
export const WORLD_CATEGORIES_EDIT = {
    ...BASE_WORLD_CATEGORIES_EDIT,
};
export const WORLD_CATEGORIES_VIEW = {
    ...BASE_WORLD_CATEGORIES_VIEW,
};
