import "./i18n";
import { COMPANIES_LIST as BASE_COMPANIES_LIST, COMPANIES_CREATE as BASE_COMPANIES_CREATE, COMPANIES_EDIT as BASE_COMPANIES_EDIT, COMPANIES_VIEW as BASE_COMPANIES_VIEW, } from "./config/routes";
export const COMPANIES_LIST = {
    ...BASE_COMPANIES_LIST,
};
export const COMPANIES_CREATE = {
    ...BASE_COMPANIES_CREATE,
};
export const COMPANIES_EDIT = {
    ...BASE_COMPANIES_EDIT,
};
export const COMPANIES_VIEW = {
    ...BASE_COMPANIES_VIEW,
};
