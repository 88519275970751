import { AnalyticsList } from "../components/List/AnalyticsList";
import { AnalyticsCreate } from "../components/Create/AnalyticsCreate";
import { AnalyticsEdit } from "../components/Edit/AnalyticsEdit";
import { AnalyticsView } from "../components/View/AnalyticsView";
import { SettingFilled } from "@ant-design/icons";
export const ANALYTICS_LIST = {
    path: "/admin/analytics",
    component: AnalyticsList,
    menu: {
        key: "ANALYTICS_LIST",
        label: "management.analytics.menu.title",
        icon: SettingFilled,
    },
};
export const ANALYTICS_CREATE = {
    path: "/admin/analytics/create",
    component: AnalyticsCreate,
};
export const ANALYTICS_EDIT = {
    path: "/admin/analytics/:id/edit",
    component: AnalyticsEdit,
};
export const ANALYTICS_VIEW = {
    path: "/admin/analytics/:id/view",
    component: AnalyticsView,
};
