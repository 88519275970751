import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, use, useDataOne, useTranslate, } from "@bluelibs/x-ui";
import { PublicChatSessionEditForm } from "../../config/PublicChatSessionEditForm";
import { features } from "../../config/features";
import { PublicChatSessionsCollection, } from "@bundles/UIAppBundle/collections";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function PublicChatSessionsEdit(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(PublicChatSessionEditForm, { transient: true });
    const router = useRouter();
    const collection = use(PublicChatSessionsCollection);
    const { data: document, isLoading, error, } = useDataOne(PublicChatSessionsCollection, new ObjectId(props.id), PublicChatSessionEditForm.getRequestBody());
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.public_chat_sessions.edit.header"), onBack: () => window.history.back(), extra: features.view
                    ? [
                        _jsx(Link, { to: router.path(Routes.PUBLIC_CHAT_SESSIONS_VIEW, {
                                params: { id: props.id },
                            }), children: _jsx(Ant.Button, { children: t("generics.view") }) }, "view"),
                    ]
                    : [] }), _jsxs(Ant.Card, { children: [isLoading && (_jsx(Ant.Space, { align: "center", children: _jsx(Ant.Spin, { size: "large" }) })), !isLoading && (error || !document) && (_jsx(Ant.Alert, { message: error || t("generics.error_message"), type: "error" })), !isLoading && !error && (_jsxs(Ant.Form, { ...formLayout, requiredMark: "optional", initialValues: document, onFinish: (document) => form.onSubmit(props.id, document), children: [form.render(), _jsx(Ant.Form.Item, { ...formTailLayout, children: _jsx(Ant.Button, { type: "primary", htmlType: "submit", children: t("generics.submit") }) })] }))] })] }));
}
