var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let DemoProjectList = class DemoProjectList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                title: t("management.demo_projects.fields.name"),
                key: "management.demo_projects.fields.name",
                dataIndex: ["name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "onSuccessfulInquiryRedirectURL",
                title: t("management.demo_projects.fields.onSuccessfulInquiryRedirectURL"),
                key: "management.demo_projects.fields.onSuccessfulInquiryRedirectURL",
                dataIndex: ["onSuccessfulInquiryRedirectURL"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "linkName",
                title: t("management.demo_projects.fields.linkName"),
                key: "management.demo_projects.fields.linkName",
                dataIndex: ["linkName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "description.values",
                title: t("management.demo_projects.fields.description.values"),
                key: "management.demo_projects.fields.description.values",
                dataIndex: ["description", "values"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "description.text",
                title: t("management.demo_projects.fields.description.text"),
                key: "management.demo_projects.fields.description.text",
                dataIndex: ["description", "text"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "toDate",
                title: t("management.demo_projects.fields.toDate"),
                key: "management.demo_projects.fields.toDate",
                dataIndex: ["toDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "liveDate",
                title: t("management.demo_projects.fields.liveDate"),
                key: "management.demo_projects.fields.liveDate",
                dataIndex: ["liveDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "state",
                title: t("management.demo_projects.fields.state"),
                key: "management.demo_projects.fields.state",
                dataIndex: ["state"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "type",
                title: t("management.demo_projects.fields.type"),
                key: "management.demo_projects.fields.type",
                dataIndex: ["type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "watermarksEnabled",
                title: t("management.demo_projects.fields.watermarksEnabled"),
                key: "management.demo_projects.fields.watermarksEnabled",
                dataIndex: ["watermarksEnabled"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isAvailableToVideoAndAudioChat",
                title: t("management.demo_projects.fields.isAvailableToVideoAndAudioChat"),
                key: "management.demo_projects.fields.isAvailableToVideoAndAudioChat",
                dataIndex: ["isAvailableToVideoAndAudioChat"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isApprovedByAdmin",
                title: t("management.demo_projects.fields.isApprovedByAdmin"),
                key: "management.demo_projects.fields.isApprovedByAdmin",
                dataIndex: ["isApprovedByAdmin"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isSetup",
                title: t("management.demo_projects.fields.isSetup"),
                key: "management.demo_projects.fields.isSetup",
                dataIndex: ["isSetup"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "commonIdWorld",
                title: t("management.demo_projects.fields.commonIdWorld"),
                key: "management.demo_projects.fields.commonIdWorld",
                dataIndex: ["commonIdWorld"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "commonIdCategory",
                title: t("management.demo_projects.fields.commonIdCategory"),
                key: "management.demo_projects.fields.commonIdCategory",
                dataIndex: ["commonIdCategory"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlWatermark",
                title: t("management.demo_projects.fields.downloadUrlWatermark"),
                key: "management.demo_projects.fields.downloadUrlWatermark",
                dataIndex: ["downloadUrlWatermark"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "downloadUrlLogo",
                title: t("management.demo_projects.fields.downloadUrlLogo"),
                key: "management.demo_projects.fields.downloadUrlLogo",
                dataIndex: ["downloadUrlLogo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.demo_projects.fields.createdAt"),
                key: "management.demo_projects.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.demo_projects.fields.updatedAt"),
                key: "management.demo_projects.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "merchant",
                title: t("management.demo_projects.fields.merchant"),
                key: "management.demo_projects.fields.merchant",
                dataIndex: ["merchant"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.DEMO_MERCHANTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            merchant: "merchant._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            onSuccessfulInquiryRedirectURL: 1,
            linkName: 1,
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
                text: 1,
            },
            toDate: 1,
            liveDate: 1,
            state: 1,
            type: 1,
            watermarksEnabled: 1,
            isAvailableToVideoAndAudioChat: 1,
            isApprovedByAdmin: 1,
            isSetup: 1,
            commonIdWorld: 1,
            commonIdCategory: 1,
            downloadUrlWatermark: 1,
            downloadUrlLogo: 1,
            createdAt: 1,
            updatedAt: 1,
            merchant: {
                _id: 1,
            },
            merchantId: 1,
        };
    }
};
DemoProjectList = __decorate([
    Service({ transient: true })
], DemoProjectList);
export { DemoProjectList };
