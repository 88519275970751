var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { MediasCollection, ProductCategoriesCollection, MerchantsCollection, ProductsCollection, } from "@bundles/UIAppBundle/collections";
import { I18NFieldCustomRenderer } from "@bundles/UIAppBundle/components";
let ProductCreateForm = class ProductCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.products.fields.name"),
                name: ["name"],
                required: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "description",
                label: t("management.products.fields.description"),
                name: ["description"],
                required: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "price",
                label: t("management.products.fields.price"),
                name: ["price"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "vat",
                label: t("management.products.fields.vat"),
                name: ["vat"],
                component: Ant.InputNumber,
            },
            {
                id: "manufacturer",
                label: t("management.products.fields.manufacturer"),
                name: ["manufacturer"],
                component: Ant.Input,
            },
            {
                id: "href",
                label: t("management.products.fields.href"),
                name: ["href"],
                component: Ant.Input,
            },
            {
                id: "tags",
                label: t("management.products.fields.tags"),
                name: ["tags"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "isPhysical",
                label: t("management.products.fields.isPhysical"),
                name: ["isPhysical"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "inventory",
                label: t("management.products.fields.inventory"),
                name: ["inventory"],
                nest: [
                    {
                        id: "status",
                        label: t("management.products.fields.inventory.status"),
                        name: ["inventory", "status"],
                        render: (props) => {
                            const options = [
                                { value: "IN_STOCK", label: "In Stock" },
                                { value: "OUT_OF_STOCK", label: "Out Of Stock" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.products.fields.inventory.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.products.fields.inventory.status_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.products.fields.inventory.status_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "stockKeepingUnit",
                        label: t("management.products.fields.inventory.stockKeepingUnit"),
                        name: ["inventory", "stockKeepingUnit"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "barcode",
                        label: t("management.products.fields.inventory.barcode"),
                        name: ["inventory", "barcode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "options",
                label: t("management.products.fields.options"),
                name: ["options"],
                nest: [
                    {
                        id: "sizes",
                        label: t("management.products.fields.options.sizes"),
                        name: ["options", "sizes"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "colours",
                        label: t("management.products.fields.options.colours"),
                        name: ["options", "colours"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "materials",
                        label: t("management.products.fields.options.materials"),
                        name: ["options", "materials"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                ],
            },
            {
                id: "shipping",
                label: t("management.products.fields.shipping"),
                name: ["shipping"],
                nest: [
                    {
                        id: "weight",
                        label: t("management.products.fields.shipping.weight"),
                        name: ["shipping", "weight"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "originCountryOrRegion",
                        label: t("management.products.fields.shipping.originCountryOrRegion"),
                        name: ["shipping", "originCountryOrRegion"],
                        component: Ant.Input,
                    },
                    {
                        id: "harmonisedSystemCode",
                        label: t("management.products.fields.shipping.harmonisedSystemCode"),
                        name: ["shipping", "harmonisedSystemCode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "seo",
                label: t("management.products.fields.seo"),
                name: ["seo"],
                nest: [
                    {
                        id: "title",
                        label: t("management.products.fields.seo.title"),
                        name: ["seo", "title"],
                        component: Ant.Input,
                    },
                    {
                        id: "description",
                        label: t("management.products.fields.seo.description"),
                        name: ["seo", "description"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "mediaId",
                label: t("management.products.fields.media"),
                name: ["mediaId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MediasCollection, field: "name", required: true }) })),
            },
            {
                id: "categoryId",
                label: t("management.products.fields.category"),
                name: ["categoryId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", required: false }) })),
            },
            {
                id: "merchantId",
                label: t("management.products.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.products.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.PRODUCTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.PRODUCTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.PRODUCTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ProductsCollection),
    __metadata("design:type", typeof (_a = typeof ProductsCollection !== "undefined" && ProductsCollection) === "function" ? _a : Object)
], ProductCreateForm.prototype, "collection", void 0);
ProductCreateForm = __decorate([
    Service({ transient: true })
], ProductCreateForm);
export { ProductCreateForm };
