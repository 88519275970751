import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUIComponents, use, useTranslate } from "@bluelibs/x-ui";
import * as Ant from "antd";
import { DemoProductCreateForm } from "../../config/DemoProductCreateForm";
const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export function DemoProductsCreate() {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const form = use(DemoProductCreateForm, { transient: true });
    form.build();
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.demo_products.create.header"), onBack: () => window.history.back() }), _jsx(Ant.Card, { children: _jsxs(Ant.Form, { ...formLayout, requiredMark: "optional", onFinish: (document) => form.onSubmit(document), children: [form.render(), _jsx(Ant.Form.Item, { ...formTailLayout, children: _jsx(Ant.Button, { type: "primary", htmlType: "submit", children: t("generics.submit") }) })] }) })] }));
}
