var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { DemoMerchantsCollection, } from "@bundles/UIAppBundle/collections";
let DemoProjectListFiltersForm = class DemoProjectListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.demo_projects.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "onSuccessfulInquiryRedirectURL",
                label: t("management.demo_projects.fields.onSuccessfulInquiryRedirectURL"),
                name: ["onSuccessfulInquiryRedirectURL"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "linkName",
                label: t("management.demo_projects.fields.linkName"),
                name: ["linkName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "description",
                label: t("management.demo_projects.fields.description"),
                name: ["description"],
                columns: true,
                nest: [
                    {
                        id: "values",
                        label: t("management.demo_projects.fields.description.values"),
                        name: ["description", "values"],
                        required: true,
                        isList: true,
                        nest: [],
                    },
                    {
                        id: "text",
                        label: t("management.demo_projects.fields.description.text"),
                        name: ["description", "text"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "toDate",
                label: t("management.demo_projects.fields.toDate"),
                name: ["toDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "liveDate",
                label: t("management.demo_projects.fields.liveDate"),
                name: ["liveDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "state",
                label: t("management.demo_projects.fields.state"),
                name: ["state"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.demo_projects.fields.state"), children: [_jsx(Ant.Select.Option, { value: "PUBLISHED", children: "Published" }, "PUBLISHED"), _jsx(Ant.Select.Option, { value: "UNPUBLISHED", children: "Unpublished" }, "UNPUBLISHED")] }) })),
            },
            {
                id: "type",
                label: t("management.demo_projects.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.demo_projects.fields.type"), children: [_jsx(Ant.Select.Option, { value: "GALLERY", children: "Exquisite Gallery" }, "GALLERY"), _jsx(Ant.Select.Option, { value: "SHOP", children: "Shop" }, "SHOP"), _jsx(Ant.Select.Option, { value: "EXHIBITION", children: "Exhibition" }, "EXHIBITION"), _jsx(Ant.Select.Option, { value: "HALL", children: "Hall" }, "HALL")] }) })),
            },
            {
                id: "watermarksEnabled",
                label: t("management.demo_projects.fields.watermarksEnabled"),
                name: ["watermarksEnabled"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAvailableToVideoAndAudioChat",
                label: t("management.demo_projects.fields.isAvailableToVideoAndAudioChat"),
                name: ["isAvailableToVideoAndAudioChat"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isApprovedByAdmin",
                label: t("management.demo_projects.fields.isApprovedByAdmin"),
                name: ["isApprovedByAdmin"],
                tooltip: t("management.demo_projects.fields.isApprovedByAdmin_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isSetup",
                label: t("management.demo_projects.fields.isSetup"),
                name: ["isSetup"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "commonIdWorld",
                label: t("management.demo_projects.fields.commonIdWorld"),
                name: ["commonIdWorld"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "commonIdCategory",
                label: t("management.demo_projects.fields.commonIdCategory"),
                name: ["commonIdCategory"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlWatermark",
                label: t("management.demo_projects.fields.downloadUrlWatermark"),
                name: ["downloadUrlWatermark"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlLogo",
                label: t("management.demo_projects.fields.downloadUrlLogo"),
                name: ["downloadUrlLogo"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.demo_projects.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.demo_projects.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.demo_projects.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.demo_projects.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "merchantId",
                label: t("management.demo_projects.fields.merchant"),
                name: ["merchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: DemoMerchantsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
DemoProjectListFiltersForm = __decorate([
    Service({ transient: true })
], DemoProjectListFiltersForm);
export { DemoProjectListFiltersForm };
