var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { MediasCollection, } from "@bundles/UIAppBundle/collections";
let MediaEditForm = class MediaEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.medias.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "videoUrl",
                label: t("management.medias.fields.videoUrl"),
                name: ["videoUrl"],
                component: Ant.Input,
            },
            {
                id: "image360FilesIds",
                label: t("management.medias.fields.image360Files"),
                name: ["image360FilesIds"],
                component: UIComponents.AdminFilesUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "image2dFilesIds",
                label: t("management.medias.fields.image2dFiles"),
                name: ["image2dFilesIds"],
                component: UIComponents.AdminFilesUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "image3dOBJId",
                label: t("management.medias.fields.image3dOBJ"),
                name: ["image3dOBJId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "image3dMTLId",
                label: t("management.medias.fields.image3dMTL"),
                name: ["image3dMTLId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "image3DGLBId",
                label: t("management.medias.fields.image3DGLB"),
                name: ["image3DGLBId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "videoUploadId",
                label: t("management.medias.fields.videoUpload"),
                name: ["videoUploadId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            videoUrl: 1,
            image360Files: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image360FilesIds: 1,
            image2dFiles: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image2dFilesIds: 1,
            image3dOBJ: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image3dOBJId: 1,
            image3dMTL: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image3dMTLId: 1,
            image3DGLB: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            image3DGLBId: 1,
            videoUpload: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            videoUploadId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.medias.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => MediasCollection),
    __metadata("design:type", typeof (_a = typeof MediasCollection !== "undefined" && MediasCollection) === "function" ? _a : Object)
], MediaEditForm.prototype, "collection", void 0);
MediaEditForm = __decorate([
    Service({ transient: true })
], MediaEditForm);
export { MediaEditForm };
