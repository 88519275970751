import { ProductCategoriesList } from "../components/List/ProductCategoriesList";
import { ProductCategoriesCreate } from "../components/Create/ProductCategoriesCreate";
import { ProductCategoriesEdit } from "../components/Edit/ProductCategoriesEdit";
import { ProductCategoriesView } from "../components/View/ProductCategoriesView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCT_CATEGORIES_LIST = {
    path: "/admin/product-categories",
    component: ProductCategoriesList,
    menu: {
        key: "PRODUCT_CATEGORIES_LIST",
        label: "management.product_categories.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCT_CATEGORIES_CREATE = {
    path: "/admin/product-categories/create",
    component: ProductCategoriesCreate,
};
export const PRODUCT_CATEGORIES_EDIT = {
    path: "/admin/product-categories/:id/edit",
    component: ProductCategoriesEdit,
};
export const PRODUCT_CATEGORIES_VIEW = {
    path: "/admin/product-categories/:id/view",
    component: ProductCategoriesView,
};
