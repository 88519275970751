var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ProjectsCollection, MerchantsCollection, } from "@bundles/UIAppBundle/collections";
let MerchantsBillingListFiltersForm = class MerchantsBillingListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "renewalDate",
                label: t("management.merchants_billings.fields.renewalDate"),
                name: ["renewalDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "packageType",
                label: t("management.merchants_billings.fields.packageType"),
                name: ["packageType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.packageType"), children: [_jsx(Ant.Select.Option, { value: "BRONZE", children: "Bronze" }, "BRONZE"), _jsx(Ant.Select.Option, { value: "SILVER", children: "Silver" }, "SILVER"), _jsx(Ant.Select.Option, { value: "GOLD", children: "Gold" }, "GOLD"), _jsx(Ant.Select.Option, { value: "TITAN", children: "Titan" }, "TITAN")] }) })),
            },
            {
                id: "periodType",
                label: t("management.merchants_billings.fields.periodType"),
                name: ["periodType"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.periodType"), children: [_jsx(Ant.Select.Option, { value: "MONTHLY", children: "Monthly" }, "MONTHLY"), _jsx(Ant.Select.Option, { value: "YEARLY", children: "Yearly" }, "YEARLY")] }) })),
            },
            {
                id: "stripeSubscriptionId",
                label: t("management.merchants_billings.fields.stripeSubscriptionId"),
                name: ["stripeSubscriptionId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "stripeSubscriptionSchedule",
                label: t("management.merchants_billings.fields.stripeSubscriptionSchedule"),
                name: ["stripeSubscriptionSchedule"],
                columns: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.id"),
                        name: ["stripeSubscriptionSchedule", "id"],
                        component: Ant.Input,
                    },
                    {
                        id: "nextPhaseDate",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.nextPhaseDate"),
                        name: ["stripeSubscriptionSchedule", "nextPhaseDate"],
                        render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
                    },
                    {
                        id: "packageType",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.packageType"),
                        name: ["stripeSubscriptionSchedule", "packageType"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "BRONZE", label: "Bronze" },
                                { value: "SILVER", label: "Silver" },
                                { value: "GOLD", label: "Gold" },
                                { value: "TITAN", label: "Titan" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billings.fields.stripeSubscriptionSchedule.packageType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.stripeSubscriptionSchedule.packageType_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billings.fields.stripeSubscriptionSchedule.packageType_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "periodType",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.periodType"),
                        name: ["stripeSubscriptionSchedule", "periodType"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "MONTHLY", label: "Monthly" },
                                { value: "YEARLY", label: "Yearly" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billings.fields.stripeSubscriptionSchedule.periodType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.stripeSubscriptionSchedule.periodType_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billings.fields.stripeSubscriptionSchedule.periodType_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "features",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.features"),
                        name: ["stripeSubscriptionSchedule", "features"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "ADD_360_IMAGE", label: "Add 360 Image" },
                                { value: "ADD_3D_OBJECT", label: "Add 3 D Object" },
                                { value: "ADD_10_3D_OBJECTS", label: "Add 10 3 D Objects" },
                                { value: "ADD_VIDEO", label: "Add Video" },
                                { value: "API_CALL_ACCESS", label: "Api Call Access" },
                                { value: "LOAD_1_PHOTO", label: "Load 1 Photo" },
                                { value: "LOAD_10_PHOTOS", label: "Load 10 Photos" },
                                {
                                    value: "WISHLIST_FOR_CLIENTS",
                                    label: "Wishlist For Clients",
                                },
                                {
                                    value: "CLIENTS_CAN_GET_CONTACTS",
                                    label: "Clients Can Get Contacts",
                                },
                                { value: "TEXTCHAT", label: "Textchat" },
                                { value: "VIDEOCHAT", label: "Videochat" },
                                { value: "VOICECHAT", label: "Voicechat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.stripeSubscriptionSchedule.features"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.stripeSubscriptionSchedule.features_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billings.fields.stripeSubscriptionSchedule.features_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                ],
            },
            {
                id: "expiryDate",
                label: t("management.merchants_billings.fields.expiryDate"),
                name: ["expiryDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "status",
                label: t("management.merchants_billings.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.status"), children: [_jsx(Ant.Select.Option, { value: "TRIAL", children: "Trial" }, "TRIAL"), _jsx(Ant.Select.Option, { value: "PAYMENT_FAILED", children: "Payment Failed" }, "PAYMENT_FAILED"), _jsx(Ant.Select.Option, { value: "CANCELED", children: "Canceled" }, "CANCELED"), _jsx(Ant.Select.Option, { value: "ACTIVE", children: "Active" }, "ACTIVE")] }) })),
            },
            {
                id: "failedPaymentReasonCode",
                label: t("management.merchants_billings.fields.failedPaymentReasonCode"),
                name: ["failedPaymentReasonCode"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "features",
                label: t("management.merchants_billings.fields.features"),
                name: ["features"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.features"), children: [_jsx(Ant.Select.Option, { value: "ADD_360_IMAGE", children: "Add 360 Image" }, "ADD_360_IMAGE"), _jsx(Ant.Select.Option, { value: "ADD_3D_OBJECT", children: "Add 3 D Object" }, "ADD_3D_OBJECT"), _jsx(Ant.Select.Option, { value: "ADD_10_3D_OBJECTS", children: "Add 10 3 D Objects" }, "ADD_10_3D_OBJECTS"), _jsx(Ant.Select.Option, { value: "ADD_VIDEO", children: "Add Video" }, "ADD_VIDEO"), _jsx(Ant.Select.Option, { value: "API_CALL_ACCESS", children: "Api Call Access" }, "API_CALL_ACCESS"), _jsx(Ant.Select.Option, { value: "LOAD_1_PHOTO", children: "Load 1 Photo" }, "LOAD_1_PHOTO"), _jsx(Ant.Select.Option, { value: "LOAD_10_PHOTOS", children: "Load 10 Photos" }, "LOAD_10_PHOTOS"), _jsx(Ant.Select.Option, { value: "WISHLIST_FOR_CLIENTS", children: "Wishlist For Clients" }, "WISHLIST_FOR_CLIENTS"), _jsx(Ant.Select.Option, { value: "CLIENTS_CAN_GET_CONTACTS", children: "Clients Can Get Contacts" }, "CLIENTS_CAN_GET_CONTACTS"), _jsx(Ant.Select.Option, { value: "TEXTCHAT", children: "Textchat" }, "TEXTCHAT"), _jsx(Ant.Select.Option, { value: "VIDEOCHAT", children: "Videochat" }, "VIDEOCHAT"), _jsx(Ant.Select.Option, { value: "VOICECHAT", children: "Voicechat" }, "VOICECHAT")] }) })),
            },
            {
                id: "projectId",
                label: t("management.merchants_billings.fields.project"),
                name: ["projectId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "merchantId",
                label: t("management.merchants_billings.fields.merchant"),
                name: ["merchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
MerchantsBillingListFiltersForm = __decorate([
    Service({ transient: true })
], MerchantsBillingListFiltersForm);
export { MerchantsBillingListFiltersForm };
