var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { MerchantsCollection, WorldsCollection, } from "@bundles/UIAppBundle/collections";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
import { RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
let WorldReviewListFiltersForm = class WorldReviewListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "rating",
                label: t("management.world_reviews.fields.rating"),
                name: ["rating"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "comment",
                label: t("management.world_reviews.fields.comment"),
                name: ["comment"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.world_reviews.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.world_reviews.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.world_reviews.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.world_reviews.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "merchantId",
                label: t("management.world_reviews.fields.merchant"),
                name: ["merchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "worldId",
                label: t("management.world_reviews.fields.world"),
                name: ["worldId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", otherQuery: nameQuery, placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
WorldReviewListFiltersForm = __decorate([
    Service({ transient: true })
], WorldReviewListFiltersForm);
export { WorldReviewListFiltersForm };
