import { WishlistItemsList } from "../components/List/WishlistItemsList";
import { WishlistItemsView } from "../components/View/WishlistItemsView";
import { SettingFilled } from "@ant-design/icons";
export const WISHLIST_ITEMS_LIST = {
    path: "/admin/wishlist-items",
    component: WishlistItemsList,
    menu: {
        key: "WISHLIST_ITEMS_LIST",
        label: "management.wishlist_items.menu.title",
        icon: SettingFilled,
    },
};
export const WISHLIST_ITEMS_CREATE = {
    path: "/admin/wishlist-items/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const WISHLIST_ITEMS_EDIT = {
    path: "/admin/wishlist-items/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const WISHLIST_ITEMS_VIEW = {
    path: "/admin/wishlist-items/:id/view",
    component: WishlistItemsView,
};
