var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { DemoUsersCollection, } from "@bundles/UIAppBundle/collections";
let DemoMerchantListFiltersForm = class DemoMerchantListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.demo_merchants.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "lastName",
                label: t("management.demo_merchants.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "email",
                label: t("management.demo_merchants.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "phoneNumber",
                label: t("management.demo_merchants.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "consentForEmails",
                label: t("management.demo_merchants.fields.consentForEmails"),
                name: ["consentForEmails"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAvailableToChat",
                label: t("management.demo_merchants.fields.isAvailableToChat"),
                name: ["isAvailableToChat"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "billingAddress",
                label: t("management.demo_merchants.fields.billingAddress"),
                name: ["billingAddress"],
                columns: true,
                nest: [
                    {
                        id: "line1",
                        label: t("management.demo_merchants.fields.billingAddress.line1"),
                        name: ["billingAddress", "line1"],
                        component: Ant.Input,
                    },
                    {
                        id: "line2",
                        label: t("management.demo_merchants.fields.billingAddress.line2"),
                        name: ["billingAddress", "line2"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.demo_merchants.fields.billingAddress.zip"),
                        name: ["billingAddress", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.demo_merchants.fields.billingAddress.city"),
                        name: ["billingAddress", "city"],
                        component: Ant.Input,
                    },
                    {
                        id: "county",
                        label: t("management.demo_merchants.fields.billingAddress.county"),
                        name: ["billingAddress", "county"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.demo_merchants.fields.billingAddress.country"),
                        name: ["billingAddress", "country"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "stripeCustomerId",
                label: t("management.demo_merchants.fields.stripeCustomerId"),
                name: ["stripeCustomerId"],
                tooltip: t("management.demo_merchants.fields.stripeCustomerId_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "status",
                label: t("management.demo_merchants.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.demo_merchants.fields.status"), children: [_jsx(Ant.Select.Option, { value: "ACTIVE", children: "Active" }, "ACTIVE"), _jsx(Ant.Select.Option, { value: "INACTIVE", children: "Inactive" }, "INACTIVE")] }) })),
            },
            {
                id: "VAT",
                label: t("management.demo_merchants.fields.VAT"),
                name: ["VAT"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "OAuth",
                label: t("management.demo_merchants.fields.OAuth"),
                name: ["OAuth"],
                columns: true,
                nest: [
                    {
                        id: "secretKey",
                        label: t("management.demo_merchants.fields.OAuth.secretKey"),
                        name: ["OAuth", "secretKey"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "activationDate",
                        label: t("management.demo_merchants.fields.OAuth.activationDate"),
                        name: ["OAuth", "activationDate"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: true }) })),
                    },
                    {
                        id: "regenerationDate",
                        label: t("management.demo_merchants.fields.OAuth.regenerationDate"),
                        name: ["OAuth", "regenerationDate"],
                        render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
                    },
                ],
            },
            {
                id: "downloadUrlAvatar",
                label: t("management.demo_merchants.fields.downloadUrlAvatar"),
                name: ["downloadUrlAvatar"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "commonIdFavouriteWorlds",
                label: t("management.demo_merchants.fields.commonIdFavouriteWorlds"),
                name: ["commonIdFavouriteWorlds"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.demo_merchants.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.demo_merchants.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.demo_merchants.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.demo_merchants.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "ownerId",
                label: t("management.demo_merchants.fields.owner"),
                name: ["ownerId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: DemoUsersCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
DemoMerchantListFiltersForm = __decorate([
    Service({ transient: true })
], DemoMerchantListFiltersForm);
export { DemoMerchantListFiltersForm };
