import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable */
import * as React from "react";
import { useData } from "@bluelibs/x-ui";
import { Alert, Select, Spin } from "antd";
import { ObjectId } from "@bluelibs/ejson";
import * as _ from "lodash";
export function RemoteSelectCustomRenderer(props) {
    let { field, collectionClass, idAsString, onChange, value, required, otherQuery, ...rest } = props;
    if (value) {
        if (Array.isArray(value)) {
            value = value.map((v) => v.toString());
        }
        else {
            value = value.toString();
        }
    }
    const { data, isLoading, error } = useData(props.collectionClass, {}, _.set({ _id: 1, ...otherQuery }, field.split("."), 1));
    if (isLoading) {
        return _jsx(Spin, {});
    }
    if (error) {
        return _jsx(Alert, { message: "Error loading data", type: "error" });
    }
    const options = data.map((d) => (_jsx(Select.Option, { value: d._id.toString(), children: _.get(d, field.split("."), "N/A").toString() }, d._id.toString())));
    return (_jsx(Select, { value: value, allowClear: !required, onClear: () => {
            const isArray = Array.isArray(value);
            onChange(isArray ? [] : null, {
                label: undefined,
                value: undefined,
            });
        }, onChange: (value, option) => {
            if (Array.isArray(value)) {
                onChange &&
                    onChange(!idAsString && value ? value.map((v) => new ObjectId(v)) : value, option);
            }
            else {
                onChange &&
                    onChange(!idAsString && value ? new ObjectId(value) : value, option);
            }
        }, ...rest, children: options }));
}
