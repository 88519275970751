var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ProjectAssetInfoList = class ProjectAssetInfoList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isEnabled",
                title: t("management.project_asset_infos.fields.isEnabled"),
                key: "management.project_asset_infos.fields.isEnabled",
                dataIndex: ["isEnabled"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "mediaType",
                title: t("management.project_asset_infos.fields.mediaType"),
                key: "management.project_asset_infos.fields.mediaType",
                dataIndex: ["mediaType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "typeFor3D",
                title: t("management.project_asset_infos.fields.typeFor3D"),
                key: "management.project_asset_infos.fields.typeFor3D",
                dataIndex: ["typeFor3D"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "typeForVideo",
                title: t("management.project_asset_infos.fields.typeForVideo"),
                key: "management.project_asset_infos.fields.typeForVideo",
                dataIndex: ["typeForVideo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "action.type",
                title: t("management.project_asset_infos.fields.action.type"),
                key: "management.project_asset_infos.fields.action.type",
                dataIndex: ["action", "type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "action.projectId",
                title: t("management.project_asset_infos.fields.action.projectId"),
                key: "management.project_asset_infos.fields.action.projectId",
                dataIndex: ["action", "projectId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "deltaCoordinates.position",
                title: t("management.project_asset_infos.fields.deltaCoordinates.position"),
                key: "management.project_asset_infos.fields.deltaCoordinates.position",
                dataIndex: ["deltaCoordinates", "position"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "deltaCoordinates.rotation",
                title: t("management.project_asset_infos.fields.deltaCoordinates.rotation"),
                key: "management.project_asset_infos.fields.deltaCoordinates.rotation",
                dataIndex: ["deltaCoordinates", "rotation"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "deltaCoordinates.scale",
                title: t("management.project_asset_infos.fields.deltaCoordinates.scale"),
                key: "management.project_asset_infos.fields.deltaCoordinates.scale",
                dataIndex: ["deltaCoordinates", "scale"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "project",
                title: t("management.project_asset_infos.fields.project"),
                key: "management.project_asset_infos.fields.project",
                dataIndex: ["project"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROJECTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "product",
                title: t("management.project_asset_infos.fields.product"),
                key: "management.project_asset_infos.fields.product",
                dataIndex: ["product"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PRODUCTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "translatedName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "assetPosition",
                title: t("management.project_asset_infos.fields.assetPosition"),
                key: "management.project_asset_infos.fields.assetPosition",
                dataIndex: ["assetPosition"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.WORLDS_ASSET_POSITIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            project: "project.name",
            product: "product.translatedName",
            assetPosition: "assetPosition._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            isEnabled: 1,
            mediaType: 1,
            typeFor3D: 1,
            typeForVideo: 1,
            action: {
                type: 1,
                projectId: 1,
            },
            deltaCoordinates: {
                position: 1,
                rotation: 1,
                scale: 1,
            },
            project: {
                _id: 1,
                name: 1,
            },
            projectId: 1,
            product: {
                _id: 1,
                translatedName: 1,
            },
            productId: 1,
            assetPosition: {
                _id: 1,
            },
            assetPositionId: 1,
        };
    }
};
ProjectAssetInfoList = __decorate([
    Service({ transient: true })
], ProjectAssetInfoList);
export { ProjectAssetInfoList };
