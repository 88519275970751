import { DemoProjectsList } from "../components/List/DemoProjectsList";
import { DemoProjectsCreate } from "../components/Create/DemoProjectsCreate";
import { DemoProjectsEdit } from "../components/Edit/DemoProjectsEdit";
import { DemoProjectsView } from "../components/View/DemoProjectsView";
import { SettingFilled } from "@ant-design/icons";
export const DEMO_PROJECTS_LIST = {
    path: "/admin/demo-projects",
    component: DemoProjectsList,
    menu: {
        key: "DEMO_PROJECTS_LIST",
        label: "management.demo_projects.menu.title",
        icon: SettingFilled,
    },
};
export const DEMO_PROJECTS_CREATE = {
    path: "/admin/demo-projects/create",
    component: DemoProjectsCreate,
};
export const DEMO_PROJECTS_EDIT = {
    path: "/admin/demo-projects/:id/edit",
    component: DemoProjectsEdit,
};
export const DEMO_PROJECTS_VIEW = {
    path: "/admin/demo-projects/:id/view",
    component: DemoProjectsView,
};
