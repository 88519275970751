import "./i18n";
import { ORDER_ITEMS_LIST as BASE_ORDER_ITEMS_LIST, ORDER_ITEMS_CREATE as BASE_ORDER_ITEMS_CREATE, ORDER_ITEMS_EDIT as BASE_ORDER_ITEMS_EDIT, ORDER_ITEMS_VIEW as BASE_ORDER_ITEMS_VIEW, } from "./config/routes";
export const ORDER_ITEMS_LIST = {
    ...BASE_ORDER_ITEMS_LIST,
};
export const ORDER_ITEMS_CREATE = {
    ...BASE_ORDER_ITEMS_CREATE,
};
export const ORDER_ITEMS_EDIT = {
    ...BASE_ORDER_ITEMS_EDIT,
};
export const ORDER_ITEMS_VIEW = {
    ...BASE_ORDER_ITEMS_VIEW,
};
