import "./i18n";
import { VR_AVATARS_LIST as BASE_VR_AVATARS_LIST, VR_AVATARS_CREATE as BASE_VR_AVATARS_CREATE, VR_AVATARS_EDIT as BASE_VR_AVATARS_EDIT, VR_AVATARS_VIEW as BASE_VR_AVATARS_VIEW, } from "./config/routes";
export const VR_AVATARS_LIST = {
    ...BASE_VR_AVATARS_LIST,
};
export const VR_AVATARS_CREATE = {
    ...BASE_VR_AVATARS_CREATE,
};
export const VR_AVATARS_EDIT = {
    ...BASE_VR_AVATARS_EDIT,
};
export const VR_AVATARS_VIEW = {
    ...BASE_VR_AVATARS_VIEW,
};
