var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { MerchantsCollection, WorldsCollection, WorldReviewsCollection, } from "@bundles/UIAppBundle/collections";
import { nameQuery } from "@bundles/UIAppBundle/queries/nameQuery";
import { RemoteSelectCustomRenderer } from "@bundles/UIAppBundle/components";
let WorldReviewEditForm = class WorldReviewEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "rating",
                label: t("management.world_reviews.fields.rating"),
                name: ["rating"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "comment",
                label: t("management.world_reviews.fields.comment"),
                name: ["comment"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "merchantId",
                label: t("management.world_reviews.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
            {
                id: "worldId",
                label: t("management.world_reviews.fields.world"),
                name: ["worldId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(RemoteSelectCustomRenderer, { collectionClass: WorldsCollection, field: "name.text", otherQuery: nameQuery, required: true }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            rating: 1,
            comment: 1,
            merchant: {
                _id: 1,
                fullName: 1,
            },
            merchantId: 1,
            world: {
                _id: 1,
                name: {
                    text: 1,
                    values: {
                        lang: 1,
                        text: 1,
                    },
                },
            },
            worldId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.world_reviews.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => WorldReviewsCollection),
    __metadata("design:type", typeof (_a = typeof WorldReviewsCollection !== "undefined" && WorldReviewsCollection) === "function" ? _a : Object)
], WorldReviewEditForm.prototype, "collection", void 0);
WorldReviewEditForm = __decorate([
    Service({ transient: true })
], WorldReviewEditForm);
export { WorldReviewEditForm };
