import "./i18n";
import { MERCHANTS_BILLINGS_LIST as BASE_MERCHANTS_BILLINGS_LIST, MERCHANTS_BILLINGS_CREATE as BASE_MERCHANTS_BILLINGS_CREATE, MERCHANTS_BILLINGS_EDIT as BASE_MERCHANTS_BILLINGS_EDIT, MERCHANTS_BILLINGS_VIEW as BASE_MERCHANTS_BILLINGS_VIEW, } from "./config/routes";
export const MERCHANTS_BILLINGS_LIST = {
    ...BASE_MERCHANTS_BILLINGS_LIST,
};
export const MERCHANTS_BILLINGS_CREATE = {
    ...BASE_MERCHANTS_BILLINGS_CREATE,
};
export const MERCHANTS_BILLINGS_EDIT = {
    ...BASE_MERCHANTS_BILLINGS_EDIT,
};
export const MERCHANTS_BILLINGS_VIEW = {
    ...BASE_MERCHANTS_BILLINGS_VIEW,
};
