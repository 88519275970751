import { BasketItemsList } from "../components/List/BasketItemsList";
import { BasketItemsCreate } from "../components/Create/BasketItemsCreate";
import { BasketItemsEdit } from "../components/Edit/BasketItemsEdit";
import { BasketItemsView } from "../components/View/BasketItemsView";
import { SettingFilled } from "@ant-design/icons";
export const BASKET_ITEMS_LIST = {
    path: "/admin/basket-items",
    component: BasketItemsList,
    menu: {
        key: "BASKET_ITEMS_LIST",
        label: "management.basket_items.menu.title",
        icon: SettingFilled,
    },
};
export const BASKET_ITEMS_CREATE = {
    path: "/admin/basket-items/create",
    component: BasketItemsCreate,
};
export const BASKET_ITEMS_EDIT = {
    path: "/admin/basket-items/:id/edit",
    component: BasketItemsEdit,
};
export const BASKET_ITEMS_VIEW = {
    path: "/admin/basket-items/:id/view",
    component: BasketItemsView,
};
