import "./i18n";
import { WORLD_REVIEWS_LIST as BASE_WORLD_REVIEWS_LIST, WORLD_REVIEWS_CREATE as BASE_WORLD_REVIEWS_CREATE, WORLD_REVIEWS_EDIT as BASE_WORLD_REVIEWS_EDIT, WORLD_REVIEWS_VIEW as BASE_WORLD_REVIEWS_VIEW, } from "./config/routes";
export const WORLD_REVIEWS_LIST = {
    ...BASE_WORLD_REVIEWS_LIST,
};
export const WORLD_REVIEWS_CREATE = {
    ...BASE_WORLD_REVIEWS_CREATE,
};
export const WORLD_REVIEWS_EDIT = {
    ...BASE_WORLD_REVIEWS_EDIT,
};
export const WORLD_REVIEWS_VIEW = {
    ...BASE_WORLD_REVIEWS_VIEW,
};
