import { WorldCategoriesList } from "../components/List/WorldCategoriesList";
import { WorldCategoriesCreate } from "../components/Create/WorldCategoriesCreate";
import { WorldCategoriesEdit } from "../components/Edit/WorldCategoriesEdit";
import { WorldCategoriesView } from "../components/View/WorldCategoriesView";
import { SettingFilled } from "@ant-design/icons";
export const WORLD_CATEGORIES_LIST = {
    path: "/admin/world-categories",
    component: WorldCategoriesList,
    menu: {
        key: "WORLD_CATEGORIES_LIST",
        label: "management.world_categories.menu.title",
        icon: SettingFilled,
    },
};
export const WORLD_CATEGORIES_CREATE = {
    path: "/admin/world-categories/create",
    component: WorldCategoriesCreate,
};
export const WORLD_CATEGORIES_EDIT = {
    path: "/admin/world-categories/:id/edit",
    component: WorldCategoriesEdit,
};
export const WORLD_CATEGORIES_VIEW = {
    path: "/admin/world-categories/:id/view",
    component: WorldCategoriesView,
};
