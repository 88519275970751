import "./i18n";
import { WISHLIST_ITEMS_LIST as BASE_WISHLIST_ITEMS_LIST, WISHLIST_ITEMS_CREATE as BASE_WISHLIST_ITEMS_CREATE, WISHLIST_ITEMS_EDIT as BASE_WISHLIST_ITEMS_EDIT, WISHLIST_ITEMS_VIEW as BASE_WISHLIST_ITEMS_VIEW, } from "./config/routes";
export const WISHLIST_ITEMS_LIST = {
    ...BASE_WISHLIST_ITEMS_LIST,
};
export const WISHLIST_ITEMS_CREATE = {
    ...BASE_WISHLIST_ITEMS_CREATE,
};
export const WISHLIST_ITEMS_EDIT = {
    ...BASE_WISHLIST_ITEMS_EDIT,
};
export const WISHLIST_ITEMS_VIEW = {
    ...BASE_WISHLIST_ITEMS_VIEW,
};
