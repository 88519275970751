import "./i18n";
import { DEMO_MEDIAS_LIST as BASE_DEMO_MEDIAS_LIST, DEMO_MEDIAS_CREATE as BASE_DEMO_MEDIAS_CREATE, DEMO_MEDIAS_EDIT as BASE_DEMO_MEDIAS_EDIT, DEMO_MEDIAS_VIEW as BASE_DEMO_MEDIAS_VIEW, } from "./config/routes";
export const DEMO_MEDIAS_LIST = {
    ...BASE_DEMO_MEDIAS_LIST,
};
export const DEMO_MEDIAS_CREATE = {
    ...BASE_DEMO_MEDIAS_CREATE,
};
export const DEMO_MEDIAS_EDIT = {
    ...BASE_DEMO_MEDIAS_EDIT,
};
export const DEMO_MEDIAS_VIEW = {
    ...BASE_DEMO_MEDIAS_VIEW,
};
