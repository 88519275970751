var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let DemoMediaListFiltersForm = class DemoMediaListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.demo_medias.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlImage360Files",
                label: t("management.demo_medias.fields.downloadUrlImage360Files"),
                name: ["downloadUrlImage360Files"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlImage2dFiles",
                label: t("management.demo_medias.fields.downloadUrlImage2dFiles"),
                name: ["downloadUrlImage2dFiles"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlImage3dOBJ",
                label: t("management.demo_medias.fields.downloadUrlImage3dOBJ"),
                name: ["downloadUrlImage3dOBJ"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlImage3dMTL",
                label: t("management.demo_medias.fields.downloadUrlImage3dMTL"),
                name: ["downloadUrlImage3dMTL"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlImage3DGLB",
                label: t("management.demo_medias.fields.downloadUrlImage3DGLB"),
                name: ["downloadUrlImage3DGLB"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "downloadUrlVideoUpload",
                label: t("management.demo_medias.fields.downloadUrlVideoUpload"),
                name: ["downloadUrlVideoUpload"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "videoUrl",
                label: t("management.demo_medias.fields.videoUrl"),
                name: ["videoUrl"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "createdAt",
                label: t("management.demo_medias.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.demo_medias.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.demo_medias.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.demo_medias.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
        ]);
    }
};
DemoMediaListFiltersForm = __decorate([
    Service({ transient: true })
], DemoMediaListFiltersForm);
export { DemoMediaListFiltersForm };
