import { Collection, } from "@bluelibs/x-ui";
import { AppFilesCollection, AppFileGroupsCollection, } from "@bluelibs/x-ui-admin";
import { UsersCollection, WorldsAssetPositionsCollection, ProjectsCollection, WorldCategoriesCollection, WorldReviewsCollection, MerchantsCollection, } from "@bundles/UIAppBundle/collections";
export class WorldsCollection extends Collection {
    getName() {
        return "Worlds";
    }
    getInputs() {
        return {
            insert: "WorldInsertInput!",
            update: "WorldUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AppFilesCollection,
                name: "worldFile",
                field: "worldFileId",
            },
            {
                collection: () => AppFilesCollection,
                name: "meshFile",
                field: "meshFileId",
            },
            {
                collection: () => AppFileGroupsCollection,
                name: "pictureGallery",
                field: "pictureGalleryId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => WorldCategoriesCollection,
                name: "category",
                field: "categoryId",
            },
            {
                collection: () => MerchantsCollection,
                name: "restrictedToMerchant",
                field: "restrictedToMerchantId",
            },
            {
                collection: () => WorldsAssetPositionsCollection,
                name: "assetPositions",
            },
            {
                collection: () => ProjectsCollection,
                name: "projects",
            },
            {
                collection: () => WorldReviewsCollection,
                name: "reviews",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
