var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let DemoUserListFiltersForm = class DemoUserListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "username",
                label: t("management.demo_users.fields.username"),
                name: ["username"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "password",
                label: t("management.demo_users.fields.password"),
                name: ["password"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "email",
                label: t("management.demo_users.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "firstName",
                label: t("management.demo_users.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "lastName",
                label: t("management.demo_users.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "roles",
                label: t("management.demo_users.fields.roles"),
                name: ["roles"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.demo_users.fields.roles"), children: [_jsx(Ant.Select.Option, { value: "ADMIN", children: "Admin" }, "ADMIN"), _jsx(Ant.Select.Option, { value: "ENDUSER", children: "Enduser" }, "ENDUSER"), _jsx(Ant.Select.Option, { value: "WORLD_ADMIN", children: "World Admin" }, "WORLD_ADMIN"), _jsx(Ant.Select.Option, { value: "MERCHANT", children: "Merchant" }, "MERCHANT")] }) })),
            },
            {
                id: "activeNotifications",
                label: t("management.demo_users.fields.activeNotifications"),
                name: ["activeNotifications"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.demo_users.fields.activeNotifications"), children: [_jsx(Ant.Select.Option, { value: "ENQUIRY", children: "Enquiry" }, "ENQUIRY"), _jsx(Ant.Select.Option, { value: "NEW_MESSAGE", children: "New Message" }, "NEW_MESSAGE"), _jsx(Ant.Select.Option, { value: "SOMEONE_SPEAKS", children: "Someone Speaks" }, "SOMEONE_SPEAKS"), _jsx(Ant.Select.Option, { value: "SYSTEM_UPDATE", children: "System Update" }, "SYSTEM_UPDATE")] }) })),
            },
            {
                id: "preferredLanguage",
                label: t("management.demo_users.fields.preferredLanguage"),
                name: ["preferredLanguage"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.demo_users.fields.preferredLanguage"), children: [_jsx(Ant.Select.Option, { value: "en", children: "En" }, "en"), _jsx(Ant.Select.Option, { value: "de", children: "De" }, "de")] }) })),
            },
            {
                id: "createdAt",
                label: t("management.demo_users.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.demo_users.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.demo_users.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.demo_users.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
        ]);
    }
};
DemoUserListFiltersForm = __decorate([
    Service({ transient: true })
], DemoUserListFiltersForm);
export { DemoUserListFiltersForm };
