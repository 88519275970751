import "./i18n";
import { MEDIAS_LIST as BASE_MEDIAS_LIST, MEDIAS_CREATE as BASE_MEDIAS_CREATE, MEDIAS_EDIT as BASE_MEDIAS_EDIT, MEDIAS_VIEW as BASE_MEDIAS_VIEW, } from "./config/routes";
export const MEDIAS_LIST = {
    ...BASE_MEDIAS_LIST,
};
export const MEDIAS_CREATE = {
    ...BASE_MEDIAS_CREATE,
};
export const MEDIAS_EDIT = {
    ...BASE_MEDIAS_EDIT,
};
export const MEDIAS_VIEW = {
    ...BASE_MEDIAS_VIEW,
};
