import { BasketsList } from "../components/List/BasketsList";
import { BasketsCreate } from "../components/Create/BasketsCreate";
import { BasketsEdit } from "../components/Edit/BasketsEdit";
import { BasketsView } from "../components/View/BasketsView";
import { SettingFilled } from "@ant-design/icons";
export const BASKETS_LIST = {
    path: "/admin/baskets",
    component: BasketsList,
    menu: {
        key: "BASKETS_LIST",
        label: "management.baskets.menu.title",
        icon: SettingFilled,
    },
};
export const BASKETS_CREATE = {
    path: "/admin/baskets/create",
    component: BasketsCreate,
};
export const BASKETS_EDIT = {
    path: "/admin/baskets/:id/edit",
    component: BasketsEdit,
};
export const BASKETS_VIEW = {
    path: "/admin/baskets/:id/view",
    component: BasketsView,
};
