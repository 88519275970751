import { Collection, } from "@bluelibs/x-ui";
import { MerchantsCollection, DisputesRepliesCollection, } from "@bundles/UIAppBundle/collections";
export class DisputesCollection extends Collection {
    getName() {
        return "Disputes";
    }
    getInputs() {
        return {
            insert: "DisputeInsertInput!",
            update: "DisputeUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => MerchantsCollection,
                name: "merchant",
                field: "merchantId",
            },
            {
                collection: () => DisputesRepliesCollection,
                name: "replies",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
