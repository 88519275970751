var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let MerchantList = class MerchantList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                title: t("management.merchants.fields.firstName"),
                key: "management.merchants.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "lastName",
                title: t("management.merchants.fields.lastName"),
                key: "management.merchants.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "fullName",
                title: t("management.merchants.fields.fullName"),
                key: "management.merchants.fields.fullName",
                dataIndex: ["fullName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "email",
                title: t("management.merchants.fields.email"),
                key: "management.merchants.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "phoneNumber",
                title: t("management.merchants.fields.phoneNumber"),
                key: "management.merchants.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "consentForEmails",
                title: t("management.merchants.fields.consentForEmails"),
                key: "management.merchants.fields.consentForEmails",
                dataIndex: ["consentForEmails"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "incomingChannel",
                title: t("management.merchants.fields.incomingChannel"),
                key: "management.merchants.fields.incomingChannel",
                dataIndex: ["incomingChannel"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "isAvailableToChat",
                title: t("management.merchants.fields.isAvailableToChat"),
                key: "management.merchants.fields.isAvailableToChat",
                dataIndex: ["isAvailableToChat"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.line1",
                title: t("management.merchants.fields.billingAddress.line1"),
                key: "management.merchants.fields.billingAddress.line1",
                dataIndex: ["billingAddress", "line1"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.line2",
                title: t("management.merchants.fields.billingAddress.line2"),
                key: "management.merchants.fields.billingAddress.line2",
                dataIndex: ["billingAddress", "line2"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.zip",
                title: t("management.merchants.fields.billingAddress.zip"),
                key: "management.merchants.fields.billingAddress.zip",
                dataIndex: ["billingAddress", "zip"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.city",
                title: t("management.merchants.fields.billingAddress.city"),
                key: "management.merchants.fields.billingAddress.city",
                dataIndex: ["billingAddress", "city"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.county",
                title: t("management.merchants.fields.billingAddress.county"),
                key: "management.merchants.fields.billingAddress.county",
                dataIndex: ["billingAddress", "county"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "billingAddress.country",
                title: t("management.merchants.fields.billingAddress.country"),
                key: "management.merchants.fields.billingAddress.country",
                dataIndex: ["billingAddress", "country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "stripeCustomerId",
                title: t("management.merchants.fields.stripeCustomerId"),
                key: "management.merchants.fields.stripeCustomerId",
                dataIndex: ["stripeCustomerId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "status",
                title: t("management.merchants.fields.status"),
                key: "management.merchants.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "VAT",
                title: t("management.merchants.fields.VAT"),
                key: "management.merchants.fields.VAT",
                dataIndex: ["VAT"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "disputes",
                title: t("management.merchants.fields.disputes"),
                key: "management.merchants.fields.disputes",
                dataIndex: ["disputes"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.DISPUTES_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "identifier",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "favouriteWorlds",
                title: t("management.merchants.fields.favouriteWorlds"),
                key: "management.merchants.fields.favouriteWorlds",
                dataIndex: ["favouriteWorlds"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.WORLDS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "name.text",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "inquiries",
                title: t("management.merchants.fields.inquiries"),
                key: "management.merchants.fields.inquiries",
                dataIndex: ["inquiries"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.END_USER_INQUIRIES_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "_id",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "projects",
                title: t("management.merchants.fields.projects"),
                key: "management.merchants.fields.projects",
                dataIndex: ["projects"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.PROJECTS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "name",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }));
                },
            },
            {
                id: "avatar",
                title: t("management.merchants.fields.avatar"),
                key: "management.merchants.fields.avatar",
                dataIndex: ["avatar"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "file",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "owner",
                title: t("management.merchants.fields.owner"),
                key: "management.merchants.fields.owner",
                dataIndex: ["owner"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            disputes: "disputes.identifier",
            favouriteWorlds: "favouriteWorlds.name.text",
            inquiries: "inquiries._id",
            projects: "projects.name",
            avatar: "avatar._id",
            owner: "owner.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            firstName: 1,
            lastName: 1,
            fullName: 1,
            email: 1,
            phoneNumber: 1,
            consentForEmails: 1,
            incomingChannel: 1,
            isAvailableToChat: 1,
            billingAddress: {
                line1: 1,
                line2: 1,
                zip: 1,
                city: 1,
                county: 1,
                country: 1,
            },
            stripeCustomerId: 1,
            status: 1,
            VAT: 1,
            disputes: {
                _id: 1,
                identifier: 1,
            },
            favouriteWorlds: {
                _id: 1,
                name: {
                    text: 1,
                    values: {
                        lang: 1,
                        text: 1,
                    },
                },
            },
            favouriteWorldsIds: 1,
            inquiries: {
                _id: 1,
            },
            projects: {
                _id: 1,
                name: 1,
            },
            avatar: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            avatarId: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
        };
    }
};
MerchantList = __decorate([
    Service({ transient: true })
], MerchantList);
export { MerchantList };
