import { Collection, } from "@bluelibs/x-ui";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
export class SubscriptionPricesCollection extends Collection {
    getName() {
        return "SubscriptionPrices";
    }
    getInputs() {
        return {
            insert: "SubscriptionPriceInsertInput!",
            update: "SubscriptionPriceUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
