import { WorldsAssetPositionsList } from "../components/List/WorldsAssetPositionsList";
import { WorldsAssetPositionsCreate } from "../components/Create/WorldsAssetPositionsCreate";
import { WorldsAssetPositionsEdit } from "../components/Edit/WorldsAssetPositionsEdit";
import { WorldsAssetPositionsView } from "../components/View/WorldsAssetPositionsView";
import { SettingFilled } from "@ant-design/icons";
export const WORLDS_ASSET_POSITIONS_LIST = {
    path: "/admin/worlds-asset-positions",
    component: WorldsAssetPositionsList,
    menu: {
        key: "WORLDS_ASSET_POSITIONS_LIST",
        label: "management.worlds_asset_positions.menu.title",
        icon: SettingFilled,
    },
};
export const WORLDS_ASSET_POSITIONS_CREATE = {
    path: "/admin/worlds-asset-positions/create",
    component: WorldsAssetPositionsCreate,
};
export const WORLDS_ASSET_POSITIONS_EDIT = {
    path: "/admin/worlds-asset-positions/:id/edit",
    component: WorldsAssetPositionsEdit,
};
export const WORLDS_ASSET_POSITIONS_VIEW = {
    path: "/admin/worlds-asset-positions/:id/view",
    component: WorldsAssetPositionsView,
};
