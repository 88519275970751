import { WorldReviewsList } from "../components/List/WorldReviewsList";
import { WorldReviewsCreate } from "../components/Create/WorldReviewsCreate";
import { WorldReviewsEdit } from "../components/Edit/WorldReviewsEdit";
import { WorldReviewsView } from "../components/View/WorldReviewsView";
import { SettingFilled } from "@ant-design/icons";
export const WORLD_REVIEWS_LIST = {
    path: "/admin/world-reviews",
    component: WorldReviewsList,
    menu: {
        key: "WORLD_REVIEWS_LIST",
        label: "management.world_reviews.menu.title",
        icon: SettingFilled,
    },
};
export const WORLD_REVIEWS_CREATE = {
    path: "/admin/world-reviews/create",
    component: WorldReviewsCreate,
};
export const WORLD_REVIEWS_EDIT = {
    path: "/admin/world-reviews/:id/edit",
    component: WorldReviewsEdit,
};
export const WORLD_REVIEWS_VIEW = {
    path: "/admin/world-reviews/:id/view",
    component: WorldReviewsView,
};
