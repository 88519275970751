import "./i18n";
import { NOTIFICATIONS_LIST as BASE_NOTIFICATIONS_LIST, NOTIFICATIONS_CREATE as BASE_NOTIFICATIONS_CREATE, NOTIFICATIONS_EDIT as BASE_NOTIFICATIONS_EDIT, NOTIFICATIONS_VIEW as BASE_NOTIFICATIONS_VIEW, } from "./config/routes";
export const NOTIFICATIONS_LIST = {
    ...BASE_NOTIFICATIONS_LIST,
};
export const NOTIFICATIONS_CREATE = {
    ...BASE_NOTIFICATIONS_CREATE,
};
export const NOTIFICATIONS_EDIT = {
    ...BASE_NOTIFICATIONS_EDIT,
};
export const NOTIFICATIONS_VIEW = {
    ...BASE_NOTIFICATIONS_VIEW,
};
