import { Collection, } from "@bluelibs/x-ui";
import { ProductsCollection, EndUsersCollection, } from "@bundles/UIAppBundle/collections";
export class OrderItemsCollection extends Collection {
    getName() {
        return "OrderItems";
    }
    getInputs() {
        return {
            insert: "OrderItemInsertInput!",
            update: "OrderItemUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProductsCollection,
                name: "product",
                field: "productId",
            },
            {
                collection: () => EndUsersCollection,
                name: "endUser",
                field: "endUserId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
