import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { AcceptedLanguage } from "@root/api.types";
import * as Ant from "antd";
export const I18NFieldCustomRenderer = ({ name, label }) => {
    const languages = [AcceptedLanguage.en, AcceptedLanguage.de];
    return (_jsx(Ant.Form.List, { name: [name.toString(), "values"], initialValue: languages.map((lang) => ({ lang, text: "" })), children: (fields) => {
            return (_jsx("div", { children: fields.map((field, index) => {
                    return (_jsx(_Fragment, { children: _jsx(Ant.Form.Item, { label: `${label} [${languages[index]}]`, ...field, name: [field.name, "text"], children: _jsx(Ant.Input, {}) }) }));
                }) }));
        } }));
};
