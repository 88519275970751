import "./i18n";
import { MERCHANTS_BILLING_PACKAGES_LIST as BASE_MERCHANTS_BILLING_PACKAGES_LIST, MERCHANTS_BILLING_PACKAGES_CREATE as BASE_MERCHANTS_BILLING_PACKAGES_CREATE, MERCHANTS_BILLING_PACKAGES_EDIT as BASE_MERCHANTS_BILLING_PACKAGES_EDIT, MERCHANTS_BILLING_PACKAGES_VIEW as BASE_MERCHANTS_BILLING_PACKAGES_VIEW, } from "./config/routes";
export const MERCHANTS_BILLING_PACKAGES_LIST = {
    ...BASE_MERCHANTS_BILLING_PACKAGES_LIST,
};
export const MERCHANTS_BILLING_PACKAGES_CREATE = {
    ...BASE_MERCHANTS_BILLING_PACKAGES_CREATE,
};
export const MERCHANTS_BILLING_PACKAGES_EDIT = {
    ...BASE_MERCHANTS_BILLING_PACKAGES_EDIT,
};
export const MERCHANTS_BILLING_PACKAGES_VIEW = {
    ...BASE_MERCHANTS_BILLING_PACKAGES_VIEW,
};
