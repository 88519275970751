var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let CompanyListFiltersForm = class CompanyListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.companies.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "vatId",
                label: t("management.companies.fields.vatId"),
                name: ["vatId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "address",
                label: t("management.companies.fields.address"),
                name: ["address"],
                columns: true,
                nest: [
                    {
                        id: "line1",
                        label: t("management.companies.fields.address.line1"),
                        name: ["address", "line1"],
                        component: Ant.Input,
                    },
                    {
                        id: "line2",
                        label: t("management.companies.fields.address.line2"),
                        name: ["address", "line2"],
                        component: Ant.Input,
                    },
                    {
                        id: "zip",
                        label: t("management.companies.fields.address.zip"),
                        name: ["address", "zip"],
                        component: Ant.Input,
                    },
                    {
                        id: "city",
                        label: t("management.companies.fields.address.city"),
                        name: ["address", "city"],
                        component: Ant.Input,
                    },
                    {
                        id: "county",
                        label: t("management.companies.fields.address.county"),
                        name: ["address", "county"],
                        component: Ant.Input,
                    },
                    {
                        id: "country",
                        label: t("management.companies.fields.address.country"),
                        name: ["address", "country"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdById",
                label: t("management.companies.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.companies.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.companies.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.companies.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
CompanyListFiltersForm = __decorate([
    Service({ transient: true })
], CompanyListFiltersForm);
export { CompanyListFiltersForm };
