import { DemoUsersList } from "../components/List/DemoUsersList";
import { DemoUsersCreate } from "../components/Create/DemoUsersCreate";
import { DemoUsersEdit } from "../components/Edit/DemoUsersEdit";
import { DemoUsersView } from "../components/View/DemoUsersView";
import { SettingFilled } from "@ant-design/icons";
export const DEMO_USERS_LIST = {
    path: "/admin/demo-users",
    component: DemoUsersList,
    menu: {
        key: "DEMO_USERS_LIST",
        label: "management.demo_users.menu.title",
        icon: SettingFilled,
    },
};
export const DEMO_USERS_CREATE = {
    path: "/admin/demo-users/create",
    component: DemoUsersCreate,
};
export const DEMO_USERS_EDIT = {
    path: "/admin/demo-users/:id/edit",
    component: DemoUsersEdit,
};
export const DEMO_USERS_VIEW = {
    path: "/admin/demo-users/:id/view",
    component: DemoUsersView,
};
