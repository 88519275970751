var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { MediasCollection, ProductCategoriesCollection, MerchantsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
import { I18NFieldCustomRenderer } from "@bundles/UIAppBundle/components";
let ProductListFiltersForm = class ProductListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.products.fields.name"),
                name: ["name"],
                columns: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "description",
                label: t("management.products.fields.description"),
                name: ["description"],
                columns: true,
                render: (props) => _jsx(I18NFieldCustomRenderer, { ...props }),
            },
            {
                id: "price",
                label: t("management.products.fields.price"),
                name: ["price"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "vat",
                label: t("management.products.fields.vat"),
                name: ["vat"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }) })),
            },
            {
                id: "manufacturer",
                label: t("management.products.fields.manufacturer"),
                name: ["manufacturer"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "href",
                label: t("management.products.fields.href"),
                name: ["href"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "tags",
                label: t("management.products.fields.tags"),
                name: ["tags"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "isPhysical",
                label: t("management.products.fields.isPhysical"),
                name: ["isPhysical"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "inventory",
                label: t("management.products.fields.inventory"),
                name: ["inventory"],
                columns: true,
                nest: [
                    {
                        id: "status",
                        label: t("management.products.fields.inventory.status"),
                        name: ["inventory", "status"],
                        render: (props) => {
                            const options = [
                                { value: "IN_STOCK", label: "In Stock" },
                                { value: "OUT_OF_STOCK", label: "Out Of Stock" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.products.fields.inventory.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.products.fields.inventory.status_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.products.fields.inventory.status_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "stockKeepingUnit",
                        label: t("management.products.fields.inventory.stockKeepingUnit"),
                        name: ["inventory", "stockKeepingUnit"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "barcode",
                        label: t("management.products.fields.inventory.barcode"),
                        name: ["inventory", "barcode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "options",
                label: t("management.products.fields.options"),
                name: ["options"],
                columns: true,
                nest: [
                    {
                        id: "sizes",
                        label: t("management.products.fields.options.sizes"),
                        name: ["options", "sizes"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "colours",
                        label: t("management.products.fields.options.colours"),
                        name: ["options", "colours"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "materials",
                        label: t("management.products.fields.options.materials"),
                        name: ["options", "materials"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                ],
            },
            {
                id: "shipping",
                label: t("management.products.fields.shipping"),
                name: ["shipping"],
                columns: true,
                nest: [
                    {
                        id: "weight",
                        label: t("management.products.fields.shipping.weight"),
                        name: ["shipping", "weight"],
                        component: Ant.InputNumber,
                    },
                    {
                        id: "originCountryOrRegion",
                        label: t("management.products.fields.shipping.originCountryOrRegion"),
                        name: ["shipping", "originCountryOrRegion"],
                        component: Ant.Input,
                    },
                    {
                        id: "harmonisedSystemCode",
                        label: t("management.products.fields.shipping.harmonisedSystemCode"),
                        name: ["shipping", "harmonisedSystemCode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "seo",
                label: t("management.products.fields.seo"),
                name: ["seo"],
                columns: true,
                nest: [
                    {
                        id: "title",
                        label: t("management.products.fields.seo.title"),
                        name: ["seo", "title"],
                        component: Ant.Input,
                    },
                    {
                        id: "description",
                        label: t("management.products.fields.seo.description"),
                        name: ["seo", "description"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.products.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.products.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "updatedAt",
                label: t("management.products.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.products.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.DatePicker.RangePicker, {}) })),
            },
            {
                id: "mediaId",
                label: t("management.products.fields.media"),
                name: ["mediaId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MediasCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "categoryId",
                label: t("management.products.fields.category"),
                name: ["categoryId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProductCategoriesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "merchantId",
                label: t("management.products.fields.merchant"),
                name: ["merchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "createdById",
                label: t("management.products.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.products.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
            {
                id: "updatedById",
                label: t("management.products.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.products.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
ProductListFiltersForm = __decorate([
    Service({ transient: true })
], ProductListFiltersForm);
export { ProductListFiltersForm };
