import "./i18n";
import { DEMO_USERS_LIST as BASE_DEMO_USERS_LIST, DEMO_USERS_CREATE as BASE_DEMO_USERS_CREATE, DEMO_USERS_EDIT as BASE_DEMO_USERS_EDIT, DEMO_USERS_VIEW as BASE_DEMO_USERS_VIEW, } from "./config/routes";
export const DEMO_USERS_LIST = {
    ...BASE_DEMO_USERS_LIST,
};
export const DEMO_USERS_CREATE = {
    ...BASE_DEMO_USERS_CREATE,
};
export const DEMO_USERS_EDIT = {
    ...BASE_DEMO_USERS_EDIT,
};
export const DEMO_USERS_VIEW = {
    ...BASE_DEMO_USERS_VIEW,
};
