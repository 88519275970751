import { NotificationsList } from "../components/List/NotificationsList";
import { NotificationsCreate } from "../components/Create/NotificationsCreate";
import { NotificationsEdit } from "../components/Edit/NotificationsEdit";
import { NotificationsView } from "../components/View/NotificationsView";
import { SettingFilled } from "@ant-design/icons";
export const NOTIFICATIONS_LIST = {
    path: "/admin/notifications",
    component: NotificationsList,
    menu: {
        key: "NOTIFICATIONS_LIST",
        label: "management.notifications.menu.title",
        icon: SettingFilled,
    },
};
export const NOTIFICATIONS_CREATE = {
    path: "/admin/notifications/create",
    component: NotificationsCreate,
};
export const NOTIFICATIONS_EDIT = {
    path: "/admin/notifications/:id/edit",
    component: NotificationsEdit,
};
export const NOTIFICATIONS_VIEW = {
    path: "/admin/notifications/:id/view",
    component: NotificationsView,
};
