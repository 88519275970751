import "./i18n";
import { WORLDS_ASSET_POSITIONS_LIST as BASE_WORLDS_ASSET_POSITIONS_LIST, WORLDS_ASSET_POSITIONS_CREATE as BASE_WORLDS_ASSET_POSITIONS_CREATE, WORLDS_ASSET_POSITIONS_EDIT as BASE_WORLDS_ASSET_POSITIONS_EDIT, WORLDS_ASSET_POSITIONS_VIEW as BASE_WORLDS_ASSET_POSITIONS_VIEW, } from "./config/routes";
export const WORLDS_ASSET_POSITIONS_LIST = {
    ...BASE_WORLDS_ASSET_POSITIONS_LIST,
};
export const WORLDS_ASSET_POSITIONS_CREATE = {
    ...BASE_WORLDS_ASSET_POSITIONS_CREATE,
};
export const WORLDS_ASSET_POSITIONS_EDIT = {
    ...BASE_WORLDS_ASSET_POSITIONS_EDIT,
};
export const WORLDS_ASSET_POSITIONS_VIEW = {
    ...BASE_WORLDS_ASSET_POSITIONS_VIEW,
};
