import "./i18n";
import { BASKET_ITEMS_LIST as BASE_BASKET_ITEMS_LIST, BASKET_ITEMS_CREATE as BASE_BASKET_ITEMS_CREATE, BASKET_ITEMS_EDIT as BASE_BASKET_ITEMS_EDIT, BASKET_ITEMS_VIEW as BASE_BASKET_ITEMS_VIEW, } from "./config/routes";
export const BASKET_ITEMS_LIST = {
    ...BASE_BASKET_ITEMS_LIST,
};
export const BASKET_ITEMS_CREATE = {
    ...BASE_BASKET_ITEMS_CREATE,
};
export const BASKET_ITEMS_EDIT = {
    ...BASE_BASKET_ITEMS_EDIT,
};
export const BASKET_ITEMS_VIEW = {
    ...BASE_BASKET_ITEMS_VIEW,
};
