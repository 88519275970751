import "./i18n";
import { PROJECTS_LIST as BASE_PROJECTS_LIST, PROJECTS_CREATE as BASE_PROJECTS_CREATE, PROJECTS_EDIT as BASE_PROJECTS_EDIT, PROJECTS_VIEW as BASE_PROJECTS_VIEW, } from "./config/routes";
export const PROJECTS_LIST = {
    ...BASE_PROJECTS_LIST,
};
export const PROJECTS_CREATE = {
    ...BASE_PROJECTS_CREATE,
};
export const PROJECTS_EDIT = {
    ...BASE_PROJECTS_EDIT,
};
export const PROJECTS_VIEW = {
    ...BASE_PROJECTS_VIEW,
};
