import { Collection, } from "@bluelibs/x-ui";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { WorldsCollection, MerchantsBillingsCollection, ProjectsCategoriesCollection, ProjectAssetInfosCollection, MerchantsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class ProjectsCollection extends Collection {
    getName() {
        return "Projects";
    }
    getInputs() {
        return {
            insert: "ProjectInsertInput!",
            update: "ProjectUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => WorldsCollection,
                name: "world",
                field: "worldId",
            },
            {
                collection: () => ProjectsCategoriesCollection,
                name: "category",
                field: "categoryId",
            },
            {
                collection: () => MerchantsCollection,
                name: "merchant",
                field: "merchantId",
            },
            {
                collection: () => AppFilesCollection,
                name: "watermark",
                field: "watermarkId",
            },
            {
                collection: () => AppFilesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => MerchantsBillingsCollection,
                name: "billing",
            },
            {
                collection: () => ProjectAssetInfosCollection,
                name: "assetInfos",
                many: true,
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            toDate: (v) => (v && new Date(v) ? new Date(v) : v),
            liveDate: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
