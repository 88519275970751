import { Collection, } from "@bluelibs/x-ui";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
export class MediasCollection extends Collection {
    getName() {
        return "Medias";
    }
    getInputs() {
        return {
            insert: "MediaInsertInput!",
            update: "MediaUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AppFilesCollection,
                name: "image360Files",
                many: true,
                field: "image360FilesIds",
            },
            {
                collection: () => AppFilesCollection,
                name: "image2dFiles",
                many: true,
                field: "image2dFilesIds",
            },
            {
                collection: () => AppFilesCollection,
                name: "image3dOBJ",
                field: "image3dOBJId",
            },
            {
                collection: () => AppFilesCollection,
                name: "image3dMTL",
                field: "image3dMTLId",
            },
            {
                collection: () => AppFilesCollection,
                name: "image3DGLB",
                field: "image3DGLBId",
            },
            {
                collection: () => AppFilesCollection,
                name: "videoUpload",
                field: "videoUploadId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
