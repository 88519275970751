import { ProjectAssetInfosList } from "../components/List/ProjectAssetInfosList";
import { ProjectAssetInfosCreate } from "../components/Create/ProjectAssetInfosCreate";
import { ProjectAssetInfosEdit } from "../components/Edit/ProjectAssetInfosEdit";
import { ProjectAssetInfosView } from "../components/View/ProjectAssetInfosView";
import { SettingFilled } from "@ant-design/icons";
export const PROJECT_ASSET_INFOS_LIST = {
    path: "/admin/project-asset-infos",
    component: ProjectAssetInfosList,
    menu: {
        key: "PROJECT_ASSET_INFOS_LIST",
        label: "management.project_asset_infos.menu.title",
        icon: SettingFilled,
    },
};
export const PROJECT_ASSET_INFOS_CREATE = {
    path: "/admin/project-asset-infos/create",
    component: ProjectAssetInfosCreate,
};
export const PROJECT_ASSET_INFOS_EDIT = {
    path: "/admin/project-asset-infos/:id/edit",
    component: ProjectAssetInfosEdit,
};
export const PROJECT_ASSET_INFOS_VIEW = {
    path: "/admin/project-asset-infos/:id/view",
    component: ProjectAssetInfosView,
};
