var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { DemoMerchantsCollection, DemoProjectsCollection, } from "@bundles/UIAppBundle/collections";
let DemoProjectEditForm = class DemoProjectEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.demo_projects.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "onSuccessfulInquiryRedirectURL",
                label: t("management.demo_projects.fields.onSuccessfulInquiryRedirectURL"),
                name: ["onSuccessfulInquiryRedirectURL"],
                component: Ant.Input,
            },
            {
                id: "linkName",
                label: t("management.demo_projects.fields.linkName"),
                name: ["linkName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.demo_projects.fields.description"),
                name: ["description"],
                required: true,
                nest: [
                    {
                        id: "values",
                        label: t("management.demo_projects.fields.description.values"),
                        name: ["description", "values"],
                        required: true,
                        isList: true,
                        nest: [],
                    },
                    {
                        id: "text",
                        label: t("management.demo_projects.fields.description.text"),
                        name: ["description", "text"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "toDate",
                label: t("management.demo_projects.fields.toDate"),
                name: ["toDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "liveDate",
                label: t("management.demo_projects.fields.liveDate"),
                name: ["liveDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "state",
                label: t("management.demo_projects.fields.state"),
                name: ["state"],
                required: true,
                initialValue: "UNPUBLISHED",
                render: (props) => {
                    const options = [
                        { value: "PUBLISHED", label: "Published" },
                        { value: "UNPUBLISHED", label: "Unpublished" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.demo_projects.fields.state"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.demo_projects.fields.state_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.demo_projects.fields.state_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "type",
                label: t("management.demo_projects.fields.type"),
                name: ["type"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "GALLERY", label: "Exquisite Gallery" },
                        { value: "SHOP", label: "Shop" },
                        { value: "EXHIBITION", label: "Exhibition" },
                        { value: "HALL", label: "Hall" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.demo_projects.fields.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.demo_projects.fields.type_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.demo_projects.fields.type_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "watermarksEnabled",
                label: t("management.demo_projects.fields.watermarksEnabled"),
                name: ["watermarksEnabled"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isAvailableToVideoAndAudioChat",
                label: t("management.demo_projects.fields.isAvailableToVideoAndAudioChat"),
                name: ["isAvailableToVideoAndAudioChat"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isApprovedByAdmin",
                label: t("management.demo_projects.fields.isApprovedByAdmin"),
                name: ["isApprovedByAdmin"],
                required: true,
                tooltip: t("management.demo_projects.fields.isApprovedByAdmin_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "isSetup",
                label: t("management.demo_projects.fields.isSetup"),
                name: ["isSetup"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, { value: false, children: "No" }, 0), _jsx(Ant.Radio, { value: true, children: "Yes" }, 1)] }) })),
            },
            {
                id: "commonIdWorld",
                label: t("management.demo_projects.fields.commonIdWorld"),
                name: ["commonIdWorld"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "commonIdCategory",
                label: t("management.demo_projects.fields.commonIdCategory"),
                name: ["commonIdCategory"],
                component: Ant.Input,
            },
            {
                id: "downloadUrlWatermark",
                label: t("management.demo_projects.fields.downloadUrlWatermark"),
                name: ["downloadUrlWatermark"],
                component: Ant.Input,
            },
            {
                id: "downloadUrlLogo",
                label: t("management.demo_projects.fields.downloadUrlLogo"),
                name: ["downloadUrlLogo"],
                component: Ant.Input,
            },
            {
                id: "merchantId",
                label: t("management.demo_projects.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: DemoMerchantsCollection, field: "_id", required: true }) })),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            onSuccessfulInquiryRedirectURL: 1,
            linkName: 1,
            description: {
                values: {
                    lang: 1,
                    text: 1,
                },
            },
            toDate: 1,
            liveDate: 1,
            state: 1,
            type: 1,
            watermarksEnabled: 1,
            isAvailableToVideoAndAudioChat: 1,
            isApprovedByAdmin: 1,
            isSetup: 1,
            commonIdWorld: 1,
            commonIdCategory: 1,
            downloadUrlWatermark: 1,
            downloadUrlLogo: 1,
            merchant: {
                _id: 1,
            },
            merchantId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.demo_projects.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => DemoProjectsCollection),
    __metadata("design:type", typeof (_a = typeof DemoProjectsCollection !== "undefined" && DemoProjectsCollection) === "function" ? _a : Object)
], DemoProjectEditForm.prototype, "collection", void 0);
DemoProjectEditForm = __decorate([
    Service({ transient: true })
], DemoProjectEditForm);
export { DemoProjectEditForm };
