import { CompaniesList } from "../components/List/CompaniesList";
import { CompaniesCreate } from "../components/Create/CompaniesCreate";
import { CompaniesEdit } from "../components/Edit/CompaniesEdit";
import { CompaniesView } from "../components/View/CompaniesView";
import { SettingFilled } from "@ant-design/icons";
export const COMPANIES_LIST = {
    path: "/admin/companies",
    component: CompaniesList,
    menu: {
        key: "COMPANIES_LIST",
        label: "management.companies.menu.title",
        icon: SettingFilled,
    },
};
export const COMPANIES_CREATE = {
    path: "/admin/companies/create",
    component: CompaniesCreate,
};
export const COMPANIES_EDIT = {
    path: "/admin/companies/:id/edit",
    component: CompaniesEdit,
};
export const COMPANIES_VIEW = {
    path: "/admin/companies/:id/view",
    component: CompaniesView,
};
