import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import React, { useState } from "react";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card, notification, } from "antd";
export function Register() {
    const guardian = useGuardian();
    const router = useRouter();
    const tl = useTranslate("authentication.register");
    const [submitError, setSubmitError] = useState(null);
    const onSubmit = (data) => {
        const { email, password, firstName, lastName } = data;
        guardian
            .register({
            email,
            firstName,
            lastName,
            password,
        })
            .then((token) => {
            notification.success({
                message: tl("success.header"),
                description: tl("success.description"),
            });
            setSubmitError(null);
            router.go(Routes.HOME);
        })
            .catch((err) => {
            setSubmitError(err.toString());
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, { justify: "center", align: "middle", style: style, className: "register-page", children: _jsx(Col, { sm: 24, md: 12, lg: 6, children: _jsx(Card, { title: tl("header"), children: _jsxs(Form, { onFinish: (data) => onSubmit(data), className: "authentication-form", children: [_jsx(Form.Item, { name: "firstName", rules: [{ required: true }], children: _jsx(Input, { placeholder: tl("fields.firstName") }) }), _jsx(Form.Item, { name: "lastName", rules: [{ required: true }], children: _jsx(Input, { placeholder: tl("fields.lastName") }) }), _jsx(Form.Item, { name: "email", rules: [{ required: true }], children: _jsx(Input, { placeholder: tl("fields.email") }) }), _jsx(Form.Item, { name: "password", rules: [{ required: true }], children: _jsx(Input.Password, { placeholder: tl("fields.password") }) }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "authentication-form-button", children: tl("submit") }) }), submitError && _jsx(Alert, { message: submitError, type: "error" })] }) }) }) }));
}
