import { DemoProductsList } from "../components/List/DemoProductsList";
import { DemoProductsCreate } from "../components/Create/DemoProductsCreate";
import { DemoProductsEdit } from "../components/Edit/DemoProductsEdit";
import { DemoProductsView } from "../components/View/DemoProductsView";
import { SettingFilled } from "@ant-design/icons";
export const DEMO_PRODUCTS_LIST = {
    path: "/admin/demo-products",
    component: DemoProductsList,
    menu: {
        key: "DEMO_PRODUCTS_LIST",
        label: "management.demo_products.menu.title",
        icon: SettingFilled,
    },
};
export const DEMO_PRODUCTS_CREATE = {
    path: "/admin/demo-products/create",
    component: DemoProductsCreate,
};
export const DEMO_PRODUCTS_EDIT = {
    path: "/admin/demo-products/:id/edit",
    component: DemoProductsEdit,
};
export const DEMO_PRODUCTS_VIEW = {
    path: "/admin/demo-products/:id/view",
    component: DemoProductsView,
};
