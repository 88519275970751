import { Collection, } from "@bluelibs/x-ui";
import { ProjectsCollection, MerchantsCollection, } from "@bundles/UIAppBundle/collections";
export class MerchantsBillingsCollection extends Collection {
    getName() {
        return "MerchantsBillings";
    }
    getInputs() {
        return {
            insert: "MerchantsBillingInsertInput!",
            update: "MerchantsBillingUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProjectsCollection,
                name: "project",
                field: "projectId",
            },
            {
                collection: () => MerchantsCollection,
                name: "merchant",
                field: "merchantId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            renewalDate: (v) => (v && new Date(v) ? new Date(v) : v),
            expiryDate: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
