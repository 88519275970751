import { Collection, } from "@bluelibs/x-ui";
import { ProjectsCollection, ProductsCollection, MerchantsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class AnalyticsCollection extends Collection {
    getName() {
        return "Analytics";
    }
    getInputs() {
        return {
            insert: "AnalyticInsertInput!",
            update: "AnalyticUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProjectsCollection,
                name: "project",
                field: "projectId",
            },
            {
                collection: () => ProductsCollection,
                name: "product",
                field: "productId",
            },
            {
                collection: () => MerchantsCollection,
                name: "merchant",
                field: "merchantId",
            },
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
