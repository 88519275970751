var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { WorldsCollection, MediasCollection, WorldsAssetPositionsCollection, } from "@bundles/UIAppBundle/collections";
let WorldsAssetPositionCreateForm = class WorldsAssetPositionCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "coordinates",
                label: t("management.worlds_asset_positions.fields.coordinates"),
                name: ["coordinates"],
                required: true,
                nest: [
                    {
                        id: "position",
                        label: t("management.worlds_asset_positions.fields.coordinates.position"),
                        name: ["coordinates", "position"],
                        required: true,
                        tooltip: t("management.worlds_asset_positions.fields.coordinates.position_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "rotation",
                        label: t("management.worlds_asset_positions.fields.coordinates.rotation"),
                        name: ["coordinates", "rotation"],
                        required: true,
                        tooltip: t("management.worlds_asset_positions.fields.coordinates.rotation_description"),
                        component: Ant.Input,
                    },
                    {
                        id: "scale",
                        label: t("management.worlds_asset_positions.fields.coordinates.scale"),
                        name: ["coordinates", "scale"],
                        required: true,
                        tooltip: t("management.worlds_asset_positions.fields.coordinates.scale_description"),
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "mediaType",
                label: t("management.worlds_asset_positions.fields.mediaType"),
                name: ["mediaType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "IMAGE_2D", label: "Image 2 D" },
                        { value: "IMAGE_2D_CAROUSEL", label: "Image 2 D Carousel" },
                        { value: "IMAGE_360", label: "Image 360" },
                        { value: "IMAGE_360_CAROUSEL", label: "Image 360 Carousel" },
                        { value: "ITEM_3D", label: "Item 3 D" },
                        { value: "VIDEO", label: "Video" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.worlds_asset_positions.fields.mediaType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.worlds_asset_positions.fields.mediaType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.worlds_asset_positions.fields.mediaType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "typeFor3D",
                label: t("management.worlds_asset_positions.fields.typeFor3D"),
                name: ["typeFor3D"],
                render: (props) => {
                    const options = [
                        { value: "OBJ_MTL", label: "Obj Mtl" },
                        { value: "GLB", label: "Glb" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.worlds_asset_positions.fields.typeFor3D"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.worlds_asset_positions.fields.typeFor3D_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.worlds_asset_positions.fields.typeFor3D_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "action",
                label: t("management.worlds_asset_positions.fields.action"),
                name: ["action"],
                nest: [
                    {
                        id: "type",
                        label: t("management.worlds_asset_positions.fields.action.type"),
                        name: ["action", "type"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "EXIT", label: "Exit" },
                                { value: "NEXT_ROOM", label: "Next Room" },
                                { value: "ADD_TO_BASKET", label: "Add To Basket" },
                                { value: "CHAT", label: "Chat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.worlds_asset_positions.fields.action.type"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.worlds_asset_positions.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.worlds_asset_positions.fields.action.type_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "projectId",
                        label: t("management.worlds_asset_positions.fields.action.projectId"),
                        name: ["action", "projectId"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "width",
                label: t("management.worlds_asset_positions.fields.width"),
                name: ["width"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "height",
                label: t("management.worlds_asset_positions.fields.height"),
                name: ["height"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "worldId",
                label: t("management.worlds_asset_positions.fields.world"),
                name: ["worldId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: WorldsCollection, field: "", required: true }) })),
            },
            {
                id: "mediaId",
                label: t("management.worlds_asset_positions.fields.media"),
                name: ["mediaId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MediasCollection, field: "name", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.worlds_asset_positions.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.WORLDS_ASSET_POSITIONS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.WORLDS_ASSET_POSITIONS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.WORLDS_ASSET_POSITIONS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => WorldsAssetPositionsCollection),
    __metadata("design:type", typeof (_a = typeof WorldsAssetPositionsCollection !== "undefined" && WorldsAssetPositionsCollection) === "function" ? _a : Object)
], WorldsAssetPositionCreateForm.prototype, "collection", void 0);
WorldsAssetPositionCreateForm = __decorate([
    Service({ transient: true })
], WorldsAssetPositionCreateForm);
export { WorldsAssetPositionCreateForm };
