import "./i18n";
import { END_USER_INQUIRIES_LIST as BASE_END_USER_INQUIRIES_LIST, END_USER_INQUIRIES_CREATE as BASE_END_USER_INQUIRIES_CREATE, END_USER_INQUIRIES_EDIT as BASE_END_USER_INQUIRIES_EDIT, END_USER_INQUIRIES_VIEW as BASE_END_USER_INQUIRIES_VIEW, } from "./config/routes";
export const END_USER_INQUIRIES_LIST = {
    ...BASE_END_USER_INQUIRIES_LIST,
};
export const END_USER_INQUIRIES_CREATE = {
    ...BASE_END_USER_INQUIRIES_CREATE,
};
export const END_USER_INQUIRIES_EDIT = {
    ...BASE_END_USER_INQUIRIES_EDIT,
};
export const END_USER_INQUIRIES_VIEW = {
    ...BASE_END_USER_INQUIRIES_VIEW,
};
