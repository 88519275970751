import { DemoMerchantsList } from "../components/List/DemoMerchantsList";
import { DemoMerchantsCreate } from "../components/Create/DemoMerchantsCreate";
import { DemoMerchantsEdit } from "../components/Edit/DemoMerchantsEdit";
import { DemoMerchantsView } from "../components/View/DemoMerchantsView";
import { SettingFilled } from "@ant-design/icons";
export const DEMO_MERCHANTS_LIST = {
    path: "/admin/demo-merchants",
    component: DemoMerchantsList,
    menu: {
        key: "DEMO_MERCHANTS_LIST",
        label: "management.demo_merchants.menu.title",
        icon: SettingFilled,
    },
};
export const DEMO_MERCHANTS_CREATE = {
    path: "/admin/demo-merchants/create",
    component: DemoMerchantsCreate,
};
export const DEMO_MERCHANTS_EDIT = {
    path: "/admin/demo-merchants/:id/edit",
    component: DemoMerchantsEdit,
};
export const DEMO_MERCHANTS_VIEW = {
    path: "/admin/demo-merchants/:id/view",
    component: DemoMerchantsView,
};
