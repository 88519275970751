import { PublicChatSessionsList } from "../components/List/PublicChatSessionsList";
import { PublicChatSessionsCreate } from "../components/Create/PublicChatSessionsCreate";
import { PublicChatSessionsEdit } from "../components/Edit/PublicChatSessionsEdit";
import { PublicChatSessionsView } from "../components/View/PublicChatSessionsView";
import { SettingFilled } from "@ant-design/icons";
export const PUBLIC_CHAT_SESSIONS_LIST = {
    path: "/admin/public-chat-sessions",
    component: PublicChatSessionsList,
    menu: {
        key: "PUBLIC_CHAT_SESSIONS_LIST",
        label: "management.public_chat_sessions.menu.title",
        icon: SettingFilled,
    },
};
export const PUBLIC_CHAT_SESSIONS_CREATE = {
    path: "/admin/public-chat-sessions/create",
    component: PublicChatSessionsCreate,
};
export const PUBLIC_CHAT_SESSIONS_EDIT = {
    path: "/admin/public-chat-sessions/:id/edit",
    component: PublicChatSessionsEdit,
};
export const PUBLIC_CHAT_SESSIONS_VIEW = {
    path: "/admin/public-chat-sessions/:id/view",
    component: PublicChatSessionsView,
};
