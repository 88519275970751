var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ProjectsCollection, MerchantsCollection, MerchantsBillingsCollection, } from "@bundles/UIAppBundle/collections";
let MerchantsBillingCreateForm = class MerchantsBillingCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "renewalDate",
                label: t("management.merchants_billings.fields.renewalDate"),
                name: ["renewalDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "packageType",
                label: t("management.merchants_billings.fields.packageType"),
                name: ["packageType"],
                render: (props) => {
                    const options = [
                        { value: "BRONZE", label: "Bronze" },
                        { value: "SILVER", label: "Silver" },
                        { value: "GOLD", label: "Gold" },
                        { value: "TITAN", label: "Titan" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.merchants_billings.fields.packageType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.packageType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants_billings.fields.packageType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "periodType",
                label: t("management.merchants_billings.fields.periodType"),
                name: ["periodType"],
                render: (props) => {
                    const options = [
                        { value: "MONTHLY", label: "Monthly" },
                        { value: "YEARLY", label: "Yearly" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { allowClear: true, placeholder: t("management.merchants_billings.fields.periodType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.periodType_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants_billings.fields.periodType_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "stripeSubscriptionId",
                label: t("management.merchants_billings.fields.stripeSubscriptionId"),
                name: ["stripeSubscriptionId"],
                component: Ant.Input,
            },
            {
                id: "stripeSubscriptionSchedule",
                label: t("management.merchants_billings.fields.stripeSubscriptionSchedule"),
                name: ["stripeSubscriptionSchedule"],
                nest: [
                    {
                        id: "id",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.id"),
                        name: ["stripeSubscriptionSchedule", "id"],
                        component: Ant.Input,
                    },
                    {
                        id: "nextPhaseDate",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.nextPhaseDate"),
                        name: ["stripeSubscriptionSchedule", "nextPhaseDate"],
                        render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
                    },
                    {
                        id: "packageType",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.packageType"),
                        name: ["stripeSubscriptionSchedule", "packageType"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "BRONZE", label: "Bronze" },
                                { value: "SILVER", label: "Silver" },
                                { value: "GOLD", label: "Gold" },
                                { value: "TITAN", label: "Titan" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billings.fields.stripeSubscriptionSchedule.packageType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.stripeSubscriptionSchedule.packageType_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billings.fields.stripeSubscriptionSchedule.packageType_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "periodType",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.periodType"),
                        name: ["stripeSubscriptionSchedule", "periodType"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "MONTHLY", label: "Monthly" },
                                { value: "YEARLY", label: "Yearly" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billings.fields.stripeSubscriptionSchedule.periodType"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.stripeSubscriptionSchedule.periodType_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billings.fields.stripeSubscriptionSchedule.periodType_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                    {
                        id: "features",
                        label: t("management.merchants_billings.fields.stripeSubscriptionSchedule.features"),
                        name: ["stripeSubscriptionSchedule", "features"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "ADD_360_IMAGE", label: "Add 360 Image" },
                                { value: "ADD_3D_OBJECT", label: "Add 3 D Object" },
                                { value: "ADD_10_3D_OBJECTS", label: "Add 10 3 D Objects" },
                                { value: "ADD_VIDEO", label: "Add Video" },
                                { value: "API_CALL_ACCESS", label: "Api Call Access" },
                                { value: "LOAD_1_PHOTO", label: "Load 1 Photo" },
                                { value: "LOAD_10_PHOTOS", label: "Load 10 Photos" },
                                {
                                    value: "WISHLIST_FOR_CLIENTS",
                                    label: "Wishlist For Clients",
                                },
                                {
                                    value: "CLIENTS_CAN_GET_CONTACTS",
                                    label: "Clients Can Get Contacts",
                                },
                                { value: "TEXTCHAT", label: "Textchat" },
                                { value: "VIDEOCHAT", label: "Videochat" },
                                { value: "VOICECHAT", label: "Voicechat" },
                            ];
                            return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.stripeSubscriptionSchedule.features"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.stripeSubscriptionSchedule.features_enums.${option.label.toLowerCase()}`)
                                            ? t(`management.merchants_billings.fields.stripeSubscriptionSchedule.features_enums.${option.label.toLowerCase()}`)
                                            : option.label }, option.value))) }) }));
                        },
                    },
                ],
            },
            {
                id: "expiryDate",
                label: t("management.merchants_billings.fields.expiryDate"),
                name: ["expiryDate"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.DatePicker, { required: false }) })),
            },
            {
                id: "status",
                label: t("management.merchants_billings.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "TRIAL", label: "Trial" },
                        { value: "PAYMENT_FAILED", label: "Payment Failed" },
                        { value: "CANCELED", label: "Canceled" },
                        { value: "ACTIVE", label: "Active" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { placeholder: t("management.merchants_billings.fields.status"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.status_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants_billings.fields.status_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "failedPaymentReasonCode",
                label: t("management.merchants_billings.fields.failedPaymentReasonCode"),
                name: ["failedPaymentReasonCode"],
                component: Ant.Input,
            },
            {
                id: "features",
                label: t("management.merchants_billings.fields.features"),
                name: ["features"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ADD_360_IMAGE", label: "Add 360 Image" },
                        { value: "ADD_3D_OBJECT", label: "Add 3 D Object" },
                        { value: "ADD_10_3D_OBJECTS", label: "Add 10 3 D Objects" },
                        { value: "ADD_VIDEO", label: "Add Video" },
                        { value: "API_CALL_ACCESS", label: "Api Call Access" },
                        { value: "LOAD_1_PHOTO", label: "Load 1 Photo" },
                        { value: "LOAD_10_PHOTOS", label: "Load 10 Photos" },
                        { value: "WISHLIST_FOR_CLIENTS", label: "Wishlist For Clients" },
                        {
                            value: "CLIENTS_CAN_GET_CONTACTS",
                            label: "Clients Can Get Contacts",
                        },
                        { value: "TEXTCHAT", label: "Textchat" },
                        { value: "VIDEOCHAT", label: "Videochat" },
                        { value: "VOICECHAT", label: "Voicechat" },
                    ];
                    return (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Select, { mode: "multiple", placeholder: t("management.merchants_billings.fields.features"), children: options.map((option) => (_jsx(Ant.Select.Option, { value: option.value, children: t(`management.merchants_billings.fields.features_enums.${option.label.toLowerCase()}`)
                                    ? t(`management.merchants_billings.fields.features_enums.${option.label.toLowerCase()}`)
                                    : option.label }, option.value))) }) }));
                },
            },
            {
                id: "projectId",
                label: t("management.merchants_billings.fields.project"),
                name: ["projectId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: ProjectsCollection, field: "name", required: true }) })),
            },
            {
                id: "merchantId",
                label: t("management.merchants_billings.fields.merchant"),
                name: ["merchantId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", required: true }) })),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.merchants_billings.create_confirmation"),
                icon: _jsx(SmileOutlined, {}),
            });
            if (features.view) {
                return this.router.go(Routes.MERCHANTS_BILLINGS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.MERCHANTS_BILLINGS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.MERCHANTS_BILLINGS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => MerchantsBillingsCollection),
    __metadata("design:type", typeof (_a = typeof MerchantsBillingsCollection !== "undefined" && MerchantsBillingsCollection) === "function" ? _a : Object)
], MerchantsBillingCreateForm.prototype, "collection", void 0);
MerchantsBillingCreateForm = __decorate([
    Service({ transient: true })
], MerchantsBillingCreateForm);
export { MerchantsBillingCreateForm };
