import { ProductsList } from "../components/List/ProductsList";
import { ProductsCreate } from "../components/Create/ProductsCreate";
import { ProductsEdit } from "../components/Edit/ProductsEdit";
import { ProductsView } from "../components/View/ProductsView";
import { SettingFilled } from "@ant-design/icons";
export const PRODUCTS_LIST = {
    path: "/admin/products",
    component: ProductsList,
    menu: {
        key: "PRODUCTS_LIST",
        label: "management.products.menu.title",
        icon: SettingFilled,
    },
};
export const PRODUCTS_CREATE = {
    path: "/admin/products/create",
    component: ProductsCreate,
};
export const PRODUCTS_EDIT = {
    path: "/admin/products/:id/edit",
    component: ProductsEdit,
};
export const PRODUCTS_VIEW = {
    path: "/admin/products/:id/view",
    component: ProductsView,
};
