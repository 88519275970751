import { MerchantsBillingPackagesList } from "../components/List/MerchantsBillingPackagesList";
import { MerchantsBillingPackagesCreate } from "../components/Create/MerchantsBillingPackagesCreate";
import { MerchantsBillingPackagesEdit } from "../components/Edit/MerchantsBillingPackagesEdit";
import { MerchantsBillingPackagesView } from "../components/View/MerchantsBillingPackagesView";
import { SettingFilled } from "@ant-design/icons";
export const MERCHANTS_BILLING_PACKAGES_LIST = {
    path: "/admin/merchants-billing-packages",
    component: MerchantsBillingPackagesList,
    menu: {
        key: "MERCHANTS_BILLING_PACKAGES_LIST",
        label: "management.merchants_billing_packages.menu.title",
        icon: SettingFilled,
    },
};
export const MERCHANTS_BILLING_PACKAGES_CREATE = {
    path: "/admin/merchants-billing-packages/create",
    component: MerchantsBillingPackagesCreate,
};
export const MERCHANTS_BILLING_PACKAGES_EDIT = {
    path: "/admin/merchants-billing-packages/:id/edit",
    component: MerchantsBillingPackagesEdit,
};
export const MERCHANTS_BILLING_PACKAGES_VIEW = {
    path: "/admin/merchants-billing-packages/:id/view",
    component: MerchantsBillingPackagesView,
};
