import "./i18n";
import { DEMO_MERCHANTS_LIST as BASE_DEMO_MERCHANTS_LIST, DEMO_MERCHANTS_CREATE as BASE_DEMO_MERCHANTS_CREATE, DEMO_MERCHANTS_EDIT as BASE_DEMO_MERCHANTS_EDIT, DEMO_MERCHANTS_VIEW as BASE_DEMO_MERCHANTS_VIEW, } from "./config/routes";
export const DEMO_MERCHANTS_LIST = {
    ...BASE_DEMO_MERCHANTS_LIST,
};
export const DEMO_MERCHANTS_CREATE = {
    ...BASE_DEMO_MERCHANTS_CREATE,
};
export const DEMO_MERCHANTS_EDIT = {
    ...BASE_DEMO_MERCHANTS_EDIT,
};
export const DEMO_MERCHANTS_VIEW = {
    ...BASE_DEMO_MERCHANTS_VIEW,
};
