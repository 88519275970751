import { ProjectInvitesList } from "../components/List/ProjectInvitesList";
import { ProjectInvitesCreate } from "../components/Create/ProjectInvitesCreate";
import { ProjectInvitesEdit } from "../components/Edit/ProjectInvitesEdit";
import { ProjectInvitesView } from "../components/View/ProjectInvitesView";
import { SettingFilled } from "@ant-design/icons";
export const PROJECT_INVITES_LIST = {
    path: "/admin/project-invites",
    component: ProjectInvitesList,
    menu: {
        key: "PROJECT_INVITES_LIST",
        label: "management.project_invites.menu.title",
        icon: SettingFilled,
    },
};
export const PROJECT_INVITES_CREATE = {
    path: "/admin/project-invites/create",
    component: ProjectInvitesCreate,
};
export const PROJECT_INVITES_EDIT = {
    path: "/admin/project-invites/:id/edit",
    component: ProjectInvitesEdit,
};
export const PROJECT_INVITES_VIEW = {
    path: "/admin/project-invites/:id/view",
    component: ProjectInvitesView,
};
