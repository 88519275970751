var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let SubscriptionFeaturePriceList = class SubscriptionFeaturePriceList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "periodType",
                title: t("management.subscription_feature_prices.fields.periodType"),
                key: "management.subscription_feature_prices.fields.periodType",
                dataIndex: ["periodType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "feature",
                title: t("management.subscription_feature_prices.fields.feature"),
                key: "management.subscription_feature_prices.fields.feature",
                dataIndex: ["feature"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "projectType",
                title: t("management.subscription_feature_prices.fields.projectType"),
                key: "management.subscription_feature_prices.fields.projectType",
                dataIndex: ["projectType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "price",
                title: t("management.subscription_feature_prices.fields.price"),
                key: "management.subscription_feature_prices.fields.price",
                dataIndex: ["price"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "productId",
                title: t("management.subscription_feature_prices.fields.productId"),
                key: "management.subscription_feature_prices.fields.productId",
                dataIndex: ["productId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdAt",
                title: t("management.subscription_feature_prices.fields.createdAt"),
                key: "management.subscription_feature_prices.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedAt",
                title: t("management.subscription_feature_prices.fields.updatedAt"),
                key: "management.subscription_feature_prices.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "createdBy",
                title: t("management.subscription_feature_prices.fields.createdBy"),
                key: "management.subscription_feature_prices.fields.createdBy",
                dataIndex: ["createdBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
            {
                id: "updatedBy",
                title: t("management.subscription_feature_prices.fields.updatedBy"),
                key: "management.subscription_feature_prices.fields.updatedBy",
                dataIndex: ["updatedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, { ...props });
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            createdBy: "createdBy.fullName",
            updatedBy: "updatedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            periodType: 1,
            feature: 1,
            projectType: 1,
            price: 1,
            productId: 1,
            createdAt: 1,
            updatedAt: 1,
            createdBy: {
                _id: 1,
                fullName: 1,
            },
            createdById: 1,
            updatedBy: {
                _id: 1,
                fullName: 1,
            },
            updatedById: 1,
        };
    }
};
SubscriptionFeaturePriceList = __decorate([
    Service({ transient: true })
], SubscriptionFeaturePriceList);
export { SubscriptionFeaturePriceList };
