import "./i18n";
import { ORDERS_LIST as BASE_ORDERS_LIST, ORDERS_CREATE as BASE_ORDERS_CREATE, ORDERS_EDIT as BASE_ORDERS_EDIT, ORDERS_VIEW as BASE_ORDERS_VIEW, } from "./config/routes";
export const ORDERS_LIST = {
    ...BASE_ORDERS_LIST,
};
export const ORDERS_CREATE = {
    ...BASE_ORDERS_CREATE,
};
export const ORDERS_EDIT = {
    ...BASE_ORDERS_EDIT,
};
export const ORDERS_VIEW = {
    ...BASE_ORDERS_VIEW,
};
