import "./i18n";
import { DISPUTES_REPLIES_LIST as BASE_DISPUTES_REPLIES_LIST, DISPUTES_REPLIES_CREATE as BASE_DISPUTES_REPLIES_CREATE, DISPUTES_REPLIES_EDIT as BASE_DISPUTES_REPLIES_EDIT, DISPUTES_REPLIES_VIEW as BASE_DISPUTES_REPLIES_VIEW, } from "./config/routes";
export const DISPUTES_REPLIES_LIST = {
    ...BASE_DISPUTES_REPLIES_LIST,
};
export const DISPUTES_REPLIES_CREATE = {
    ...BASE_DISPUTES_REPLIES_CREATE,
};
export const DISPUTES_REPLIES_EDIT = {
    ...BASE_DISPUTES_REPLIES_EDIT,
};
export const DISPUTES_REPLIES_VIEW = {
    ...BASE_DISPUTES_REPLIES_VIEW,
};
