import "./i18n";
import { BASKETS_LIST as BASE_BASKETS_LIST, BASKETS_CREATE as BASE_BASKETS_CREATE, BASKETS_EDIT as BASE_BASKETS_EDIT, BASKETS_VIEW as BASE_BASKETS_VIEW, } from "./config/routes";
export const BASKETS_LIST = {
    ...BASE_BASKETS_LIST,
};
export const BASKETS_CREATE = {
    ...BASE_BASKETS_CREATE,
};
export const BASKETS_EDIT = {
    ...BASE_BASKETS_EDIT,
};
export const BASKETS_VIEW = {
    ...BASE_BASKETS_VIEW,
};
