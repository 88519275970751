var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { MerchantsCollection, } from "@bundles/UIAppBundle/collections";
let DisputeListFiltersForm = class DisputeListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "identifier",
                label: t("management.disputes.fields.identifier"),
                name: ["identifier"],
                tooltip: t("management.disputes.fields.identifier_description"),
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(Ant.Input, {}) })),
            },
            {
                id: "status",
                label: t("management.disputes.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsxs(Ant.Select, { mode: "multiple", placeholder: t("management.disputes.fields.status"), children: [_jsx(Ant.Select.Option, { value: "OPEN", children: "Open" }, "OPEN"), _jsx(Ant.Select.Option, { value: "IN_PROGRESS", children: "In Progress" }, "IN_PROGRESS"), _jsx(Ant.Select.Option, { value: "SOLVED", children: "Solved" }, "SOLVED")] }) })),
            },
            {
                id: "merchantId",
                label: t("management.disputes.fields.merchant"),
                name: ["merchantId"],
                render: (props) => (_jsx(Ant.Form.Item, { ...props, children: _jsx(UIComponents.RemoteSelect, { collectionClass: MerchantsCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }) })),
            },
        ]);
    }
};
DisputeListFiltersForm = __decorate([
    Service({ transient: true })
], DisputeListFiltersForm);
export { DisputeListFiltersForm };
